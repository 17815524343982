<template>
  <v-select
    :items="offices"
    :label="$t('invoices.international_offices')"
    v-model="office"
    :value="value"
  ></v-select>
</template>
<script>
import { GET_OFFICES } from '../constants/endpoints';

export default {
  name: 'officeSelect',
  props: ['value'],
  data() {
    return {
      offices: [],
      office: 1
    }
  },
  watch: {
    office() {
      this.$emit('input', this.office);
      this.$emit('change', this.office);
    }
  },
  beforeMount() {
    this.$http.get(this.buildEndpoint(GET_OFFICES))
    .then(resp => {
      if(resp.data.result) {
        this.offices = resp.data.data.map(o => {
          return {
            text: o.name,
            value: o.id
          }
        });
      }
    });
  }
}
</script>