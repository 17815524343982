<template>
  <div>
    <div 
      v-for="so in shippingOrders"
      :key="so.id"
    >
      <v-card-title>{{ $tc('shipping_orders', 1) }} #{{ so.id }}</v-card-title>
      <v-card-text>
        <ul class="so-info">
          <li><strong>{{ $t('orders.estimated_departure') }}:</strong> {{ so.tracking_etd !== null ? formatDate(so.tracking_etd) : formatDate(so.departure_date) }}</li>
          <li><strong>{{ $t('orders.estimated_delivery') }}:</strong> {{ so.tracking_eta !== null ? formatDate(so.tracking_eta) : formatDate(so.arrival_date) }}</li>
          <li><strong>BL:</strong> {{ so.bl_number   }}</li>
          <li><strong>{{ $t('orders.forwarder') }}:</strong> {{ so.forwarder_office_name }}</li>
        </ul>
        <div class="mt-2">
          <div class="font-weight-bold">{{ $t('orders.shipping_products') }}</div>
          <cargo-content-card
            v-for="cargo in so.related_cargos"
            :key="cargo.id" 
            :cargo="cargo"
          ></cargo-content-card>
        </div>
      </v-card-text>
      <v-card-text>
        <div class="font-weight-bold">{{ $t('orders.tracking') }}</div>
        <tracking-waypoints
          v-if="so.tracking_waypoints !== null"
          :waypoints="so.tracking_waypoints"
        ></tracking-waypoints>
        <div v-else>{{ $t('orders.tracking_failed') }}</div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import trackingWaypoints from '../../components/trackingWaypoints.vue';
import cargoContentCard from '../../components/orders/cargoContentCard.vue';

export default {
  name: 'orderTracking',
  props: ['shipping-orders'],
  components: {
    trackingWaypoints,
    cargoContentCard
  }
}
</script>
