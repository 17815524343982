const messages = {
  dashboard_title: 'Tablero',
  catalogue: 'Catálogo',
  catalogue_flash: 'Catalogo Flash',
  custom_lists: 'Listas personalizadas',
  order: 'Orden | Órdenes',
  client: 'Cliente | Clientes',
  zone: 'Zona | Zonas',
  address: 'Dirección',
  delivery_address: 'Direccion de entrega',
  warehouse: 'Almacén | Almacenes',
  required_deposit: 'Deposito Requerido',
  credit_days: 'Días de credito',
  save_changes: 'Guardar cambios',
  users: 'Usuario | Usuarios',
  product: 'Producto | Productos',
  option: 'Opción | Opciones',
  item_selected: 'Artículo seleccionado | Artículos seleccionados',
  item_removed: '{qty} artículo removido | {qty} artículos removidos',
  delete: 'borrar',
  delete_question: 'Borrar artículos?',
  show_prices: 'Mostrar precios',
  show_hide_price: 'Mostrar o no los precios',
  packing: 'Empaque',
  price: 'Precio',
  moq: 'Min orden',
  ref: 'Referencia',
  ref_ab: 'Ref',
  origin: 'Origen',
  destination: 'Destino',
  production_time: 'Tiempo de producción',
  hs_code: 'Código HS',
  carton: 'Cartón | Cartones',
  day: 'Día | Días',
  products: 'Productos',
  mandatory_field: 'Este campo es oligatorio',
  search: 'Buscar',
  select: 'Seleccionar',
  categories: 'Categorias',
  product_name: 'Nombre del producto',
  download: 'Descargar',
  generate: 'Generar',
  currency: 'Moneda',
  friendly_error: 'Algo salio mal, intenta mas tarde',
  price_tiers: 'Precios por nivel',
  something_wrong: 'Algo salio mal, intenta mas tarde',
  password_length: 'La contraseña necesita 8 caracteres',
  password_unmatch: 'Las contraseñas no coinciden',
  required: 'Campo requerido',
  password: 'Contraseña',
  password_confirmation: 'Confirmar contraseña',
  email: 'Correo electronico',
  reset: 'Restaurar',
  reset_success: 'Ha actualizado su contraseña exitosamente',
  forgot_reset_success: 'Hemos enviando un mail al correo especificado.',
  forgot_password: 'Olvidaste tu contraseña',
  login: 'Iniciar sesión',
  remember_me: 'Recordarme',
  yes: 'Si',
  field_required: 'Campo requerido',
  cost: 'Costo',
  no_products: 'No se encontraron productos',
  export: 'Exportar',
  customer: 'Cliente',
  stage: 'Estado',
  payments: 'Pagos',
  inspections: 'Inspecciones',
  total: 'Total',
  outstanding: 'Monto pendiente',
  quantity: 'Cantidad',
  cbm: 'CBM',
  created: 'Creada',
  reason: 'Razón',
  due_date: 'Vencimiento',
  status: 'Estado',
  paid_at: 'Pagado',
  amount: 'Monto',
  all: 'Todas',
  company: 'Compañía',
  cutoff_date: 'Día de corte',
  cargo_price: 'Precio contenedor',
  cargo_price_explain: 'Para calcular el precio DDP or REVOOLOOP el sistema necesita tener un costo de contenedor. Antes de exportar la ficha técnica debe especificar un precio.',
  export_options: 'Opciones para exportar',
  no_number: 'Este campo solo acepta numeros y .',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  edit_payment: 'Editar pago',
  remarks: 'Comentarios',
  refresh: 'Refrescar',
  link_copied: 'Direccion copiada',
  view_file: 'Ver archivo',
  designs: 'Diseños',
  confirm_delete: 'Estas seguro de eliminar esto?',
  quotation: 'Cotización | Cotizaciones',
  shipping: 'Envíos',
  shipping_orders: 'Envío | Envíos',
  customize: 'Personalizar',
  options: 'Opciones',
  search_categories: 'Buscar categorías',
  name: 'Nombre',
  files_uploaded: 'Documentos',
  date: 'Fecha',
  reports: 'Reportes',
  novelties: 'Novedades',
  underconstruction_alert: 'Esta sección esta en construcción. Terminaremos lo mas pronto posible!',
  action: 'Acción',
  please_wait: 'Por favor espere',
  invoicing_title: 'Facturación',
  no_documents: 'No hay documentos por el momento',
  copy: 'Copia',
  original: 'Original',
  telex: 'Telex Release',
  tax: 'IVA',
  subtotal: 'Subtotal',
  loading: 'Cargando... por favor espere.',
  invoice: 'Factura',
  deposit: 'Deposito',
  balance: 'Balance',
  edit: 'Editar',
  phone: 'Teléfono',
  save: 'Guardar',
  country: 'País',
  on_stock: 'Disponible',
  province: 'Provincia',
  invoices: {
    all_invoices: 'Todas las facturas',
    pending: 'Solo las que hay que generar',
    pending_payment: 'Las que faltan por pagar',
    manual: 'Manual creation',
    create_invoice: 'Crear factura',
    select_order: 'Seleccionar orden',
    order_products: 'Productos en la orden',
    invoice_preview: 'Previsualización de la factura',
    item_added_already: 'Este producto ya esta agregado',
    subtotal: 'Base imponible',
    unit_price: 'Precio unitario',
    already_paid: 'Pagado',
    to_be_paid: 'A pagar',
    notes: 'Notas',
    notes_hint: 'Se imprimira en la factura.',
    internal_notes: 'Notas internas',
    internal_notes_hint: 'Para uso interno, no se imprimira en la factura.',
    invoice_created: 'Factura creada exitosamente.',
    invoice_updated: 'Factura actualizada',
    add_custom_item: 'Agregar producto nuevo',
    quantity_carton: 'Cantidad por carton',
    for_calculation_purpose: 'Para calculos de precio solamente. No se imprimira.',
    add_custom_tax: 'Agregar otro recargo',
    percent: 'Porcentaje',
    notify_client: 'Notificar cliente',
    pending_generate: 'Pendientes de creacion',
    generated: 'Generadas',
    status: 'Estado de la factura',
    payment_status: 'Estado del pago',
    paid: 'Pagadas',
    unpaid: 'Pago pendiente',
    all: 'Todas',
    international_offices: 'Oficina internacional',
    order_payments: 'Pagos realizados',
    edit_invoice: 'Editar factura',
    update_invoice: 'Actualizar',
    product_fully_invoiced: 'Este producto ya ha sido facturado en su totalidad',
    create_credit_invoice: 'Crear rectificativa',
    select_invoice: 'Seleccionar factura',
    rectify: 'Rectificar',
    rectification_preview: 'Previsualizacion'
  },
  charts: {
    top_clients_purchases: 'Compras mejores clientes',
    confirm_sales_in: 'Compras confirmadas en',
    pending_sales_in: 'Compras pendientes en',
    sales: 'Ventas - Confirmadas vs Pendientes',
    pending: 'Pendientes',
    confirmed: 'Confirmadas',
    orders_status: 'Ventas por estado de orden',
    top_product_sales: 'Mejores ventas por producto',
    total_confirmed: 'Total confirmadas',
    total_pending: 'Total Pendientes',
    total_sales: 'Ventas totales',
    sales_by_broker: 'Ventas por broker',
    companies_created: 'Compañías creadas',
    assigned_total_sales: 'Ventas por asignación',
    fellow_total_sales: 'Ventas representates',
    created_total_sales: 'Ventas creadas',
    cbm_sold: 'Cbm Vendidos'
  },
  dashboard: {
    coming_events: 'Próximos eventos',
  },
  products_list: {
    filter_catalogue: 'Filtrar por localización',
    warehouse: 'En Depósito',
    catalogue: 'Catálogue',
    cargo: 'En contenedor',
    factory: 'En producción',
    max_order: 'Orden máxima',
    click_for_more_options: 'Clickea en el producto para mas opciones',
    offer: 'Oferta',
    password_length: 'La contraseña necesito 8 caracteres',
    order_from: 'Pedir desde',
    from_cargo: 'Cargo',
    from_factory: 'Fábrica',
    from_warehouse: 'Depósito',
    from_catalogue: 'Catálogo',
    available_for_order: 'Disponible',
    flash_label: 'Productos express'
  },
  profile: {
    title: 'Cuenta',
    last_name: 'Apellido',
    language: 'Idioma',
    working_categories: 'Categorías de trabajo',
    password: 'Contraseña',
    confirm_password: 'Confirmar contraseña',
    account_updated: 'Cuenta actualizada con exito'
  },
  orders: {
    pending: 'Pendiente',
    waiting_customer: 'Esperando confirmación del cliente',
    production: 'Procesando',
    quality_control: 'Control de calidad',
    collection: 'Recolección',
    shipped: 'Enviado',
    delivered: 'Entregado',
    place_at: 'Fecha de creación',
    estimated_delivery: 'Entrega estimada',
    terms: 'Términos',
    no_payments: 'No hay pagos registrados hasta el momento.',
    no_inspections: 'No hay ninguna inspección en la agenda por el momento.',
    pass: 'Aprobada',
    date: 'Fecha',
    inspector: 'Inspector',
    result: 'Resultado',
    incoterm: 'Incoterm',
    transport: 'Transporte',
    sea: 'Mar',
    air: 'Aire',
    select_cargo: 'Seleccionar cargo',
    cbm_used_vs_free: 'usado de',
    weight_used_vs_free: 'kg de',
    confirm_settings: 'Confirmar configuración',
    please_confirm: 'Por favor confirmar',
    changing_incoterm_confirmation_text: 'Cambiar el incoterm en una orden ya empezada borrara todos los products agregados hasta el momento. Esta seguro que desea proceder?',
    cart: 'Orden',
    clear_cart: 'Limpiar orden',
    create_order: 'Crear orden',
    order_incoterm: 'Incoterm de la orden',
    public_url: 'Dirección pública',
    cbm_per_carton: 'CBM por caja',
    box_size: 'Tamaño de la caja',
    subtotal: 'Subtotal',
    distribution_cost: 'Costo distribución',
    available: 'disponible',
    estimated_departure: 'Salida estimada',
    add_design: 'Agregar diseños',
    edit_product: 'Edit producto',
    product_design_name: 'Título del documento',
    product_design_file: 'Seleccionar documento',
    design_uploaded: 'Diseño actualizado',
    design_deleted: 'Desiño eliminado',
    logistics_details: 'Información logistica',
    order_assigned_to: 'Esta orden no esta asignada a ningun contenedor | Esta orden esta asignada a 1 contenedor | Esta order esta asiganada a {n} contenedores',
    quantity_exceed: 'La cantidad excede el espacio del contenedor',
    no_cargos_assigned: 'No hay cargos asignados',
    expiring_at: 'Vencimiento',
    expired: 'Vencida',
    all_orders: 'Todas',
    only_active: 'Solo activas',
    only_expired: 'Vencidas',
    only_missing_payment: 'Con pagos pendientes',
    expiration_date_alert: 'Esta orden vence el <strong>{date}</strong>. Por favor pagar deposito cuanto antes.',
    save_as_quotation: 'Guardar como cotización',
    select_delivery_date: 'Fecha de entrega',
    order_before: 'Pedir antes del',
    convert_to_order: 'Convertir a orden',
    products_in_cart: 'Productos en el carrito',
    arrival_date: 'Fecha de llegada',
    bl_number: 'Bill of landing',
    departure_date: 'Fecha de salida',
    transportation: 'Transporte',
    notes: 'Notas',
    add_product: 'Agregar producto',
    search_your_product: 'Buscar producto',
    product_added: 'Producto agregado',
    deleting_confirmation_text: 'El producto se borrara de la orden, estas seguro de continuar?',
    cargos: 'Cargos',
    container_number: 'Número de contenedor',
    on_the_way: 'En camino',
    arrived: 'Llegado',
    waiting_for_departure: 'Esperando por salida',
    dispatched: 'Despachado',
    mark_as_dispatched: 'Marcar como despachado',
    forwarder: 'Forwarder',
    from_to: 'Origen/Destino',
    packing_list_invoice:'Packing list',
    cargo_invoice: 'Factura ',
    original_date: 'Original eta',
    new_date: 'Nueva eta',
    delayed: 'Retraso de {days} días.',
    can_not_change_incoterm: 'El Incoterm no se puede cambiar al empezar la orden desde el catálogo.',
    add_button_disabled: 'El Incoterm en el carrito no es el mismo que en el catalogue. Por favor cambiar incoterm a {incoterm}.',
    tracking: 'Seguimiento',
    tracking_failed: 'Tenemos problemas con el seguimiento de este envio, por favor consultar con operaciones.',
    shipping_products: 'Productos en este envio',
    location: 'Locación',
    payment_success_result: 'El pago ha sido exitoso. Puede que tome unos minutos en actualizar la interface. Si tiene alguna duda, contactenos!',
    payment_unsuccess_result: 'El pago no pudo ser procesado. Por favor intentelo nuevamente.',
    showing_of: 'Mostrando {qty} productos de un total de {total_qty}.',
    invoices: 'Facturas',
    invoice_number: 'Número de factura',
    reason: 'Razón',
    invoiced: 'Facturado',
    invoice: 'Factura',
    invoice_preview: 'Previsualizar factura',
    no_possible_invoices: 'Nada que facturar',
    delivery_note: 'Albaran',
    to: 'Para',
    shipment: 'Envio',
    value: 'Monto',
    tax: 'IVA',
    deposit_invoiced: 'Deposito (facturado)',
    generate_invoice: 'Facturar',
    notify_client: 'Notificar cliente',
    client_ref: 'Referencia cliente',
    gen_cargo_pl: 'Generar packing list',
    gen_cargo_invoice: 'Generar factura',
    gen_cargo_invoice_client: 'Cargo invoice a cliente'
  },
  validation: {
    this_field_required: 'Este campo es obligatorio',
    email_is_required: 'Email es obligatorio',
    please_input_a_valid_email: 'Agregue un email valido',
    this_email_is_not_available: 'Este email no esta disponible'
  },
  components: {
    hide_price: 'Ocultar precio',
    delivery_date: 'Fecha de entrega',
    export_options: 'Opciones para exportar',
    select_currency: 'Moneda',
    select_language: 'Idioma',
    select_incoterm: 'Incoterm',
    set_price: 'Precio del contenedor',
    export_history: 'Archivos generados previamente',
    companies: {
      company_details: 'Detalles de la compañia',
      company_name: 'Nombre de la compañia',
      business_registration_number: 'Número de registro',
      please_white_while_we_load_the_zones: 'Espera mientras cargamos las zonas',
      please_wait_while_we_load_the_warehouses: 'Espera mientras cargamos los almacenes',
      preferred_currency: 'Moneda preferida',
      please_wait_while_we_load_the_currencies: 'Espera mientras cargamos las monedas',
      user_name: 'Nombre del usuario',
      user_email: 'Email',
      notify_user: 'Notificar',
      create_company: 'Crear compañia',
      company_created_successfully: 'Compañia creada exitosamente',
      company_updated: 'Compañia actualizada',
      categories_interest: 'Interés por categorías'
    },
     products: {
      total_units_carton: 'Total unidades',
      cartons_onwards: 'cartones en adelante',
      carton: 'cartón | cartones',
      unit: 'unidad | unidades',
      ref_number: 'Número referencia',
      weight: 'Peso (kg)',
      carton_size: 'Tamaño de la caja',
      certificate_name: 'Nombre del certificado',
      certificates: 'Certificados',
      add_to_existing_catalogue: 'Agregar a catálogo existente',
      add: 'Agregar',
      new_catalogue: 'Catálogo nuevo',
      delivery_alert: 'Confirma la orden hoy y recíbela el <strong>{date}.</strong>',
      before: 'Antes',
      now: 'Ahora',
      attr_combination_no_in_offer: 'Esta combinación de atributos no aplica para la oferta.',
      only_offers: 'Mostrar solo productos en oferta',
      offer_expire: 'La oferta vence el',
      download_datasheet: 'Descargar ficha técnica',
      base_unit: 'Unidad base',
      outer_name: 'Exportado en',
      per: 'por',
      attributes: 'Atributos',
      option_details: 'Detalles de la opcion',
      packing_specs: 'Especificaciones de empaque',
      option_search: 'Buscar opciones'
    }
  },
  views: {
    orders: {
      order_settings: 'Configuración de la orden',
      add_items: 'Seleccionar productos',
      confirm_order: 'Confirmar orden',
      start_new_order: 'Empezar orden nueva',
      continue_order: 'Continuar orden',
      confirm_order_pay: 'Confirmar y pagar',
    },
    lists: {
      no_lists_text: 'Al parecer no tienes ninguna lista todavia. Por favor ve a la sección de catalogos y crea tu primera lista.',
      show_products: 'Mostrar productos',
      hide_product: 'Esconder producto | Esconder productos',
      download_list: 'Descargar lista',
      list_updated: 'Lista actualizada',
      list_removed: 'Lista removida',
      delete_list: 'Borrar lista',
      save_current_sequence: 'Guardar secuencia',
      new_sequence_saved: 'Secuencia guardada'
    },
    catalogue: {
      catalogue_subheader: 'Lista completa de productos disponibles.',
      catalogue_flash_subheader: 'List de productos con entrega más cercana. Cada producto tiene su propia fecha de entrega.',
      page_size: 'Productos por página',
    },
    companies: {
      create_new: 'Crear nueva compañía',
      editing: 'Editar compañía',
      companies: 'Companías',
      subtitle: 'Administrar companías y usuarios',
      br_number: 'Numero de registro',
      zone: 'Zona',
      create_user: 'Crear usuario',
      edit_user: 'Editar usuario',
      set_new_password: 'Nueva contraseña',
      notify_user: 'Notificar usuario?',
      update_user: 'Actualizar usuario',
      add_user: 'Agregar usuario',
    }
  },
  payment: {
    create_payment: 'Crear un requisito de pago',
    payment_updated_successfully: 'Pago actualizado',
    payment_created_successfully: 'Pago creado',
    deposit: 'Deposito',
    balance: 'Balance',
    pay_now: 'Pagar ahora',
    full_name: 'Nombre completo',
    secure_payments: 'Pagos seguros por',
    pay_by_credit_card: 'Pagar con tarjeta de credito',
    pay_by_transfer: 'Pagar por transferencia',
    payment_options: 'Opciones de pago',
    payment_successful: 'Pago exitoso',
    payment_type: 'Tipo de pago',
    notify_client_payment_done: 'Notificar cliente que el pago esta completado?',
    notify_client_payment_request: 'Notificar cliente de este requisito de pago_',
    confirm_payment: 'Confirmar pago',
    pending: 'Pendiente',
    paid: 'Pagado',
    first_name: 'Nombre',
    last_name: 'Apellido',
    save_payment_method: 'Desea uardar este metodo de pago?',
    create_invoice_confirmation: 'Esta acción creara una invoice sobre el pago. Esta seguro que desea proceeder?'
  },
  notifications: {
    notifications_center: 'Centro de notificaciones',
    orders_notifications: 'Órdenes',
    payments_notifications: 'Pagos',
    catalogue_notifications: 'Catalogo',
    order_product_updated_title: 'El producto <strong class="mColor-text">{product_name}</strong> ha sido actualizado.',
    done_by: 'Por {user}.',
    clear_all: 'Omitir todas',
    order_status_changed: 'El estado de la orden ha cambiado',
    order_documents_uploaded: 'Nuevo <strong class="mColor-text">{document}</strong> se ha subido.',
    order_created: 'Nueva {type} creada',
    from: 'De',
    to: 'a',
    order_payment_status_changed: 'El estado del pago ha cambiado',
    order_product_created_title: 'Producto <strong class="mColor-text">{product_name}</strong> ha sido agregado a la orden.',
    product_created: '<strong class="mColor-text">{product_name}</strong> ha sido agregado al sistema.',
    product_price_updated: 'El PRECIO de <strong class="mColor-text">{product_name}</strong> ha sido cambiado',
    product_updated: 'El producto <strong class="mColor-text">{product_name}</strong> ha sido modificado'
  },
  customDatasheet: {
    show_header: 'Mostrar encabezado',
    image_for_reference_only: 'Las imagenes son de referencia unicamente',
    created_by: 'Creado por',
    phone: 'Tel',
    email: 'Correo'
  },
  errors: {
    server_error: 'Algo salio mal. Por favor intente otra configuracion. Error: {error_code}, Mensaje: {error_text}'
  }
}

export default messages