<template>
  <ul>
     <li>
        <p class="pb-0 mb-0">
          {{ $t('components.products.before') }}:
          <span class="font-weight-bold text-decoration-line-through">{{ numberToNiceString(originalPrice, this.currency) }} x {{ packingInnerDescription }}</span><br>
        </p>
        <div class="d-flex align-center">
          <v-btn
            rounded
            color="primary"
            elevation="1"
            fab
            dark
            x-small
          >
            <v-icon>mdi-tag</v-icon>
          </v-btn>
          <div class="pl-2">
            <div class="text-h6" :style="`color: ${$store.getters.hexColor}`">
              <span>{{ numberToNiceString(newPrice, this.currency) }} x {{ packingInnerDescription }}</span>
            </div>
            <p
              v-if="this.option.quantity_per_carton !== 1" 
              class="pa-0 ma-0"
            >{{ numberToNiceString(newPriceCarton, this.currency) }} x {{ packingOuterDescription }}</p>
            <div class="font-italic font-weight-bold">{{ $t('components.products.offer_expire') }}: {{ formatDate(offer.expired_at) }}</div>
          </div>
        </div>
      </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductPriceOffer',
  props: {
    option: {
      required: true
    },
    offer: {
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currency: 'getCurrency'
    }),
    packingOuterDescription() {
      return this.option.is_tree === 1 ? this.option.packing_tree[this.option.packing_tree.length - 1].value + ' ' + this.option.packing_tree[this.option.packing_tree.length - 1].name : this.$tc('components.products.carton',1);
    },
    packingInnerDescription() {
      return this.option.is_tree === 1 ? this.option.packing_tree[0].value + ' ' + this.option.packing_tree[0].name : this.$tc('components.products.unit',1);
    },
    originalPrice() {
      if(this.incoterm === 'REVOOLOOP') {
        if(this.option.is_tree === 0) {
          return this.option.unit_price;
        }
        return this.option.tiers[0].cost_per_unit;
      } if(this.incoterm === 'DDP') {
        return this.option.ddp_unit_sale_price;
      } else {
        return this.option.unit_price;
      }
    },
    moq() {
      return this.option.offer.moq;
    },
    newPrice() {
      //for reprice
      if(this.option.offer.action === 'REPRICE') {
        return this.option.offer.value;
      }
      return null;
    },
    newPriceCarton() {
      return this.newPrice*this.option.quantity_per_carton;
    }
  }
}
</script>
