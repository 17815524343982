var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.cargo.products !== undefined)?_c('div',[_c('v-card-title',[_c('strong',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('orders.container_number'))+":")]),_vm._v(" "+_vm._s(_vm.cargo.container_number)+" ")]),_c('v-card-subtitle',{directives:[{name:"show",rawName:"v-show",value:(_vm.hideFooter),expression:"hideFooter"}]},[_vm._v(" "+_vm._s(_vm.$t('orders.showing_of', { qty: _vm.cargo.products.length > 10 ? 10 : _vm.cargo.products.length, total_qty: _vm.cargo.products.length }))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":[
          { text: _vm.$tc('order', 1), align: 'start', sortable: true, value: 'order_sku' },
          { text: _vm.$t('company'), align: 'start', sortable: true, value: 'company' },
          { text: _vm.$t('product_name'), align: 'start', sortable: true, value: 'name' },
          { text: _vm.$t('quantity'), align: 'start', sortable: true, value: 'quantity' } ],"items-per-page":10,"items":_vm.cargo.products,"hide-default-footer":_vm.hideFooter,"dense":""},scopedSlots:_vm._u([{key:"item.order_sku",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.order_sku)+" ")]}},{key:"item.company",fn:function(ref){
        var item = ref.item;
return [(item.order_id === null)?_c('div',{staticClass:"font-weight-bold"},[_vm._v("EXPRESS")]):_c('div',[_vm._v(_vm._s(item.product.company))])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.product.name)+" - "),_c('strong',{staticClass:"mColor-text"},[_vm._v(_vm._s(item.product.option.key)+":")]),_vm._v(" "+_vm._s(item.product.option.value))]),(item.product.attributes.length > 0)?_c('div',{staticClass:"text-caption"},_vm._l((item.product.attributes),function(attr){return _c('span',{key:attr.key,staticClass:"pr-3"},[_c('strong',[_vm._v(_vm._s(attr.key)+":")]),_vm._v(" "+_vm._s(attr.value))])}),0):_vm._e()]}},{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cargo_quantity)+" ")]}}],null,true)})],1)],1):_c('div',[_vm._v(_vm._s(_vm.$t('loading')))])])}
var staticRenderFns = []

export { render, staticRenderFns }