var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 pa-8",attrs:{"loading":_vm.loading,"headers":_vm.table.headers,"items":_vm.table.orders,"items-per-page":_vm.pageSize,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.pageCount,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":[
            { text: _vm.$t('orders.all_orders'), value: 'all' },
            { text: _vm.$t('orders.only_active'), value: 'active' },
            { text: _vm.$t('orders.only_missing_payment'), value: 'missing_payment' },
            { text: _vm.$t('orders.only_expired'), value: 'expired' }
          ],"label":_vm.type === 'orders' ? _vm.$tc('order', 2) : _vm.$tc('quotation', 2)},on:{"change":function($event){return _vm.loadOrders()}},model:{value:(_vm.ordersType),callback:function ($$v) {_vm.ordersType=$$v},expression:"ordersType"}})],1),(_vm.type === 'orders')?_c('v-col',[_c('v-select',{attrs:{"clearable":"","items":_vm.orderStages,"label":_vm.$t('stage')},on:{"change":function($event){return _vm.loadOrders()}},model:{value:(_vm.orderStage),callback:function ($$v) {_vm.orderStage=$$v},expression:"orderStage"}})],1):_vm._e(),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadOrders($event)},"click:clear":function($event){return _vm.loadOrders(true)}},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1)],1),_c('orders-filters',{on:{"change":function($event){return _vm.loadOrders()}},model:{value:(_vm.oFilters),callback:function ($$v) {_vm.oFilters=$$v},expression:"oFilters"}})]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_c('v-tooltip',{attrs:{"right":"","color":"rgba(255, 255, 255, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":_vm.$store.getters.vColor},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.type === 'orders' ? item.orderNumber : item.id)+" ")])]}}],null,true)},[_c('v-card',{staticClass:"py-2",attrs:{"max-width":"960","flat":""}},[_c('order-products-list',{attrs:{"is-preview":"","order":item,"products":item.products}})],1)],1)],1),_c('td',[_c('div',[_vm._v(_vm._s(_vm.formatDate(item.date)))]),(item.expired_at !== null && item.expired_at !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.isExpired(item.expired_at) ? 'warning' : 'success',"x-small":""}},[_c('strong',{staticClass:"mr-1 "},[_vm._v(_vm._s(_vm.isExpired(item.expired_at) ? _vm.$t('orders.expired') : _vm.$t('orders.expiring_at'))+": ")]),_vm._v(" "+_vm._s(_vm.formatDate(item.expired_at))+" ")]):_vm._e()],1),_c('td',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.company))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.incoterm)+" - "+_vm._s(item.transport_description))]),(item.reference !== null)?_c('p',{staticClass:"mb-0 font-italic"},[_vm._v(_vm._s(_vm.$t('ref'))+": "+_vm._s(item.reference))]):_vm._e()]),_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_string))]),_c('v-chip-group',_vm._l((item.payments),function(payment){return _c('v-tooltip',{key:payment.id,attrs:{"bottom":"","color":"rgba(255, 255, 255, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","color":payment.status === 'Pending' ? 'red' : 'green',"dark":"","x-small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(payment.status === 'Pending' ? 'mdi-currency-usd-off' : 'mdi-currency-usd'))])],1)]}}],null,true)},[_c('v-card',{staticClass:"py-2",attrs:{"flat":""}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('created'))+":")]),_vm._v(" "+_vm._s(_vm.formatDate(payment.created_at)))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('reason'))+":")]),_vm._v(" "+_vm._s(payment.reason))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('amount'))+":")]),_vm._v(" "+_vm._s(payment.amount_string))]),(payment.status === 'Pending')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('orders.expiring_at'))+":")]),_vm._v(" "+_vm._s(_vm.formatDate(payment.expired_at)))]):_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('paid_at'))+":")]),_vm._v(" "+_vm._s(_vm.formatDate(payment.paid_at)))])])],1)}),1)],1),_c('td',{staticClass:"py-2"},[_c('v-chip',{attrs:{"color":_vm.stageColor(item.stage.slug),"label":"","dark":"","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.stageIcon(item.stage.slug)))])],1),_vm._v(" "+_vm._s(item.stage.description)+" ")],1),_vm._l((item.shipping_orders),function(so){return _c('div',{key:so.id},[(so.tracking_waypoints !== null)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(255, 255, 255, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","color":"blue","dark":"","x-small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(so.id)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-map-marker")])],1)]}}],null,true)},[_c('v-card',{staticClass:"py-2",attrs:{"flat":""}},[_c('tracking-waypoints',{attrs:{"waypoints":so.tracking_waypoints}})],1)],1):_vm._e()],1)})],2),_c('td',{domProps:{"innerHTML":_vm._s(_vm.orderDeliveryDate(item))}}),_c('td',[_c('v-btn',{staticClass:"ma-2",attrs:{"x-small":"","fab":"","elevation":"2","color":_vm.$store.getters.vColor},on:{"click":function($event){_vm.$router.push(_vm.viewPush(item.id))}}},[_c('span',{staticClass:"white--text"},[_c('v-icon',[_vm._v("mdi-pencil")])],1)])],1)])]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-3",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }