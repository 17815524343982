<template>
  <div>
    <v-card class="pa-8 mb-5">
      <v-card-title class="ps-0">{{ $tc('views.companies.addresses', 0) }}</v-card-title>
      <v-skeleton-loader
        class="mx-auto"
        type="list-item"
        v-if="!company"
      />
      <div v-else>
        <div class="font-weight-bold mt-5">{{ $t('views.companies.billing_address') }}</div>
        <div class="d-flex align-center pa-3">
          <div style="font-size: 14px;" class="pr-4">
            {{ billingAddress.id === null ? this.$t('views.companies.please_add_billing_address') : billingAddressText }}
          </div>
          <v-btn
            fab
            depressed
            plain
            small
            @click="openBillingAddressDialog('BILLING')"
          >
            <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
            <v-icon v-else small>mdi-pen</v-icon>
          </v-btn>
        </div>
        <div class="font-weight-bold mt-5">{{ $t('views.companies.delivery_addresses') }}</div>
        <div v-if="deliveryAddresses.length > 0" class="pa-3">
          <div
            v-for="d in deliveryAddresses"
            :key="d.uuid"
            class="d-flex align-center mb-3"
          >
            <div style="font-size: 14px;" class="pr-4">
              <span class="font-weight-bold">{{ d.alias }}, </span> 
              {{ `${d.line_one}, ${d.line_two}, ${d.city}, ${d.postal_code}, ${d.country_name}` }}
              <span v-if="d.is_default === 1" class="font-italic" style="font-size: 12px">({{$t('views.companies.default_address')}})</span>
            </div>
            <v-btn
              fab
              depressed
              plain
              small
              @click="openBillingAddressDialog('DELIVERY', d)"
            >
              <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
              <v-icon v-else small>mdi-pen</v-icon>
            </v-btn>
          </div>
        </div>
        <div style="font-size: 14px;" class="pa-3" v-else>{{ this.$t('views.companies.no_delivery_addresses') }}</div>
        <v-btn
          depressed
          plain
          small
          @click="openBillingAddressDialog('DELIVERY')"
        >
          <v-icon small>mdi-plus</v-icon>{{ $t('views.companies.add_delivery_address') }}
        </v-btn>
      </div>
    </v-card>
    <v-dialog
      v-model="overlay"
      max-width="480"
    >
      <v-card>
        <v-card-title>{{ modal_title }}</v-card-title>
        <v-form ref="companyBillingAddress" class="pa-5" @submit.prevent="updateCompanyBillingAddress">
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  v-model="add.country_id"
                  :rules="requiredRule"
                  :label="$t('country')"
                  :items="[{ text: 'España', value: 17}]"
                  disabled
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="add.type === 'DELIVERY'">
              <v-col>
                <v-text-field
                  v-model="add.alias"
                  :label="$t('views.companies.address_alias')"
                  :placeholder="$t('views.companies.alias_placeholder')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="add.type === 'DELIVERY'">
              <v-col>
                <v-text-field
                  v-model="add.recipient_first_name"
                  :label="$t('payment.first_name')"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="add.recipient_last_name"
                  :label="$t('payment.last_name')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="add.line_one"
                  :label="$t('views.companies.address_line_one')+'*'"
                  :rules="requiredRule"
                  :placeholder="$t('views.companies.address_line_one_placeholder')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="add.line_two"
                  :label="$t('views.companies.address_line_two')"
                  :placeholder="$t('views.companies.address_line_two_placeholder')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="add.city"
                  :label="$t('views.companies.city')+'*'"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="add.postal_code"
                  :label="$t('views.companies.postal_code')+'*'"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="add.type === 'DELIVERY'">
              <v-col>
                <v-checkbox 
                  v-model="defaultAdd"
                  dense
                  :label="$t('views.companies.use_as_default')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click.prevent="updateBillingAddress"
              color="primary"
              depressed
              type="submit"
              dark
              :loading="loading"
            >{{ $t('save') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="add.uuid"
              @click.prevent="deleteBillingAddress"
              depressed
              text
              :loading="loading"
            >{{ $t('delete') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import companyController from '../../controllers/companyController';

var cc = new companyController();

const default_address = {
  id: null,
  uuid: null,
  type: 'DELIVERY',
  is_default:  0,
  recipient_first_name: '',
  recipient_last_name: '',
  recipient_company: '',
  line_one: '',
  line_two:  '',
  city:  '',
  postal_code:  '',
  phone: '',
  country_id: 17,
  country_name: '',
  alias: ''
}; 

export default {
  props: ['company'],
  data() {
    return {
      editing: false,
      editing_id: null,
      loading: false,
      overlay: false,
      add: default_address,
      modal_title: null
    }
  },
  computed: {
    defaultAdd: {
      get() {
        return this.add.is_default === 0 ? false : true;
      },
      set(val) {
        this.add.is_default = val ? 1 : 0;
      }
    },
    deliveryAddresses() {
      if(this.company) {
        return this.company.delivery_addresses;
      }
      return [];
    },
    billingAddress() {
      let c = this.company;
      if(!c) {
        return {};
      }
      if(c.billing_addresses.length > 0) {
        return c.billing_addresses[0];
      }
      return {
        id: null,
        uuid: null,
        type: 'BILLING',
        is_default:  1,
        recipient_first_name: '',
        recipient_last_name:  '',
        recipient_company:  this.company.company_name,
        line_one: '',
        line_two: '',
        city: '',
        postal_code: '',
        phone: this.company.tel,
        country_id: 17,
        country_name: this.company.country_name
      };
    },
    billingAddressText() {
      var b = this.billingAddress;
      return `${b.line_one}, ${b.line_two}, ${b.city}, ${b.postal_code}, ${b.country_name}`;
    },
  },
  methods: {
    addCompany(cId) {
      if(cId) {
        this.overlay = true;
      }
      this.overlay = true;
    },
    openBillingAddressDialog(type, address = null) {
      if(type === 'BILLING') {
        this.modal_title = this.$t('views.companies.billing_address');
        this.add = JSON.parse(JSON.stringify(this.billingAddress));
      }
      if(type === 'DELIVERY') {
        this.modal_title = this.$t('views.companies.delivery_address');
        this.add = address === null ? JSON.parse(JSON.stringify(default_address)) : JSON.parse(JSON.stringify(address));
      }
      this.overlay = true;
    },
    async updateBillingAddress() {
      try {
        this.loading = true;
        await cc.updateAddress(this.company.id, this.add);
        this.$emit('update');
        this.overlay = false;
        this.loading = false;
      } catch(e) {
        console.log(e);
      }
    },
    async deleteBillingAddress() {
      try {
        this.loading = true;
        await cc.deleteAddress(this.company.id, this.add);
        this.$emit('update');
        this.overlay = false;
        this.loading = false;
      } catch(e) {
        console.log(e);
      }
    }
  }
}
</script>