const paymentStatus = [
  {
    text: 'Pending',
    value: 'Pending',
    name: 'Pending'
  },
  {
    text: 'Completed',
    value: 'Completed',
    name: 'Completed'
  }
]

export default paymentStatus