var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"wrapper",attrs:{"id":"customCatalogue"}},[_c('table',{style:({
        width: '100%'
      })},[_c('tbody',[_c('tr',[_c('td',{style:({
              width: '300px'
            })},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/images/logo-color.jpg")}})]),_c('td',{style:({
              textAlign: 'right'
            })},[_c('h3',[_vm._v("Sourcing Specialists Ltd.")]),_vm._m(0)])])])]),(_vm.list.display_price === 1)?_c('table',{style:({
        margin: '10px',
        textAlign: 'left'
      })},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Incoterm:")]),_c('td',[_vm._v(_vm._s(_vm.$route.query.incoterm))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('currency'))+":")]),_c('td',[_vm._v(_vm._s(_vm.$route.query.currency))])]),_c('tr'),_c('tr')])]):_vm._e(),_c('div',{staticClass:"product-list"},[_c('v-container',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.list.name))]),_c('v-divider')],1),_c('v-container',_vm._l((_vm.list.products),function(product){return _c('div',{key:("product_" + (product.id)),staticClass:"product_row"},[_c('v-row',{staticClass:"no-break"},[_c('div',{style:({
                width: '20%',
                border: '3px solid #CCC',
                borderRadius: '15px',
                display: 'inline-table',
                marginTop: '15px'
              })},[_c('img',{style:({
                  borderRadius: '11px',
                  display: 'block'
                }),attrs:{"src":_vm.getImage(product)}})]),_c('div',{style:({
                width: '80%',
                padding: '15px'
              })},[_c('h3',[_vm._v(_vm._s(product.name))]),_c('p',[_vm._v(_vm._s(product.description))]),_c('ul',{style:({
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '15px'
                })},[_c('li',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.$t('ref'))+": ")]),_vm._v(_vm._s(product.ref)+" ")]),_c('li',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.$t('origin'))+": ")]),_vm._v(_vm._s(product.origin)+" ")]),_c('li',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.$t('production_time'))+": ")]),_vm._v(_vm._s(product.production_time)+" "+_vm._s(_vm.$tc('day', product.production_time))+" ")]),_c('li',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.$t('hs_code'))+": ")]),_vm._v(_vm._s(product.hscode.number)+" ")])])])]),_c('v-row',[_c('table',{staticClass:"options-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('ref_ab')))]),_c('th',[_vm._v(_vm._s(_vm.$tc('option', 1)))]),_c('th',[_vm._v(_vm._s(_vm.$t('packing')))]),_c('th',[_vm._v(_vm._s(_vm.$t('moq')))]),(_vm.list.display_price === 1)?_c('th',[_vm._v(_vm._s(_vm.$t('price'))+" "+_vm._s(_vm.$route.query.incoterm))]):_vm._e()])]),_c('tbody',_vm._l((product.options),function(option){return _c('tr',{key:("option_" + (option.id))},[_c('td',[_vm._v(_vm._s(option.ref))]),_c('td',[_c('h3',[_vm._v(_vm._s(option.group_name)+": "+_vm._s(option.group_value))]),_c('div',{staticClass:"option-details",domProps:{"innerHTML":_vm._s(_vm.mxOptionDetails(option))}})]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.mxPacking(option))}}),_c('td',[_vm._v(_vm._s(option.min_order)+" "+_vm._s(_vm.$tc('carton', option.min_order)))]),(_vm.list.display_price === 1)?_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.mxPriceTiers(option, _vm.$route.query.incoterm, _vm.$route.query.currency))}})]):_vm._e()])}),0)])]),_c('v-divider')],1)}),0)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Office 605, 6/F, Hua Fu Commercial Bldg"),_c('br'),_vm._v(" 111 Queen's Road West, Sheung Wan"),_c('br'),_vm._v(" Hong Kong"),_c('br'),_vm._v(" Tel: +852 8888 8888")])}]

export { render, staticRenderFns }