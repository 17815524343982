<template>
  <v-data-table
    :loading="loading"
    :headers="table.headers"
    :items="table.orders"
    :items-per-page="pageSize"
    :page.sync="page"
    :options.sync="options"
    :server-items-length="pageCount"
    hide-default-footer
    class="elevation-1 pa-8"
  >
    <template v-slot:top>
      <v-row>
        <v-col>
          <v-select
            :items="[
              { text: $t('orders.all_orders'), value: 'all' },
              { text: $t('orders.only_active'), value: 'active' },
              { text: $t('orders.only_missing_payment'), value: 'missing_payment' },
              { text: $t('orders.only_expired'), value: 'expired' }
            ]"
            :label="type === 'orders' ? $tc('order', 2) : $tc('quotation', 2)"
            v-model="ordersType"
            @change="loadOrders()"
          >
          </v-select>
        </v-col>
        <v-col
          v-if="type === 'orders'"
        >
          <v-select
            clearable
            :items="orderStages"
            :label="$t('stage')"
            v-model="orderStage"
            @change="loadOrders()"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="table.search"
            :label="$t('search')"
            @keyup.enter="loadOrders"
            @click:clear="loadOrders(true)"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <orders-filters
        v-model="oFilters"
        @change="loadOrders()"
      ></orders-filters>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <td>
            <v-tooltip
            right
            color="rgba(255, 255, 255, 1)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                :color="$store.getters.vColor"
                @click.prevent
              >
                {{ type === 'orders' ? item.orderNumber : item.id }}
              </v-btn>
            </template>
            <v-card max-width="960" class="py-2" flat>
              <order-products-list
                is-preview
                :order="item"
                :products="item.products"
              ></order-products-list>
            </v-card>
          </v-tooltip>
        </td>
        <td>
          <div>{{ formatDate(item.date) }}</div>
          <v-chip
            v-if="item.expired_at !== null && item.expired_at !== ''"
            label
            :color="isExpired(item.expired_at) ? 'warning' : 'success'"
            x-small
          >
            <strong class="mr-1 ">{{ isExpired(item.expired_at) ? $t('orders.expired') : $t('orders.expiring_at') }}: </strong> {{ formatDate(item.expired_at) }}
          </v-chip>
        </td>
        <td>
          <div class="font-weight-bold">{{ item.company }}</div>
          <p class="mb-0">{{ item.incoterm }} - {{ item.transport_description }}</p>
          <p v-if="item.reference !== null" class="mb-0 font-italic">{{ $t('ref') }}: {{ item.reference }}</p>
        </td>
        <td class="py-2">
          <div class="font-weight-bold">{{ item.total_string }}</div>
          <v-chip-group>
            <v-tooltip
              bottom
              color="rgba(255, 255, 255, 1)"
              v-for="payment in item.payments"
              :key="payment.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  :color="payment.status === 'Pending' ? 'red' : 'green'"
                  dark
                  x-small
                >
                  <v-icon x-small>{{ payment.status === 'Pending' ? 'mdi-currency-usd-off' : 'mdi-currency-usd' }}</v-icon>
                </v-chip>
              </template>
              <v-card class="py-2" flat>
                <div><strong>{{ $t('created') }}:</strong> {{ formatDate(payment.created_at) }}</div>
                <div><strong>{{ $t('reason') }}:</strong> {{ payment.reason }}</div>
                <div><strong>{{ $t('amount') }}:</strong> {{ payment.amount_string }}</div>
                <div v-if="payment.status === 'Pending'"><strong>{{ $t('orders.expiring_at') }}:</strong> {{ formatDate(payment.expired_at) }}</div>
                <div v-else><strong>{{ $t('paid_at') }}:</strong> {{ formatDate(payment.paid_at) }}</div>
              </v-card>
            </v-tooltip>
          </v-chip-group>
        </td>
        <td class="py-2">
          <v-chip
            :color="stageColor(item.stage.slug)"
            label
            dark
            small
          >
            <v-avatar left>
              <v-icon small>{{ stageIcon(item.stage.slug) }}</v-icon>
            </v-avatar>
            {{ item.stage.description }} 
          </v-chip>
          <div
            v-for="so in item.shipping_orders"
            :key="so.id"
          >
            <v-tooltip
              bottom
              color="rgba(255, 255, 255, 1)"
              v-if="so.tracking_waypoints !== null"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="blue"
                  dark
                  x-small
                >
                  {{ so.id }} <v-icon x-small>mdi-map-marker</v-icon>
                </v-chip>
              </template>
              <v-card class="py-2" flat>
                <tracking-waypoints
                  :waypoints="so.tracking_waypoints"
                ></tracking-waypoints>
              </v-card>
            </v-tooltip>
          </div>
        </td>
        <td v-html="orderDeliveryDate(item)"></td>
        <td>
          <v-btn
            class="ma-2"
            x-small
            fab
            elevation="2"
            :color="$store.getters.vColor"
            @click="$router.push(viewPush(item.id))"
          >
            <span class="white--text"><v-icon>mdi-pencil</v-icon></span>
          </v-btn>
        </td>
      </tr>
    </template>
    <template v-slot:footer>
      <v-pagination
        class="mt-3"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </template>
  </v-data-table>
</template>

<script>
import { ORDER_GET } from '../../constants/endpoints';
import { getOrderStages } from '../../endpoints';
import orderProductsList from '../../components/orders/orderProductsList.vue';
import ordersFilters from './ordersFilters.vue';
import trackingWaypoints from '../trackingWaypoints.vue';

export default {
  name: 'ordersList',
  props: {
    type: {
      default: 'orders'
    }
  },
  components: {
    orderProductsList,
    ordersFilters,
    trackingWaypoints
  },
  data() {
    return {
      loading: true,
      table: {
        search: '',
        headers: [
          { text: this.$t('ref'), align: 'start', sortable: true, filterable: true, value: 'orderNumber', width: '100px', fixed: true },
          { text: this.$t('orders.place_at'), align: 'start', sortable: true, value: 'date' },
          { text: this.$t('company'), align: 'start', sortable: true, filterable: true, value: 'company' },
          { text: this.$t('total'), align: 'start', sortable: true, value: 'total_string' },
          { text: this.$t('status'), align: 'start', sortable: true, value: 'stage' },
          { text: this.$t('orders.estimated_delivery'), align: 'start', sortable: true, value: 'stage' },
          { text: '', align: 'end', value: 'actions', sortable: false, width: '50px' }
        ],
        orders: [],
      },
      orderStages: [],
      orderStage: '',
      show_expired: false,
      ordersType: 'active',
      pageSize: 15,
      page: 1,
      pageCount: 1,
      totalRecords: 0, 
      options: {},
      oFilters: {
        company: null,
        product: null
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.loadOrders()
      },
      deep: true,
    },
  },
  methods: {
    loadOrders(clear = false) {
      if(clear === true) {//has to be === true because clear get an event
        this.table.search = '';
      }
      this.loading = true
      this.$http.get(this.buildEndpoint(ORDER_GET), {
        params: {
          stage: this.orderStage,
          client_id: this.oFilters.company,
          product_id: this.oFilters.product,
          //incoterm: '',
          search: this.table.search,
          order_type: this.ordersType,
          quotation: this.type === 'quotations' ? 1 : 0,
          pageSize: this.pageSize,
          pageNumber: this.page,
        }
      })
      .then((resp) => {
        //console.log(resp.data.data.records);
        this.table.orders = resp.data.data.records;
        this.loading = false;
        this.pageCount = resp.data.data.pagination.totalPages;
        this.totalRecords = resp.data.data.pagination.itemsCount;
      })
    },
    isExpired(date) {
      if(new Date() > new Date(date)) {
        return true;
      }
      return false;
    },
    viewPush(id) {
      return this.type === 'quotations' ? { name: 'viewQuotation', params: { id: id }} : { name: 'viewOrder', params: { id: id }};
    },
    orderDeliveryDate(order) {
      if(order.tentative_delivery_date !== null) {
        return this.formatDate(order.tentative_delivery_date);
      }
      var dates = order.target_delivery_date;
      if(order.shipping_orders.length > 0) {
        var so = order.shipping_orders;
        var text = '';
        for(var i in so) {
          text += `<div>${so[i].tracking_eta !== null ? this.formatDate(so[i].tracking_eta) : this.formatDate(so[i].arrival_date)}</div>`;
        }
        return text;
      }
      if(dates === null) {
        return;
      }
      if(dates.length === 1) {
        return this.formatDate(dates[0].date);
      } else {
        var initial_date = dates[0].date;
        var new_date = null;
        for(var d in dates) {
          if(dates[d].date > initial_date) {
            new_date = dates[d].date;
          }
        }
        if(new_date !== null) {
          return `
            <div class="text-caption font-italic">${this.$t('orders.original_date')}: ${this.formatDate(initial_date)}</div>
            <div class="text-caption font-italic font-weight-bold mColor-text">${this.$t('orders.delayed', { days: this.compareDates(initial_date, new_date) })}</div>
            <div class="font-weight-bold">${this.$t('orders.new_date')}: ${this.formatDate(new_date)}</div>
          `;
        } else {
          return this.formatDate(initial_date);
        }
      }
    }
  },
  mounted() {
    getOrderStages().then(stages => {
      this.orderStages = stages.map( s => {
        return {
          text: s.name,
          value: s.slug
        }
      });
    })
    //this.loadOrders()
  }
}
</script>
