var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.designs,"hide-default-footer":true,"hide-default-header":true,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('designs')))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"720"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","elevation":"1","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('orders.add_design'))+" ")])]}}]),model:{value:(_vm.addDesignDialog),callback:function ($$v) {_vm.addDesignDialog=$$v},expression:"addDesignDialog"}},[(_vm.addDesignDialog)?_c('v-card',[_c('v-form',{ref:"productDesignForm"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"label":_vm.$t('orders.product_design_name')},model:{value:(_vm.designName),callback:function ($$v) {_vm.designName=$$v},expression:"designName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-file-input',{attrs:{"small-chips":"","accept":".jpg,.png,.pdf","label":_vm.$t('orders.product_design_file'),"prepend-icon":"mdi-file-image-outline","rules":_vm.requiredRule},model:{value:(_vm.designFile),callback:function ($$v) {_vm.designFile=$$v},expression:"designFile"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$store.getters.vColor,"dark":"","elevation":"1","x-large":"","loading":_vm.loading},on:{"click":_vm.addDesign}},[_vm._v(_vm._s(_vm.$t('orders.add_design')))])],1)],1)],1):_vm._e()],1)],1),_c('v-divider')]},proxy:true},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"link":"","href":item.full_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('view_file')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteDesign(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('confirmation-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }