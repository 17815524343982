<template>
  <v-card>
    <v-card-title>{{ $t('payment.payment_options') }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-btn
              v-if="false"
              color="info"
              dark
              elevation="1"
              large
              block
              @click="getPayment"
              :loading="cardPaymentLoading"
            >
              <v-icon class="mr-3">mdi-credit-card-check</v-icon>{{ $t('payment.pay_by_credit_card') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="info"
              dark
              elevation="1"
              large
              block
              @click="braintreePay"
              :loading="cardPaymentLoading"
            >
              <v-icon class="mr-3">mdi-credit-card-check</v-icon>{{ $t('payment.pay_by_credit_card') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="dark"
              dark
              elevation="1"
              large
              block
              @click="transferInfo = !transferInfo"
            >
              <v-icon class="mr-3">mdi-bank-transfer</v-icon>{{ $t('payment.pay_by_transfer') }}
            </v-btn>
            <transition name="slide-fade">
              <div
                v-if="transferInfo"
                class="pa-3"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="a in bankSpain" :key="a.text">
                        <td>{{ a.text }}</td>
                        <td>{{ a.value }}</td>
                      </tr>
                      <tr>
                        <td>Ref</td>
                        <td>{{ order_number }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog
      persistent
      v-model="payByCardModal"
      max-width="520"
      :overlay-color="$store.getters.vColor"
      overlay-opacity="1"
      transition="dialog-bottom-transition"
      tile
      flat
    >
      <div id="dropin-container"></div>
      <cc-form
        :isModal="true"
        :payment-secret="payment"
        :client-secret="clientSecret"
        :braintree-customer-id="braintree_customer_id"
        :payment-data="paymentData"
        @modalClose="payByCardModal = false"
        @paymentDone="payByCardModal = false"
        v-on="$listeners"
      ></cc-form>
    </v-dialog>
  </v-card>
</template>
<script>
import ccForm from '../payments/ccForm';
import { bankSpain } from '../../constants/bankDetails';
import { PAYMENT_PAYPAL_CREATE, PAYMENT_BRAINTREE_TOKEN } from '../../constants/endpoints';

export default {
  name: 'paymentOptions',
  props: ['payment', 'order_id', 'order_number', 'paymentData'],
  components: {
    ccForm
  },
  data() {
    return {
      panel: [0],
      payByCardModal: false,
      clientSecret: '',
      cardPaymentLoading: false,
      transferInfo: false,
      braintree_customer_id: null,
      bankSpain
    }
  },
  methods: {
    braintreePay() {
      this.cardPaymentLoading = true;
      this.$http.get(this.buildEndpoint(PAYMENT_BRAINTREE_TOKEN), {
        params: {
          payment_secret: this.payment,
          customer_id: 459
        }
      }).then(resp => {
        //console.log(resp.data);
        if(resp.data.data.token) {
          this.clientSecret = resp.data.data.token;
          this.braintree_customer_id = resp.data.data.braintree_customer_id;
          this.payByCardModal = true;
        } else {
          this.$toasted.error('Something is wrong. Please contact us.')
        }
      }).finally(() => {
        this.cardPaymentLoading = false;
      });
    },
    getPayment() {
      this.cardPaymentLoading = true;
      this.$http.get(this.buildEndpoint(PAYMENT_PAYPAL_CREATE), {
        params: {
          payment_id: this.payment,
          return_url: process.env.VUE_APP_URL+`public/order/${this.order_id}?result=success`,
          cancel_url: process.env.VUE_APP_URL+`public/order/${this.order_id}?result=unsuccess`
        }
      }).then(resp => {
        //console.log(resp.data.data);
        var links = resp.data.data.result.links;
        var payment_url = links.filter(l => l.rel === 'approve');
        if(payment_url.length > 0) {
          window.location.href = payment_url[0].href;
        }
      })
    },
  }
}
</script>