<template>
  <div>
    <v-card min-height="100">
      <loader v-model="loading"></loader>
      <div v-if="!loading">
        <v-card-title class="justify-space-between">
          <h3>
            <span
              v-if="order.type === 'order'"
              class="mColor-text pr-2"
            >{{ `${ $tc('order', 1) } #${ order.orderNumber }` }}</span>
            <span
              v-else
              class="mColor-text pr-2"
            >{{ `${ $tc('quotation', 1) } #${ order.id }` }}</span>
            - {{ order.company.name }}
          </h3>
          <div>
            <v-btn
              v-if="$store.getters.isAuthenticated"
              :href="order.delivery_note_url" 
              target="_blank"
            >
              {{ $t('orders.delivery_note') }}<v-icon class="pl-3">mdi-text-box-check-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="$store.getters.isAuthenticated"
              @click="createInvoice"
            >
              {{ $t('invoices.create_invoice') }}<v-icon class="pl-3">mdi-file-plus-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="$store.getters.isAuthenticated"
              @click="copyToClipboard(`public/order/${order.protected_id}`, true)"
            >
              {{ $t('orders.public_url') }}<v-icon class="pl-3">mdi-content-copy</v-icon>
            </v-btn>
            <v-btn
              :href="order.download_url+`?lang=${this.$store.getters.getLanguage}&with_product_images=${order.type === 'quotation' ? 1 : 0}`"
              target="_blank"
            >
              {{ $t('download') }}<v-icon class="pl-3">mdi-download</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-subtitle v-if="order.reference !== null" class="mb-0 pt-1 font-italic">{{ $t('orders.client_ref') }}: {{ order.reference }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <ul class="order-info pb-3">
            <li><strong>{{ $t('orders.place_at') }}:</strong> {{ formatDate(order.date) }}</li>
            <li v-if="order.eta != null"><strong>{{ $t('orders.estimated_delivery') }}:</strong> {{ formatDate(order.eta) }}</li>
            <li><strong>{{ $t('orders.terms') }}:</strong> {{ order.incoterm }} - {{ order.transport_description }}</li>
            <li><strong>{{ $t('currency') }}:</strong> {{ order.currency }}</li>
          </ul>
          <div>
            <v-chip
              :color="stageColor(order.stage.slug)"
              label
              dark
            >
              <v-avatar left>
                <v-icon small>{{ stageIcon(order.stage.slug) }}</v-icon>
              </v-avatar>
              {{ order.stage.description }} 
            </v-chip>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <v-dialog
      v-model="invoicing"
      fullscreen
    >
      <v-toolbar
        flat
        dark
        color="primary"
        class="pb-2"
      >
        <v-toolbar-title>{{ $t('invoices.create_invoice') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="invoicing = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <invoice-create
        v-if="invoicing"
        :is-edit="false"
        :order-id="order.id"
      ></invoice-create>
    </v-dialog>
  </div>
</template>
<script>
import loader from '../loadingBox';
import invoiceCreate from './invoiceCreate.vue';

export default {
  name: 'OrderHeader',
  props: ['loading', 'order'],
  data() {
    return {
      invoicing: false
    }
  },
  components: {
    loader, invoiceCreate
  },
  methods: {
    createInvoice() {
      this.invoicing = true;
    }
  },
  mounted() {
    console.log(this.order);
  }
}
</script>

<style scoped lang="scss">
.order-info {
  display: flex;
  li {
    margin-right: 15px;
    font-size: 13px;
  }
}
</style>