<template>
  <div
    style="height: 450px;"
  >
    <line-chart
      :chart-data="chartData"
      :options="chartOptions"
    ></line-chart>
  </div>
</template>

<script>
import lineChart from '../charts/lineChart.vue';
import { GET_PRODUCT_PRICE_HISTORY } from '../../constants/endpoints';

export default {
  name: 'productPriceHistory',
  components: {
    lineChart
  },
  props: ['product-id'],
  data() {
    return {
      fakeData: [],
      chartData: {
        labels: ['Hola', 'Que tal', 'chau', 'perro'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [0.2,0.1,0.4,0.35],
            borderColor: '#F06292',
            backgroundColor: '#F48FB1',
            fill: false,
            lineTension: 0
          },
          {
            label: 'Dataset 2',
            data: [1.5,1.2,1.1,1.45],
            borderColor: '#64B5F6',
            backgroundColor: '#90CAF9',
            fill: false,
            lineTension: 0
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart'
          }
        }
      },
    }
  },
  methods: {
    async load() {
      this.$http.post(this.buildEndpoint(GET_PRODUCT_PRICE_HISTORY, { product_id: this.productId }))
      .then( resp => {
        //console.log(resp.data.data);
        this.chartData = resp.data.data.order_price_history;
      })
    }
  },
  created() {
    this.load();
  }
}
</script>
