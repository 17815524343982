<template>
  <v-container fluid>
    <v-row>
      <v-col
        xl="12"
        md="12"
        class="d-flex mb-1"
      >
        <v-btn
          :to="{ name: order.type === 'order' ? 'Orders' : 'Quotations' }"
          class="mb-5"
          color="blue-grey"
          elevation="2"
          dark
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="order.type === 'quotation'"
          class="mb-5"
          color="indigo"
          elevation="2"
          dark
          :loading="convertingToOrder"
          @click="convertToOrder"
        >
          {{ $t('orders.convert_to_order') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="order_alerts">
      <v-col>
        <v-alert
          v-if="!loading"
          v-model="expirationAlert"
          dismissible
          color="warning"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-calendar-alert"
        >
          <div v-html="$t('orders.expiration_date_alert', { date: formatDate(order.expired_at) })"></div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="12"
        md="12"
      >
        <order-header :loading="loading" :order="order"></order-header>
        <v-card class="mt-8">
          <v-tabs
            v-model="tab"
            :background-color="$store.getters.vColor"
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#products">
              {{ $tc('product',2) }}
              <v-icon>mdi-format-list-text</v-icon>
            </v-tab>

            <v-tab v-if="order.type === 'order'" href="#invoices">
              {{ $t('orders.invoices') }}
              <v-icon>mdi-note-edit-outline</v-icon>
            </v-tab>

            <v-tab v-if="order.type === 'order'" href="#payments">
              {{ $t('payments') }}
              <v-icon>mdi-cash-check</v-icon>
            </v-tab>

            <v-tab v-if="order.type === 'order'" href="#inspections">
              {{ $t('inspections') }}
              <v-icon>mdi-magnify-scan</v-icon>
            </v-tab>

            <v-tab v-if="order.type === 'order'" href="#tracking">
              {{ $tc('shipping_orders', 2) }}
              <v-icon>mdi-map-marker</v-icon>
            </v-tab>

            <v-spacer></v-spacer>
            <v-tab
              href="#documents"
              v-if="order.type === 'order' && $store.getters.user.is_admin"
            >
              {{ $t('documents') }}
              <v-icon>mdi-file-document-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              value="products"
            >
              <v-container v-if="canEditOrder" fluid>
                <v-row class="pa-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="second"
                    dark
                    elevation="1"
                    @click="addProductModal = true"
                  >{{ $t('orders.add_product') }}</v-btn>
                </v-row>
              </v-container>
              <v-card
                class="pa-3"
                flat
              >
                <order-products-list
                  edit
                  :order="order"
                  :products="products"
                  :can-modify="canEditOrder"
                  @quantityUpdated="reload"
                  @productDeleted="reload"
                ></order-products-list>
              </v-card>
            </v-tab-item>
            <v-tab-item value="invoices">
              <v-card
                v-if="tab === 'invoices'"
                class="pa-3"
                flat
              >
                <order-invoices :order="order"></order-invoices>
              </v-card>
            </v-tab-item>
            <v-tab-item
              value="payments"
            >
              <keep-alive>
                <v-card
                  class="pa-3"
                  flat
                >
                  <order-payments :order="order"></order-payments>
                </v-card>
              </keep-alive>
            </v-tab-item>
            <v-tab-item
              value="inspections"
            >
              <keep-alive>
                <v-card
                  class="pa-3"
                  flat
                >
                  <order-inspections :order-id="order.id"></order-inspections>
                </v-card>
              </keep-alive>
            </v-tab-item>
            <v-tab-item
              value="tracking"
            >
              <keep-alive>
                <v-card
                  class="pa-3"
                  flat
                >
                  <div 
                    v-for="so in shipping_orders"
                    :key="so.id"
                  >
                    <v-card-title>{{ $tc('shipping_orders', 1) }} #{{ so.id }}</v-card-title>
                    <v-card-text>
                      <ul class="so-info">
                        <li><strong>{{ $t('orders.estimated_delivery') }}:</strong> {{ so.tracking_eta !== null ? formatDate(so.tracking_eta) : formatDate(so.arrival_date) }}</li>
                        <li><strong>BL:</strong> {{ so.bl_number   }}</li>
                        <li><strong>{{ $t('orders.forwarder') }}:</strong> {{ so.forwarder_office_name }}</li>
                      </ul>
                      <div class="mt-2">
                        <div class="font-weight-bold">{{ $t('orders.shipping_products') }}</div>
                        <cargo-content-card
                          v-for="cargo in so.cargos"
                          :key="cargo.id" 
                          :cargo="cargo"
                        ></cargo-content-card>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div class="font-weight-bold">{{ $t('orders.tracking') }}</div>
                      <tracking-waypoints
                        v-if="so.tracking_waypoints !== null"
                        :waypoints="so.tracking_waypoints"
                      ></tracking-waypoints>
                      <div v-else>{{ $t('orders.tracking_failed') }}</div>
                    </v-card-text>
                  </div>
                </v-card>
              </keep-alive>
            </v-tab-item>
            <v-tab-item
              v-if="$store.getters.user.is_admin"
              value="documents"
            >
              <keep-alive>
                <v-card
                  class="pa-3"
                  flat
                >
                  <order-documents :order-id="order.id"></order-documents>
                </v-card>
              </keep-alive>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="addProductModal"
    >
      <add-product-to-order
        :order-id="order.id"
        :currency="order.currency"
        :order-incoterm="order.incoterm"
        @productAdded="loadOrder"
      ></add-product-to-order>
    </v-dialog>
  </v-container>
</template>

<script>
import orderPayments from '../../components/orders/payments';
import orderInvoices from '../../components/orders/invoices.vue';
import orderInspections from '../../components/orders/inspections';
import orderHeader from '../../components/orders/orderHeader.vue';
import orderProductsList from '../../components/orders/orderProductsList';
import orderDocuments from '../../components/orders/orderDocuments';
import addProductToOrder from '../../components/orders/addProductToOrder';
import trackingWaypoints from '../../components/trackingWaypoints.vue';
import cargoContentCard from '../../components/orders/cargoContentCard.vue';

export default {
  name: 'Order',
  props: ['id'],
  components: {
    orderPayments, 
    orderInspections, 
    orderHeader, 
    orderProductsList, 
    orderDocuments, 
    addProductToOrder,
    trackingWaypoints,
    cargoContentCard,
    orderInvoices
  },
  data: function() {
    return {
      loading: true,
      order: {},
      company: {},
      products: [],
      stage: 'pending',
      tab: 'products',
      convertingToOrder: false,
      addProductModal: false,
      expirationAlert: true,
      shipping_orders: []
    }
  },
  computed: {
    canEditOrder() {
      if(this.order.type === 'quotation') {
        return true;
      }
      for(var payment in this.order.payments) {
        if(this.order.payments[payment].reason === 'deposit' && this.order.payments[payment].status !== 'Completed') {
          return true;
        }
      }
      return false;
    },
    order_alerts() {
      if(this.order.expired_at !== null) {
        return true;
      }
      return false;
    }
  },
  watch: {
    id(new_val, old_val) {
      if(new_val !== old_val) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.loadOrder();
    },
    loadOrder() {
      this.loading = true;
      this.$http.get(this.endpoint(`order/get/${this.id}`))
      .then( resp => {
        //console.log(resp.data)
        if(resp.data.result) {
          console.log(resp.data.data.shipping_orders);
          //console.log(resp.data.data);
          this.company = resp.data.data.order.company;
          this.order = resp.data.data.order;
          this.products = resp.data.data.order_items;
          this.stage = resp.data.data.order.stage.slug;
          this.shipping_orders = resp.data.data.shipping_orders;
        } else {
          this.$t('friendly_error');
        }
        this.loading = false
      })
    },
    convertToOrder() {
      this.convertingToOrder = true
      this.$http.post(this.endpoint(`order/${this.order.id}/convert_to_order`), {
        notify_client: 1
      }).then( () => {
        this.$router.push({ name: 'viewOrder', params: { id: this.order.id } })
        this.reload()
      })
    }
  },
  mounted() {
    this.loadOrder()
    if(this.$route.query.tab) {
      this.tab = this.$route.query.tab
    }
  }
}
</script>

<style scoped lang="scss">
.so-info {
  display: flex;
  li {
    margin-right: 15px;
    font-size: 13px;
  }
}
</style>
