<template>
  <div class="d-flex align-center">
    <h3 class="mr-3">{{ $t('products_list.order_from') }}</h3>
    <v-chip-group
      v-model="orderMethod"
      mandatory
      active-class="primary--text"
    >
      <v-chip
        v-for="o in orderOptions"
        :key="o.val"
        :value="o.val"
        label
      >
        {{ o.text }}
      </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'expressOrderOptions',
  props: {
    product: {
      default: {},
      required: true,
    }
  },
  data() {
    return {
      orderOptions: [
        { text: this.$t('catalogue'), val: 'catalogue' }
      ]
    }
  },
  computed: {
    ...mapGetters(['productInDialog']),
    orderMethod: {
      get() {
        return this.$store.getters.orderMethod;
      },
      set(val) {
        this.$store.commit('setOrderMethod', val);
      }
    }
  },
  mounted() {
    if(this.product.in_warehouse === 1) {
      this.orderOptions.push({ text: this.$t('products_list.from_warehouse'), val: 'warehouse' });
    }
    if(this.product.in_cargo === 1) {
      this.orderOptions.push({ text: this.$t('products_list.from_cargo'), val: 'cargo' });
    }
    if(this.product.in_factory === 1) {
      this.orderOptions.push({ text: this.$t('products_list.from_factory'), val: 'factory' });
    }
  }
}
</script>