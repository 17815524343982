<template>
  <!-- App.vue -->
  <v-app :style="{ backgroundColor: '#f5f6f7' }">

    <Loader />

    <side-nav
      v-if="isAuthenticated"
    ></side-nav>

    <v-app-bar
      v-if="isAuthenticated"
      app
      color="white"
      clipped-left
    >
      <top-nav-bar></top-nav-bar>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <vue-page-transition name="fade-in-right">
        <!-- If using vue-router -->
          <router-view :key="$route.fullPath"></router-view>
        </vue-page-transition>
        <cart-float
          v-model="showCart" 
        ></cart-float>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>

  </v-app>
</template>

<script>
  import Loader from './components/loader'
  import TopNavBar from './components/TopNavBar'
  import SideNav from './components/SideNav';
  import cartFloat from './components/orders/cartFloat.vue'
  import { mapGetters } from 'vuex';

  export default {
    name: 'Layout',
    data() {
      return {
        side: true,
      }
    },
    components: {
      Loader,
      TopNavBar,
      SideNav,
      cartFloat
    },
    computed: {
      ...mapGetters('cart', [
        'showCart'
      ]),
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      }
    }
  }
</script>
<style scoped>
.container {
  position: inherit;
}
</style>
