<template>
  <v-container
    class="public_order"
  >
    <loader v-model="loading"></loader>
    <div 
      v-if="!loading"
      class="public_order_wrapper"
    >
      <v-alert
        v-if="$route.query.result !== undefined"
        v-model="paymentAlert"
        dismissible
        :color="paymentResult ? 'success' : 'red'"
        border="left"
        elevation="2"
        colored-border
        :icon="paymentResult ? 'mdi-credit-card-outline' : 'mdi-credit-card-off-outline'"
      >
        <div v-if="paymentResult" v-html="$t('orders.payment_success_result')"></div>
        <div v-if="!paymentResult" v-html="$t('orders.payment_unsuccess_result')"></div>
      </v-alert>
      <order-header :order="order"></order-header>
      <v-card
        v-if="shipping_orders.length > 0"
        class="mt-5"
      >
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header style="padding: 16px;">
              <h4 class="mColor-text" style="font-size: 1.25rem; letter-spacing: 0.0125em;">
                {{ $t('orders.tracking') }}
              </h4>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <order-tracking :shipping-orders="shipping_orders"></order-tracking>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-card
        class="mt-5"
      >
        <v-card-title>
          <h4 class="mColor-text">{{ $t('products') }}</h4>
        </v-card-title>
        <v-divider></v-divider>
        <order-products-list :order="order" :products="products"></order-products-list>
      </v-card>
      <v-card
        v-if="order.type === 'order'"
        class="mt-5"
      >
        <v-card-title class="justify-space-between">
          <h4 class="mColor-text">{{ $t('payments') }}</h4>
          <v-btn
            v-if="false"
            @click="reloadPayments"
          >
            {{ $t('refresh') }}<v-icon class="pl-3">mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <order-payments
          ref="paymentsComponent"
          :canPay="true"
          :order="order"
        ></order-payments>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { ORDER_GET_PROTECTED } from '../../constants/endpoints.js';
import loader from '../../components/loadingBox';
import orderHeader from '../../components/orders/orderHeader.vue';
import orderProductsList from '../../components/orders/orderProductsList';
import orderPayments from '../../components/orders/payments.vue';
import orderTracking from '../../components/orders/orderTracking.vue';

export default {
  name: 'PublicOrder',
  props: ['id'],
  components: {
    loader, orderHeader, orderProductsList, orderPayments, orderTracking
  },
  data() {
    return {
      loading: true,
      order: {},
      products: [],
      paymentAlert: true,
      shipping_orders: [],
    }
  },
  computed: {
    paymentResult() {
      if(this.$route.query.result === 'success') {
        return true;
      }
      return false;
    }
  },
  methods: {
    getOrder() {
      this.$http.get(this.buildEndpoint(ORDER_GET_PROTECTED, { protected_id: this.id }), {
        params: {
          protected_id: true
        }
      }).then((resp) => {
        console.log(resp.data.data)
        if(resp.data.result) {
          this.loading = false;
          this.order = resp.data.data.order;
          this.products = resp.data.data.order_items;
          this.shipping_orders = resp.data.data.shipping_orders;
        }
      })
    },
    reloadPayments() {
      this.$refs.paymentsComponent.loadAllPayments()
    }
  },
  mounted() {
    this.getOrder()
  }
}
</script>
<style scoped lang="scss">
.public_order {
  min-height: 100vh;
  .public_order_wrapper {
    max-width: 1080px;
    margin: auto;
  }
}
</style>