<template>
  <v-card
    tile
    flat
    class="pt-15 pa-10"
  >
    <v-btn
      v-if="isModal"
      icon
      dark
      :color="$store.getters.vColor"
      @click="$emit('modalClose')"
      :style="{
        position: 'absolute',
        top: '15px',
        right: '15px'
      }"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-form ref="paymentForm" id="payment-form">
      <v-card-text
        class="elements-wrapper"
      >
        <v-row v-if="false">
          <v-col>
            <v-text-field
              v-model="first_name"
              :label="$t('payment.first_name')"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="last_name"
              :label="$t('payment.last_name')"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="cardNumberContainer">
          <v-col>
            <label for="card-number">Card</label>
            <div id="card-number"></div>
            <span id="card-errors"></span>
          </v-col>
          <img :src="cardLogos" alt="Card logos" width="120">
        </v-row>
        <v-row>
          <v-col>
            <label for="expiration-date">Expiry</label>
            <div id="expiration-date"></div>
            <span id="expiry-errors"></span>
          </v-col>
          <v-col>
            <label for="cvv">CVC</label>
            <div id="cvv"></div>
            <span id="cvc-errors"></span>
          </v-col>
          <v-col>
            <label for="postal-code">Postal Code</label>
            <div id="postal-code"></div>
            <span id="cvc-errors"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-5 justify-end">
        <v-btn
          type="submit"
          :color="$store.getters.vColor"
          elevation="1"
          dark
          block
          :loading="paying"
        >{{ $t('payment.pay_now') }} {{ paymentData.amount_string }}</v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text>
      <v-divider></v-divider>
      <div class="pt-5 text-center">
        {{ $t('payment.secure_payments') }}<br>
        <img :src="btLogo" alt="Braintree" width="200">
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { requiredRule } from '../../constants/formRules';
import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import { PAYMENT_BRAINTREE_SUBMIT } from '../../constants/endpoints';
import btLogo from '../../assets/images/bt-logo.png';
import cardLogos from '../../assets/images/card-logos.png';

export default {
  name: 'ccForm',
  props: {
    isModal: {
      default: false,
      type: Boolean
    },
    clientSecret: {
      required: true,
      default: ''
    },
    paymentSecret: {
      required: true,
      default: ''
    },
    braintreeCustomerId: {
      default: null
    },
    paymentData: {
      default: null
    }
  },
  data() {
    return {
      stripe: null,
      first_name: '',
      last_name: '',
      requiredRule,
      paying: false,
      btLogo,
      cardLogos
    }
  },
  methods: {
    pay() {
      console.log(123);
    },
    loadBraintree() {
      var vue = this;
      client.create({
        authorization: this.clientSecret,
        container: '#dropin-container'
      }, function (err, clientInstance) {
        if(err) {
          console.log(err);
          return;
        }
        //console.log(clientInstance);
        vue.createHostedFields(clientInstance);
      });
    },
    createHostedFields(clientInstance) {
      var vue = this;
      var form = document.getElementById('payment-form');
      hostedFields.create({
        client: clientInstance,
        styles: {
          'input': {
            'font-size': '16px',
            'font-family': '"Roboto", sans-serif',
            'line-height': '1.5',
            'color': '#ccc',
            'padding': '12px 5px',
            'max-height': '10px'
          },
          ':focus': {
            'color': 'black'
          },
          '.valid': {
            'color': '#8bdda8'
          }
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: '**** **** **** ****'
          },
          cvv: {
            selector: '#cvv',
            placeholder: '***'
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'mm/yy'
          },
          postalCode: {
            selector: '#postal-code',
            placeholder: '****'
          }
        }
      }, function (err, hostedFieldsInstance) {
        if(err) {
          console.log(err);
        }
        var tokenize = function (event) {
          event.preventDefault();
          
          hostedFieldsInstance.tokenize(function (err, payload) {
            if (err) {
              alert('Something went wrong. Check your card details and try again.');
              return;
            }
            vue.submit(payload.nonce);
            //alert('Submit your nonce (' + payload.nonce + ') to your server here!');
          });
        };
        
        form.addEventListener('submit', tokenize, false);
      });
    },
    submit(nonce) {
      this.paying = true;
      if(this.$refs.paymentForm.validate()) {
        this.$http.post(this.buildEndpoint(PAYMENT_BRAINTREE_SUBMIT), {
          first_name: this.first_name,
          last_name: this.last_name,
          payment_secret: this.paymentSecret,
          nonce: nonce
        }).then(resp => {
          if(resp.data.data.success) {
            //console.log(resp.data.data);
            this.$toasted.success(this.$t('payment.payment_successful'));
            this.$emit('paymentDone');
          } else {
            this.$toasted.error(resp.data.data.message);
          }
        }).finally(() => {
          this.paying = false;
        });
      }
    }
  },
  created() {
    //console.log(this.paymentSecret);
    this.loadBraintree();
  }
}
</script>

<style lang="scss" scoped>
#card-number, #expiration-date, #cvv, #postal-code {
  border-bottom: 1px solid rgba(0,0,0,0.3);
  height: 30px;
  margin-top: 8px;
}
#card-errors, #expiry-errors, #cvc-errors {
  padding-top: 5px;
  height: 15px;
  display: block;
  color: #E53935;
  font-size: 10px;
  line-height: 1.2;
}

.cardNumberContainer {
  position: relative;
  img {
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
}
</style>