const bankSpain = [
  {
    text: 'Company name',
    value: 'Sourcing Specialists SLU'
  },
  {
    text: 'IBAN',
    value: 'ES94 2100 4455 7102 0031 3754'
  },
  {
    text: 'Swift',
    value: 'CAIXESBBXXX'
  },
  {
    text: 'Address',
    value: 'C/Central Num. 13, 7B, Torre Godoy - 30100 Espinardo - Murcia  | CIF: B73918518'
  },
]

export {
  bankSpain
}