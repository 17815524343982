<template>
  <div>
    <div v-if="type === 'autocomplete'">
      <v-autocomplete
        v-model="selected"
        :items="asList"
        :label="$t('categories')"
        item-text="name"
        item-value="id"
        multiple
        :loading="loadingList"
      >
        <template v-slot:selection="data">
          <v-chip
            v-if="data.index === 0"
            style="padding: 10px;"
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item, data)"
          >
            {{ data.item.name }}
          </v-chip>
          <span
            v-if="data.index === 1"
            class="grey--text caption"
          >
            (+{{ selected.length - 1 }} others)
          </span>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.parent_name"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    <div v-if="type === 'tree'">
      <v-card
        class="mt-3 pa-3"
        outlined
        tile
      >
        <loading-box v-model="loadingList"></loading-box>
        <v-text-field
          :label="$t('search_categories')"
          outlined
          dense
          v-model="search"
        ></v-text-field>
        <v-treeview
          v-model="selected"
          :items="asTree"
          :search="search"
          selectable
          dense
          shaped
        ></v-treeview>
      </v-card>
    </div>
  </div>
</template>
<script>
import loadingBox from './loadingBox.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CategoriesSelection',
  props: ['value', 'loading', 'type'],
  components: {
    loadingBox
  },
  data: function() {
    return {
      categoriesList: [],
      loadingList: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'selectedCategories'
    ]),
    asTree() {
      var newCats = [];
      this.categories.map(function(c) {
        if(c.parent_name === undefined) {
          c.name = c.header;
          c.children = [];
          newCats.push(c);
        } else {
          var ind = newCats.findIndex(parent_c => parent_c.id === c.group);
          newCats[ind].children.push(c);
        }
        c.value = c.id;
        c.text = c.name;
      });
      return newCats;
    },
    asList() {
      return this.categories.map(function(c) {
        c.value = c.id;
        c.text = c.name;
        return c;
      });
    },
    selected: {
      get: function() {
        return this.selectedCategories;
      },
      set: function(newValue) {
        this.selectCategories(newValue);
      }
    },
  },
  methods: {
    ...mapActions([
      'getCategories',
      'selectCategories',
      'getProducts'
    ]),
    remove (item) {
      const index = this.selected.indexOf(item.id);
      if (index >= 0) this.selected.splice(index, 1);
    }
  },
  mounted() {
    if(this.categories.length === 0) {
      this.getCategories();
    }
  }
}
</script>