<template>
  <div>
    <div :class="['product-flags', 'warehouse-flag', { show: inWarehouse }, 'first-flag']">
      <v-icon x-small color="#FFF" class="mr-1">mdi-office-building</v-icon> {{ $t('products_list.warehouse') }}</div>
    <div :class="['product-flags', 'cargo-flag', { show: inCargo }, guessFlagPosition('cargo')]">
      <v-icon x-small color="#FFF" class="mr-1">mdi-ferry</v-icon> {{ $t('products_list.cargo') }}</div>
    <div :class="['product-flags', 'factory-flag', { show: inFactory }, guessFlagPosition('factory')]">
      <v-icon x-small color="#FFF" class="mr-1">mdi-factory</v-icon> {{ $t('products_list.factory') }}</div>
  </div>
</template>
<script>
export default {
  name: 'productFlags',
  props: ['product'],
  computed: {
    inWarehouse() {
      return this.product.in_warehouse === 1 ? true : false;
    },
    inCargo() {
      return this.product.in_cargo === 1 ? true : false;
    },
    inFactory() {
      return this.product.in_factory === 1 ? true : false;
    }
  },
  methods: {
    guessFlagPosition(flag) {
      if(flag === 'cargo') {
        if(this.product.is_novelty === 1) {
          return 'second-flag';
        }
      }
      if(flag === 'factory') {
        if(this.inWarehouse && this.inCargo === 1) {
          return 'third-flag';
        }
        if((this.inWarehouse && !this.inCargo) || (!this.inWarehouse && this.inCargo)) {
          return 'second-flag';
        }
      }
      return 'first-flag';
    }
  }
}
</script>

<style lang="scss" scoped>
.product-flags {
  display: none;
  position: absolute;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 15px 5px 10px;
  border-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  &.show {
    display: inherit;
  }
  &.cargo-flag {
    background-color: #D32F2F;
  }
  &.warehouse-flag {
    background-color: #64B5F6;
  }
  &.factory-flag {
    background-color: #FFA000;
  }
  &.first-flag {
    top: 0px;
    left: 0px;
  }
  &.second-flag {
    top: 30px;
    left: 0px;
  }
  &.third-flag {
    top: 60px;
    left: 0px;
  }
}
</style>