<template>
  <div>
    <loader v-model="loading"></loader>
    <div class="d-flex justify-end py-3">
      <v-btn
        v-if="!canPay"
        dark
        elevation="1"
        :color="$store.getters.vColor"
        @click="paymentEditDialog = true"
      >{{ $t('payment.create_payment') }}</v-btn>
    </div>
    <v-data-table
      v-if="payments.length > 0"
      :headers="tableHeaders"
      :items="payments"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          class="ma-2"
          :color="item.status === 'Completed' ? 'green' : 'warning'"
          text-color="white"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.invoice`]="{ item }">
        <div v-if="item.invoice !== null">{{ item.invoice.invoice_no }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="false"
          fab
          dark
          elevation="1"
          :color="$store.getters.vColor"
          @click="loadPayment(item.protected_id, 'paymentEdit')"
          x-small
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="false"
          :dark="!waitToRetry(item)"
          elevation="2"
          :color="$store.getters.vColor"
          @click="loadPayment(item, 'paymentOptions')"
          small
          :disabled="waitToRetry(item)"
        >
          {{ waitToRetry(item) ? $t('please_wait') : $t('payment.pay_now') }}
        </v-btn>
        <v-btn
          v-if="item.reason === 'deposit' && item.invoice === null"
          :color="$store.getters.vColor"
          @click="generateInvoice(item.protected_id)"
          small
        >
          <v-icon>mdi-note-plus-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="$store.getters.development && item.intent !== null"
          :color="$store.getters.vColor"
          @click="checkPayment(item.intent.intent_id)"
          small
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`body.append`]>
        <tr>
          <td colspan="6"></td>
          <td>
            <h4 class="text-right"><strong>{{ $t('total') }}:</strong> {{ paymentsTotal }}</h4>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-container v-else>{{ $t('orders.no_payments') }}</v-container>
    <v-dialog
      max-width="720"
      v-model="paymentEditDialog"
    >
      <payment-edit
        v-if="paymentEditDialog"
        :order="order"
        :id="paymentId"
        @onClose="paymentEditDialog = false"
        @save="loadAllPayments"
      ></payment-edit>
    </v-dialog>
    <v-dialog
      max-width="720"
      v-model="paymentOptionsDialog"
    >
      <payment-options
        v-if="paymentOptionsDialog"
        :payment="paymentId"
        :order_id="order.protected_id"
        :order_number="order.orderNumber"
        :payment-data="paymentData"
        @paymentDone="paymentDone"
      ></payment-options>
    </v-dialog>
    <confirmation-dialog ref="confirm"></confirmation-dialog>
  </div>
</template>
<script>
import paymentEdit from './paymentEdit';
import paymentOptions from './paymentOptions';
import loader from '../loadingBox';
import { PAYMENT_PAYPAL_CHECK, PAYMENT_GENERATE_DEPOSIT_INVOICE } from '../../constants/endpoints';
import pusher from '../../PusherBus';

export default {
  name: 'OrderPayments',
  props: {
    order: {
      default: null
    },
    canPay: {
      default: false
    }
  },
  components: {
    paymentEdit, paymentOptions, loader, confirmationDialog: () => import("../confirmationDialog"),
  },
  data() {
    return {
      tableHeaders: [
        { text: this.$t('created'), align: 'start', sortable: true, value: 'created_at' },
        { text: this.$t('reason'), align: 'start', sortable: true, value: 'reason' },
        { text: this.$t('due_date'), align: 'start', sortable: true, value: 'expired_at' },
        { text: this.$t('status'), align: 'start', sortable: true, value: 'status' },
        { text: this.$t('paid_at'), align: 'start', sortable: true, value: 'paid_at' },
        { text: this.$t('invoice'), align: 'start', sortable: true, value: 'invoice' },
        { text: this.$t('amount'), align: 'end', sortable: true, value: 'amount_string' },
        { text: '', align: 'end', sortable: false, value: 'actions' },
      ],
      payments: [],
      paymentId: '',
      paymentEditDialog: false,
      paymentOptionsDialog: false,
      loading: true,
      paymentData: {}
    }
  },
  watch: {
    paymentEditDialog(v) {
      if(!v) this.paymentId = ''
    },
    order() {
      this.loadAllPayments()
    }
  },
  computed: {
    paymentsTotal() {
      let total = 0;
      var currency = 'USD'
      this.payments.map(p => {
        total+= Number(p.amount)
        currency = p.currency
      })
      return this.numberToNiceString(total, currency)
    }
  },
  methods: {
    waitToRetry(item) {
      if(this.$route.query.result !== undefined && item.intent !== null && item.intent.intent_id === this.$route.query.token) {
        return true;
      }
      return false;
    },
    loadPayment(payment, modal) {
      //console.log(protected_id);
      this.paymentId = payment.protected_id;
      this.paymentData = payment;
      if(modal === 'paymentOptions') {
        this.paymentOptionsDialog = true;
      }
      if(modal === 'paymentEdit') {
        this.paymentEditDialog = true;
      }
    },
    checkPayment(id) {
      this.$http.get(this.buildEndpoint(PAYMENT_PAYPAL_CHECK), {
        params: {
          paypal_order_id: id
        }
      }).then((resp) => {
        console.log(resp.data);
        this.loadAllPayments();
      })
    },
    loadAllPayments() {
      this.paymentEditDialog = false
      this.loading = true
      var endpoint = this.canPay ? this.endpoint(`order/get_protected/${this.order.id}/payments/list`) : this.endpoint(`order/${this.order.id}/payments/list`)
      this.$http.get(endpoint)
        .then(resp => {
          //console.log(resp.data.data)
          if(resp.data.result === true) {
            this.payments = resp.data.data
          }
          this.loading = false
        })
    },
    paymentDone() {
      this.paymentOptionsDialog = false;
      var vue = this;
      setTimeout(function() {
        vue.loadAllPayments();
      }, 3000)
      //this.loadAllPayments();
    },
    subscribe(channel, event) {
      pusher.subscribe(channel);
      pusher.bind(event, () => {
        this.loadAllPayments();
      })
    },
    async generateInvoice(payment_protect_id) {
      var confirmation = await this.$refs.confirm.open(
        this.$t('orders.please_confirm'),
        this.$t('payment.create_invoice_confirmation')
      );
      if(confirmation) {
        this.loading = true;
        this.$http.post(this.buildEndpoint(PAYMENT_GENERATE_DEPOSIT_INVOICE, { payment_protect_id: payment_protect_id }))
        .then(resp => {
          //console.log(resp.data)
          if(resp.data.result === true) {
            this.loadAllPayments();
          } else {
            this.$toasted.error(resp.data.response_message);
          }
          this.loading = false
        });
      }
    }
  },
  mounted() {
    if(this.order.id) {
      this.loadAllPayments()
    }
  },
  created() {
    this.subscribe('payments', 'payment-update');
  }
}
</script>