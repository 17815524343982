<template>
  <v-card flat>
    <div v-if="cargo.products !== undefined">
      <v-card-title>
        <strong class="mr-1">{{ $t('orders.container_number') }}:</strong> {{ cargo.container_number }}
      </v-card-title>
      <v-card-subtitle v-show="hideFooter">
        {{ $t('orders.showing_of', { qty: cargo.products.length > 10 ? 10 : cargo.products.length, total_qty: cargo.products.length }) }}
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="[
            { text: $tc('order', 1), align: 'start', sortable: true, value: 'order_sku' },
            { text: $t('company'), align: 'start', sortable: true, value: 'company' },
            { text: $t('product_name'), align: 'start', sortable: true, value: 'name' },
            { text: $t('quantity'), align: 'start', sortable: true, value: 'quantity' },
          ]"
          :items-per-page="10"
          :items="cargo.products"
          :hide-default-footer="hideFooter"
          dense
        >
          <template v-slot:[`item.order_sku`]="{ item }">
            {{ item.product.order_sku }}
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <div v-if="item.order_id === null" class="font-weight-bold">EXPRESS</div>
            <div v-else>{{ item.product.company }}</div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <h4>{{ item.product.name }} - <strong class="mColor-text">{{ item.product.option.key }}:</strong> {{ item.product.option.value }}</h4>
            <div
              class="text-caption"
              v-if="item.product.attributes.length > 0"
            >
              <span
                class="pr-3"
                v-for="attr in item.product.attributes"
                :key="attr.key"
              ><strong>{{ attr.key }}:</strong> {{ attr.value }}</span>
            </div> 
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            {{ item.cargo_quantity }}
          </template>
        </v-data-table>
      </v-card-text>
    </div>
    <div v-else>{{ $t('loading') }}</div>
  </v-card>
</template>

<script>
export default {
  name: 'cargoContentCard',
  props: ['cargo', 'hide-footer']
}
</script>
