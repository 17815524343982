import controller from './controller';
import { 
  CATALOGUE_GET, 
  CATEGORIES_GET,
  CATALOGUE_GET_PRODUCT,
  GET_WAREHOUSE_PRODUCTS 
} from '../constants/endpoints.js';

export default class catalogueController extends controller {

  async getCategories(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', CATEGORIES_GET, params).then(resp => {
        if(resp.data.result == true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async getProducts(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', CATALOGUE_GET, params).then(resp => {
        resolve(resp.data);
      }).catch((error) => {
        resolve(error.response)
      });
    });
  }

  async getProduct(id, params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', this.buildEndpoint(CATALOGUE_GET_PRODUCT, { id: id }), params).then(resp => {
        resolve(resp.data.data);
      }).catch((error) => {
        resolve(error.response)
      });
    });
  }

  async getWarehouseProducts() {
    var products = await this.sendRequest('GET', this.buildEndpoint(GET_WAREHOUSE_PRODUCTS));
    return products.data;
  }

}