<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <search-bar></search-bar>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="!isForOrders"
        :lg="2"
      >
        <inco-selection :forOrders="isForOrders"></inco-selection>
      </v-col>
      <v-col :lg="isForOrders ? ($store.getters.catView === 'tiles' ? 4 : 2) : ($store.getters.catView === 'tiles' ? 3 : 2)">
        <v-select
          v-model="catalogueTypes"
          :label="$t('products_list.filter_catalogue')"
          multiple
          :items="[
            { text: $t('products_list.warehouse'), value: 'warehouse' },
            { text: $t('products_list.cargo'), value: 'cargo' },
            { text: $t('products_list.factory'), value: 'factory' },
          ]"
          @change="getProducts()"
        ></v-select>
      </v-col>
      <v-col
        v-show="$store.getters.catView !== 'tiles'"
        :lg="isForOrders ? 5 : 4"
      >
        <categories-selection
          type="autocomplete"
        ></categories-selection>
      </v-col>
      <v-col :lg="isForOrders ? ($store.getters.catView === 'tiles' ? 6 : 3) : ($store.getters.catView === 'tiles' ? 5 : 2)">
        <custom-catalogue-selection></custom-catalogue-selection>
      </v-col>
      <v-col lg="2">
        <ul class="d-flex justify-space-around">
          <li>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  :color="only_offers ? 'primary' : 'secondary'"
                  @click="only_offers = !only_offers"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-tag</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('components.products.only_offers') }}</span>
            </v-tooltip>
          </li>
          <li v-if="false">
            <v-btn
              fab
              :class="{ activated: $store.getters.catView == 'list' }"
              @click="changeView('list')"
            >
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </li>
          <li>
            <v-btn
              fab
              :class="{ activated: $store.getters.catView == 'tiles' }"
              @click="changeView('tiles')"
            >
              <v-icon>mdi-view-dashboard</v-icon>
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IncoSelection from '../incoSelection.vue';
import CustomCatalogueSelection from '../CustomCatalogueSelection.vue';
import CategoriesSelection from '../CategoriesSelection.vue';
import searchBar from './searchBar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'catalogueFilters',
  props: ['for-orders'],
  components: {
    IncoSelection,
    CustomCatalogueSelection,
    CategoriesSelection,
    searchBar
  },
  data() {
    return {
      only_offers: false
    }
  },
  computed: {
    ...mapGetters(['catalogueType']),
    isForOrders() {
      return this.forOrders ? true : false;
    },
    catalogueTypes: {
      get () {
        return this.catalogueType;
      },
      set(value) {
        this.$store.commit('setCatalogueType', value);
      }
    },

  },
  methods: {
    ...mapActions(['getProducts']),
    changeView(display) {
      this.$store.commit('setCatalogueView', display);
      //this.$store.commit('setCatalogueType', [])
    }
  }
}
</script>