import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

const url = process.env.VUE_APP_API_ENDPOINT
const functions_url = process.env.VUE_APP_FUNCTIONS
const public_url = process.env.VUE_APP_URL
const global_endpoint = process.env.VUE_APP_API_ENDPOINT_GLOBAL

const mixins = {
  data() {
    return {
      emailRule: [
        v => !!v || this.$t('required'),
        v => /.+@.+\..+/.test(v) || this.$t('invalid_email')
      ],
      requiredRule: [
        v => !!v || this.$t('required')
      ],
      requiredRuleObjects: [
        v => Object.keys(v).length === 0 || this.$t('required')
      ],
      onlyNumbers: [
        v => /^\d*\.?\d*$/.test(v) || this.$t('no_number')
      ]
    }
  },
  computed: {
    ...mapGetters('cart', {
      cartIncoterm: 'incoterm'
    }),
    ...mapGetters({
      incoterm: 'getIncoterm',
      lang: 'getLanguage',
      orderMethod: 'orderMethod',
    }),
    development() {
      return process.env.NODE_ENV === 'development' ? true : false;
    },
  },
  methods: {
    debug(data) {
      if(this.development) {
        for(var i in data) {
          console.log(data[i]);
        }
      }
    },
    objectToUrlString(object) {
      let str = ''
      Object.keys(object).map( (key) => {
        str += `&${key}=${object[key]}`
      })
      return str
    },
    endpoint: function (task, noAuth = false, global = false) {
      const is_public = noAuth ? 'public/' : '';
      if(global) {
        return global_endpoint+task;
      }
      return url+is_public+task;
    },
    buildEndpoint: function(url, data = null) {
      if(data !== null) {
        var regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g')
        return url.replace(regex, (m, $1) => data[$1] || m)
      }
      return url
    },
    ffunctions: function(task) {
      return functions_url+task
    },
    //format packing as a list
    mxPacking(option) {
      var string = '';
      var packing  = option.packing;
      if(packing !== undefined) {
        string += `<li><span class="font-weight-bold">${ this.$t('components.products.base_unit') }:</span> ${packing.unit}</li>`;
        if(packing.unit_outer_quantity !== null) {
          string += `<li><span class="font-weight-bold">${ this.$t('components.products.outer_name') }:</span> ${packing.unit_outer_description_text}</li>`;
          string += `<li>${packing.unit_outer_quantity} ${ packing.unit } ${ this.$t('components.products.per') } ${packing.unit_outer_description_text}</li>`;
        }
      } else {
        string += `<li>ESTE PRODUCTO NECESITA SER ARREGLADO</li>`;
      }
      return string;
    },
    //format measurements
    mxMeas(option) {
      if(option.length === null && option.width === null && option.height === null) {
        return false
      }
      var string = `${option.length}cm x ${option.width}cm x ${option.height}cm`
      return string
    },
    //get a generic info of a product option
    mxOptionDetails(option) {
      var string = `<p><span class="font-weight-bold">${ this.$t('components.products.ref_number') }:</span> ${ option.ref }</p>`
      if(this.mxMeas(option)) {
        string += `<p><span class="font-weight-bold">${ this.$t('components.products.carton_size') }:</span> ${ this.mxMeas(option) }</p>`
      }
      if(option.weight_per_carton !== null) {
        string += `<p><span class="font-weight-bold">${ this.$t('components.products.weight') }:</span> ${ option.weight_per_carton }</p>`
      }
      if(option.description !== '' && option.description !== undefined) {
        const description = (option.description.length >= 100) ? (option.description.substring(0,100)+'...') : option.description
        string += `<p>${description}</p>`
      }
      return string
    },
    //format price tiers as a list
    mxPriceTiers(
      option, 
      inco = false,
      currency = null
    ) {

      var string = '';

      if(inco) {
        string += inco;
      }

      //check if the option price is valid
      var priceValid = true;
      if(option.price_expire_at !== '') {
        var date = new Date();
        var expiration = new Date(option.price_expire_at);
        if(date.getTime() > expiration.getTime()) {
          priceValid = false;
        }
      }

      //check the requested currency
      var currencyText =  this.$store.getters.getCurrency;
      if(currency !== null) {
        currencyText = currency;
      }

      var price = option.price ? option.price : {};
      var packing = option.packing ? option.packing : {};

      var outer_price = price.carton_price_string;
      var unit_price = price.unit_price_string;
      var inner_price = this.numberToNiceString(price.unit_price/packing.unit_inner_quantity, currencyText); 

      //has inner
      if(packing.unit_inner_quantity !== null) {
        string += `<div class="font-italic subunit" style="font-size: 11px">${inner_price} x ${packing.unit_inner_description}</div>`;
      }
      //base price
      string += `<div class="font-weight-bold" style="font-size: 16px">${unit_price} x ${packing.unit}</div>`;
      //has outer
      if(packing.unit_outer_quantity !== null) {
        string += `<div class="outerunit">${outer_price} x ${packing.unit_outer_description_text}</div>`;
      }
      if(this.$store.getters.flashProducts === 0) {
        string += `<div class="font-weight-bold font-italic" style="color: #F44336; ${(priceValid) ? 'display: none;' : ''}">${this.$t('components.products.price_expired')}</v-chip>`;
      }
 
      return string
    },
    getSelectedAttributes(option) {
      if(this.orderMethod !== 'catalogue') {
        return option.attribute_ids;
      } else {
        var a = option.attributes_grouped.map(function(a) {
          return a['selected'];
        });
        return a;
      }
    },
    buildCartProduct(product, option) {

      var attrs = this.getSelectedAttributes(option);

      var attributes = [];
      option.attributes.map( a => {
        if(attrs.includes(a.attribute_id)) {
          attributes.push(a);
        }
      });

      var carton_price = this.cartIncoterm === 'DDP' ? option.ddp_carton_sale_price : option.carton_price;
      var offer = null;
      //calculate price per carton if there is an offer to apply
      if(this.offerShouldApply(product.offer) && this.checkOfferAttributes(option)) {
        offer = product.offer;
        if(option.offer.action === 'REPRICE') {
          carton_price = option.offer.value*option.quantity_per_carton;
        }
      }

      //flash
      var quantity_allowed = null, 
          ref_cargo_id = null, 
          ref_purchase_order_product_id = null,
          ref_warehouse_product_id = null,
          flash_eta = null,
          is_flash = false,
          ordered_method = 'catalogue';
      if(this.orderMethod !== 'catalogue') {
        quantity_allowed = option.quantity_allowed;
        ref_cargo_id = option.cargo_id;
        ref_purchase_order_product_id = option.ref_purchase_order_product_id;
        ref_warehouse_product_id = option.ref_warehouse_product_id;
        flash_eta = option.eta_string;
        is_flash = true;
        ordered_method = this.orderMethod;
      }


      const idd = `${option.unique_id}-${attrs.join('-')}-${this.orderMethod}`; //product + attributes
      var image = (product.images[0]) ? product.images[0].small : '';

      var prod_object =  {
        id: idd,
        product_id: product.id,
        product_name: product.name,
        meas: this.mxMeas(option),
        option_id: option.option_id ? option.option_id : option.id,
        option_group_name: option.group_name,
        option_group_value: option.group_value,
        inner_units: option.inner_units,
        outer_units: option.outer_units,
        min_order: option.min_order,
        cbm_per_carton: option.cbm_per_carton,
        quantity: option.quantity,
        attributes: attrs,
        attributes_data: attributes,
        cost_per_carton: carton_price,
        image: image,
        weight_per_carton: option.weight_per_carton,
        offer: offer,
        quantity_allowed: quantity_allowed,
        ref_cargo_id: ref_cargo_id,
        ref_purchase_order_product_id: ref_purchase_order_product_id,
        ref_warehouse_product_id: ref_warehouse_product_id,
        flash_eta: flash_eta,
        is_flash: is_flash,
        price: option.price,
        packing: option.packing,
        ordered_method: ordered_method
      }
      //console.log(prod_object);
      return prod_object;
    },
    checkTier(tier, quantity) {
      if(quantity >= tier.from && tier.to === 'onwards') {
        return true
      }
      return (quantity >= tier.from && quantity < tier.to) ? true : false
    },
    getTier(tiers, quantity) {
      //find price tier
      const tier = tiers.filter(function(p) {
        if(p.to === 'onwards') {
          return quantity >= p.from
        }
        return quantity >= p.from && quantity < p.to
      })
      return tier[0]
    },
    optionQuantitiesToString(opt) {
      var string = '';
      var packing = opt.packing ? opt.packing : {};
      var quantity = opt.quantity;
      if(packing.unit_outer_quantity !== null) {
        string += `${opt.quantity} ${packing.unit_outer_description_text} - `;
        quantity = opt.quantity*packing.unit_outer_quantity;
      }
      string += `${quantity} ${packing.unit}`;
      return string;
    },
    countDecimals(n) {
      // Convert to String
      const numStr = String(n)
      // String Contains Decimal
      if (numStr.includes('.')) {
          return numStr.split('.')[1].length
      }
      // String Does Not Contain Decimal
      return 0
    },
    numberToNiceString(n, currency = 'USD', maxDecimals = null) {
      //get the nice decimals
      let decimals = maxDecimals
      if(maxDecimals === null) {
        decimals = 2
        if(this.countDecimals(n) >= 4) {
          decimals = 4
        }
        if(this.countDecimals(n) == 3) {
          decimals = 3
        }
      }
      var formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: decimals
      })
      return `${formatter.format(n)}`
    },
    calculatePercent(number, percent, action = null) {
      var ratio = percent/100;
      var perc = number*ratio;
      if(action === 'SUM') {
        return perc+number;
      }
      return perc;
    },
    formattedNumber(n) {
      const num = this.fixDigits(n)
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    fixDigits(n) {
      return Math.round(n*100)/100
    },
    round(n, decimals = 4) {
      var ceros = Math.pow(10, decimals)
      return Math.round((parseFloat(n) + Number.EPSILON) * ceros) / ceros
    },
    stageColor(stage, hex = false) {
      switch (stage) {
        case 'pending':
          return hex ? '#B71C1C' : 'red darken-4'
        case 'waiting_for_customer_confirmation':
          return hex ? '#3F51B5' : 'indigo'
        case 'require_deposit':
          return hex ? '#3F51B5' : 'indigo'
        case 'production':
          return hex ? '#4CAF50' : 'green'
        case 'quality_control':
          return hex ? '#673AB7' : 'deep-purple'
        case 'collection':
          return hex ? '#00BCD4' : 'cyan'
        case 'shipped':
          return hex ? '#0D47A1' : 'blue darken-4'
        case 'on_ship':
          return hex ? '#0D47A1' : 'light-blue'
        case 'delivered':
          return hex ? '#FFC107' : 'amber'
        default:
          return ''
      }
    },
    stageIcon(stage) {
      var icon = ''
      switch (stage) {
        case 'pending':
          icon += 'mdi-account-cog-outline'
          break
        case 'waiting_for_customer_confirmation':
          icon += 'mdi-clock-outline'
          break
        case 'require_deposit':
          icon += 'mdi-clock-outline'
          break
        case 'production':
          icon += 'mdi-factory'
          break
        case 'quality_control':
          icon += 'mdi-magnify-scan'
          break
        case 'collection':
          icon += 'mdi-truck'
          break
        case 'shipped':
          icon += 'mdi-ferry'
          break
        case 'on_ship':
          icon += 'mdi-ferry'
          break
        case 'delivered':
          icon += 'amber'
          break
        default:
          icon += ''
          break
      }
      return icon
    },
    compareDates(dateA, dateB) {
      const date1 = new Date(dateA);
      const date2 = new Date(dateB);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    formatDate(string) {
      var dMain = new Date(string)
      var d = DateTime.fromJSDate(dMain, { locale: this.$store.getters.getLanguage })
      return d.toLocaleString(DateTime.DATE_MED)
    },
    fillArray(value, len) {
      var arr = [];
      for (var i = 0; i < len; i++) {
        arr.push(value);
      }
      return arr;
    },
    copyToClipboard(text, is_url = false) {
      if(is_url) {
        var url = public_url+text
        const el = document.createElement('textarea');
        el.value = url;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.success(this.$t('link_copied'))
      }
    },
    paymentStatusString(n) {
      if(n == 1) {
        return this.$t('payment.pending')
      } else if(n == 2) {
        return this.$t('payment.paid')
      }
      return ''
    },
    buildNotification(n) {

      var data = n.data
      
      var link = data.linked_model_id
      var order = data.order_number ?? null
      var text = ''
      var details = ''
      var by = data.by_name

      switch(n.key_id) {
        case 'order_created':
          text = this.$t('notifications.order_created', { type: data.order_type })
          break
        case 'order_artworks_confirmed':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_documents_uploaded':
          text = this.$t('notifications.order_documents_uploaded', { document: data.file_type })
          break
        case 'order_inspection_confirmed':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_inspection_document_uploaded':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_product_created':
          text = this.$t('notifications.order_product_created_title', { product_name: data.product_name ?? null })
          break
        case 'order_product_deleted':
          text = this.$t('notifications.order_product_deleted_title', { product_name: data.product_name ?? null })
          break
        case 'order_product_design_uploaded':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_product_purchase_price_changed':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_product_quantity_updated':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_product_sale_price_changed':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'order_product_updated':
          text = this.$t('notifications.order_product_updated_title', { product_name: data.product_name ?? null })
          break
        case 'order_status_changed':
          text = this.$t('notifications.order_status_changed')
          details = `${this.$t('notifications.from')} <strong>${ data.stage_from ?? null }</strong> ${this.$t('notifications.to')} <strong>${ data.stage_to ?? null }</strong>`
          break
        case 'order_payment_status_changed':
          text = this.$t('notifications.order_payment_status_changed')
          var from = data.payment_status_from ?? null
          var to = data.payment_status_to ?? null
          details = `
            ${this.$t('notifications.from')} <strong>${this.paymentStatusString(from)}</strong> 
            ${this.$t('notifications.to')} <strong>${this.paymentStatusString(to)}</strong>
          `
          break
        case 'order_payment_created':
          text = this.$t('notifications.order_payment_created')
          break
        case 'product_created':
          text = this.$t('notifications.product_created', { product_name: data.product_name })
          break
        case 'product_price_updated':
          text = this.$t('notifications.product_price_updated', { product_name: data.product_name })
          break
        case 'product_updated':
          text = this.$t('notifications.product_updated', { product_name: data.product_name })
          break
        case 'production_time_updated':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
        case 'supplier_updated_po':
          return null //this has to be deleted when I handle this notification case and add BREAK below like the others
      }

      return {
        id: n.id,
        link: link,
        order: order,
        text: text,
        details: details,
        by: by
      }

    },
    checkCondition(condition) {
      var result = false;
      switch(condition.slug) {
        case 'incoterm_in_x':
          var incoterm = this.$route.name === 'OrdersNew' ? this.$store.getters['cart/incoterm'] : this.$store.getters.getIncoterm;
          for(var c in condition.params.X) {
            if(condition.params_with_info.X[c] === incoterm) {
              result = true;
              break;
            }
          }
          break;
        case 'customer_country_in_x':
          if(condition.params_with_info.X.name === this.$store.state.auth.user.country) {
            result = true;
            break;
          }
          break;
        case 'currency_in_x':
          for(var ccc in condition.params.X) {
            if(condition.params_with_info.X[ccc] === this.$store.state.appSettings.currency) {
              result = true;
              break;
            }
          }
      }
      return result;
    },
    offerShouldApply(offer) {

      //null
      if(!offer) {
        return false;
      }

      //no conditions
      if(offer.conditions.length === 0) {
        return true;
      }

      //check offers conditions
      var conditions = offer.conditions;
      var conditionsPass = [];
      for(var c in conditions) {
        conditionsPass.push(this.checkCondition(conditions[c]));
      }
      if(conditionsPass.every(x => x === true)) {
        return true;
      }

      return false;
    },
    checkOfferAttributes(option) {
      if(option.offer === null) {
        return false;
      }
      var attrs = this.getSelectedAttributes(option);
      var offer_attrs = option.offer.attribute_ids;
      var required_matches = option.attributes_grouped.length;
      var compare = (a1, a2) => a1.filter(v => a2.includes(v)).length;
      if(compare(attrs, offer_attrs) === required_matches) {
        return true;
      }
      return false;
    },
    addParamsToLocation(params, route = false) {
      var thisroute = '';
      if(route === false) {
        thisroute = this.$route.path;
      } else {
        thisroute = route;
      }
      history.pushState(
        {},
        null,
        thisroute +
          '?' +
          Object.keys(params)
            .map(key => {
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
              )
            })
            .join('&')
      )
    },
    removeParamsToLocation(path) {
      history.pushState(
        {},
        null,
        path
      )
    }
  },
}

export default mixins