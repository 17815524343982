<template>
  <div class="button-group">
    <slot name="main-action"></slot>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="$slots['secondary-actions']"
          color="red darken-4"
          dark
          v-bind="attrs"
          v-on="on"
          x-small
          depressed
          tile
          class="activator"
        >
          <v-icon x-small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <slot name="secondary-actions"></slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'buttonWithOptions',
  props: {
    icon: {
      default: null
    }
  }
}
</script>

<style lang="scss">
.button-group {
  .v-btn {
    margin-right: -3px !important;
  }
  .activator {
    padding-left: 3px !important;
    padding-right: 3px !important;
    min-width: 15px !important;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}
</style>
