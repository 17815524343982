const messages = {
  dashboard_title: 'Dashboard',
  catalogue: 'Catalogue',
  catalogue_flash: 'Catalogue flash',
  custom_lists: 'Custom lists',
  order: 'Order | Orders',
  client: 'Client | Clients',
  zone: 'Zone | Zones',
  address: 'Address',
  delivery_address: 'Delivery address',
  warehouse: 'Warehouse | Warehouses',
  required_deposit: 'Required deposit',
  credit_days: 'Credit days',
  save_changes: 'Save changes',
  users: 'User | Users',
  product: 'Product | Products',
  option: 'Option | Options',
  item_selected: 'Item selected | Items selected',
  item_removed: '{qty} item removed | {qty} items removed',
  delete: 'Delete',
  delete_question: 'Delete item?',
  show_prices: 'Show prices',
  show_hide_price: 'Show or hide prices',
  packing: 'Packing',
  price: 'Price',
  moq: 'Min Order',
  ref: 'Reference',
  ref_ab: 'Ref',
  origin: 'Origin',
  destination: 'Destination',
  production_time: 'Production time',
  hs_code: 'HS Code',
  carton: 'Carton | Cartons',
  day: 'Day | Days',
  products: 'Products',
  mandatory_field: 'This field is mandatory',
  search: 'Search',
  select: 'Select',
  categories: 'Categories',
  product_name: 'Product name',
  download: 'Download',
  currency: 'Currency',
  friendly_error: 'Sorry, something went wrong',
  price_tiers: 'Price tiers',
  something_wrong: 'Something went wrong',
  password_length: 'Password min length is 8 characteres',
  password_unmatch: 'Passwords does not match',
  required: 'Field required',
  password: 'Password',
  password_confirmation: 'Password confirmation',
  email: 'Email',
  reset: 'Reset',
  reset_success: 'Your password has been changed',
  forgot_reset_success: 'An email has been sent to the specified address.',
  forgot_password: 'Forgot password',
  login: 'Login',
  remember_me: 'Remember me',
  yes: 'Yes',
  field_required: 'Field required',
  cost: 'Cost',
  no_products: 'No products match the selected criteria',
  export: 'Export',
  customer: 'Customer',
  stage: 'Stage',
  payments: 'Payments',
  inspections: 'Inspections',
  total: 'Total',
  outstanding: 'Outstanding',
  quantity: 'Quantity',
  cbm: 'CBM',
  created: 'Created',
  reason: 'Reason',
  due_date: 'Due date',
  status: 'Status',
  paid_at: 'Paid at',
  amount: 'Amount',
  all: 'All',
  company: 'Company',
  cutoff_date: 'Cut off date',
  cargo_price: 'Cargo price',
  cargo_price_explain: 'To calculate DDP price the system must know the cargo price. Please specify the cargo price in this field before exporting the datasheet.',
  export_options: 'Export options',
  no_number: 'This field only allows numbers and .',
  cancel: 'Cancel',
  confirm: 'Confirm',
  edit_payment: 'Edit payment',
  remarks: 'Remarks',
  refresh: 'Refresh',
  link_copied: 'Link copied',
  view_file: 'View file',
  designs: 'Designs',
  confirm_delete: 'Are you sure you want to delete this item?',
  quotation: 'Quotation | Quotations',
  shipping: 'Shipping orders',
  shipping_orders: 'Shipping order | Shipping orders',
  documents: 'Documents',
  customize: 'Customize',
  options: 'Options',
  search_categories: 'Search categories',
  name: 'Name',
  files_uploaded: 'Files',
  date: 'Date',
  reports: 'Reports',
  novelties: 'Novelties',
  underconstruction_alert: 'This section is under construction. We will have it ready shortly!',
  action: 'Action',
  please_wait: 'Please wait',
  invoicing_title: 'Invoicing',
  no_documents: 'There are no documents uploaded at the moment',
  copy: 'Copy',
  original: 'Original',
  telex: 'Telex Release',
  tax: 'Tax',
  subtotal: 'Subtotal',
  loading: 'Loading... please wait.',
  invoice: 'Invoice',
  deposit: 'Deposit',
  balance: 'Balance',
  edit: 'Edit',
  phone: 'Phone',
  save: 'Save',
  country: 'Country',
  on_stock: 'On stock',
  province: 'Province',
  invoices: {
    all_invoices: 'All invoices',
    pending: 'To be generated',
    pending_payment: 'Pending payment',
    manual: 'Manual creation',
    create_invoice: 'Create invoice',
    select_order: 'Select order',
    order_products: 'Order products',
    invoice_preview: 'Invoice preview',
    item_added_already: 'This item is already added',
    subtotal: 'Subtotal',
    unit_price: 'Unit price',
    already_paid: 'Paid',
    to_be_paid: 'To pay',
    notes: 'Notes',
    notes_hint: 'Will be printed on the invoice.',
    internal_notes: 'Internal notes',
    internal_notes_hint: 'For internal use, won\'t be printed in invoice.',
    invoice_created: 'Invoice created successfully',
    invoice_updated: 'Invoice updated',
    add_custom_item: 'Add custom item',
    quantity_carton: 'Carton quantity',
    for_calculation_purpose: 'For price calculation only, won\'t be printed.',
    add_custom_tax: 'Add custom tax',
    percent: 'Percent',
    notify_client: 'Notify client',
    pending_generate: 'Pending creation',
    generated: 'Created',
    status: 'Invoice status',
    payment_status: 'Payment status',
    paid: 'Paid',
    unpaid: 'Unpaid',
    all: 'All',
    international_offices: 'International offices',
    order_payments: 'Order Payments',
    edit_invoice: 'Edit invoice',
    update_invoice: 'Update',
    product_fully_invoiced: 'Product fully invoiced',
    create_credit_invoice: 'Create credit invoice',
    select_invoice: 'Select invoice',
    rectify: 'Rectify',
    rectification_preview: 'Rectification preview'
  },
  charts: {
    top_clients_purchases: 'Top clients purchases',
    confirm_sales_in: 'Confirmed sales in',
    pending_sales_in: 'Pending sales in',
    sales: 'Sales - Confirmed vs Pending',
    pending: 'Pending',
    confirmed: 'Confirmed',
    orders_status: 'Sales by order status',
    top_product_sales: 'Sales by Top Products',
    total_confirmed: 'Total confirmed',
    total_pending: 'Total Pending',
    total_sales: 'Total sales',
    sales_by_broker: 'Brokers performance',
    companies_created: 'Companies created',
    assigned_total_sales: 'Sales assigned',
    fellow_total_sales: 'Sales brokers',
    created_total_sales: 'Sales created',
    cbm_sold: 'Cbm Sold'
  },
  dashboard: {
    coming_events: 'Coming events',
  },
  products_list: {
    filter_catalogue: 'Filter by product location',
    warehouse: 'In Warehouse',
    catalogue: 'Catalogue',
    cargo: 'In Cargo',
    factory: 'In Production',
    max_order: 'Max order',
    click_for_more_options: 'Click for more options',
    offer: 'Offer',
    password_length: 'Password must have 8 characteres',
    order_from: 'Order from',
    from_cargo: 'Cargo',
    from_factory: 'Factory',
    from_warehouse: 'Warehouse',
    from_catalogue: 'Catalogue',
    available_for_order: 'Available',
    flash_label: 'Express products'
  },
  profile: {
    title: 'Account',
    last_name: 'Last name',
    language: 'Language',
    working_categories: 'Working categories',
    password: 'Password',
    confirm_password: 'Confirm password',
    account_updated: 'Account updated'
  },
  orders: {
    pending: 'Pending',
    waiting_customer: 'Waiting customer confirmation',
    production: 'Processing',
    quality_control: 'Quantily control',
    collection: 'Collection',
    shipped: 'Shipped',
    delivered: 'Delivered',
    place_at: 'Placed at',
    estimated_delivery: 'Estimated delivery',
    terms: 'Terms',
    no_payments: 'You have no payments registered at the moment.',
    no_inspections: 'You have no inspections schedule at the moment.',
    pass: 'Pass',
    date: 'Date',
    inspector: 'Inspector',
    result: 'Result',
    incoterm: 'Incoterm',
    transport: 'Transport',
    sea: 'Sea',
    air: 'Air',
    select_cargo: 'Please chose a cargo',
    cbm_used_vs_free: 'used out of',
    weight_used_vs_free: 'kg out of',
    confirm_settings: 'Confirm settings',
    please_confirm: 'Please confirm',
    changing_incoterm_confirmation_text: 'Changing the incoterm on started orders will result in the loss of all the products in your cart. Are you sure you want to proceed?',
    cart: 'Cart',
    clear_cart: 'Clear cart',
    create_order: 'Create order',
    order_incoterm: 'Order incoterm',
    public_url: 'Public link',
    cbm_per_carton: 'CBM per carton',
    box_size: 'Box size',
    subtotal: 'Subtotal',
    distribution_cost: 'Distribution cost',
    available: 'available',
    estimated_departure: 'Estimated departure',
    add_design: 'Add design',
    edit_product: 'Edit Product',
    product_design_name: 'File title',
    product_design_file: 'Select file',
    design_uploaded: 'Design uploaded',
    design_deleted: 'Design deleted',
    logistics_details: 'Logistics details',
    order_assigned_to: 'This order is not assigned to any cargo | This order is assigned to 1 cargo | This order is assigned to {n} cargos',
    quantity_exceed: 'Quantity exceeds container space',
    no_cargos_assigned: 'This order has no cargos assigned',
    expiring_at: 'Expiring at',
    expired: 'Expired',
    all_orders: 'All orders',
    only_active: 'Active orders',
    only_expired: 'Expired',
    only_missing_payment: 'Missing payments',
    expiration_date_alert: 'This order expiry on <strong>{date}</strong>. Please push for payment.',
    save_as_quotation: 'Save as quotation',
    select_delivery_date: 'Delivery date',
    order_before: 'Order before',
    convert_to_order: 'Convert to order',
    products_in_cart: 'Products in cart',
    arrival_date: 'Arrival date',
    bl_number: 'Bill of landing',
    departure_date: 'Departure date',
    transportation: 'Transportation',
    notes: 'Notes',
    add_product: 'Add product',
    search_your_product: 'Search your product',
    product_added: 'Product has been added',
    deleting_confirmation_text: 'The product will be deleted from the order, are you sure?',
    cargos: 'Cargos',
    container_number: 'Container number',
    on_the_way: 'On the way',
    arrived: 'Arrived',
    waiting_for_departure: 'Waiting for departure',
    dispatched: 'Dispatched',
    mark_as_dispatched: 'Mark as dispatched',
    forwarder: 'Forwarder',
    from_to: 'Origin/Destination',
    packing_list_invoice:'Packing list',
    cargo_invoice: 'Invoice',
    original_date: 'Original eta',
    new_date: 'New eta',
    delayed: 'Delayed {days} days.',
    can_not_change_incoterm: 'Can not change incoterm because you are starting an order from catalogue interface.',
    add_button_disabled: 'The incoterm on the cart is different than the catalogue, you can not add products. Please change the catalogue incoterm to {incoterm}.',
    tracking: 'Tracking',
    tracking_failed: 'This shipment could not be tracked at the moment, please check with operations team.',
    shipping_products: 'Products in this shipment',
    location: 'Location',
    payment_success_result: 'The payment was processed successfully. It my take sometime to update. If you have any problem contact us!',
    payment_unsuccess_result: 'The payment could not be completed. Please try again.',
    showing_of: 'Showing {qty} items of {total_qty}.',
    invoices: 'Invoices',
    invoice_number: 'Invoice number',
    reason: 'Reason',
    invoiced: 'Invoiced',
    invoice: 'Invoice',
    invoice_preview: 'Invoice Preview',
    no_possible_invoices: 'Nothing to be invoiced',
    delivery_note: 'Delivery note',
    to: 'To',
    shipment: 'Shipment',
    value: 'Value',
    tax: 'Tax',
    deposit_invoiced: 'Deposit (invoiced)',
    generate_invoice: 'Generate invoice',
    notify_client: 'Notify client',
    client_ref: 'Client Reference',
    gen_cargo_pl: 'Cargo packing list',
    gen_cargo_invoice: 'Cargo invoice',
    gen_cargo_invoice_client: 'Cargo invoice customer'
  },
  validation: {
    this_field_required: 'This field is required',
    email_is_required: 'Email is required',
    please_input_a_valid_email: 'Please input a valid email',
    this_email_is_not_available: 'This email is not available'
  },
  components: {
    hide_price: 'Hide price',
    delivery_date: 'Delivery date',
    export_options: 'Export options',
    select_currency: 'Select currency',
    select_language: 'Select languange',
    select_incoterm: 'Select incoterm',
    set_price: 'Set new price',
    export_history: 'Export History',
    companies: {
      company_details: 'Company Details',
      company_name: 'Company name',
      business_registration_number: 'Business registration number',
      please_white_while_we_load_the_zones: 'Please wait while we load the zones',
      please_wait_while_we_load_the_warehouses: 'Please wait while we load the warehouses',
      preferred_currency: 'Preferred currency',
      please_wait_while_we_load_the_currencies: 'Please wait while we load the currencies',
      user_name: 'User name',
      user_email: 'User email',
      notify_user: 'Notify user',
      create_company: 'Create company',
      company_created_successfully: 'Company created successfully',
      company_updated: 'Company updated',
      categories_interest: 'Categories interest'
    },
    products: {
      total_units_carton: 'Total units',
      cartons_onwards: 'cartons onwards',
      carton: 'carton | cartons',
      unit: 'unit | units',
      ref_number: 'Ref Number',
      weight: 'Weight (kg)',
      carton_size: 'Carton size',
      certificate_name: 'Certificate name',
      certificates: 'Certificates',
      add_to_existing_catalogue: 'Add to existing catalogue',
      add: 'Add',
      new_catalogue: 'New catalogue',
      delivery_alert: 'Confirm the order today to get it by <strong>{date}.</strong>',
      before: 'Before',
      now: 'Now',
      attr_combination_no_in_offer: 'This attributes combination do not have offer.',
      only_offers: 'Show only offers',
      offer_expire: 'Offer expire on',
      download_datasheet: 'Download datasheet',
      price_expired: 'Price expired',
      base_unit: 'Base unit',
      outer_name: 'Outer',
      per: 'per',
      attributes: 'Attributes',
      option_details: 'Option details',
      packing_specs: 'Packing specifications',
      option_search: 'Search options'
    }
  },
  views: {
    orders: {
      order_settings: 'Order settings',
      add_items: 'Select items',
      confirm_order: 'Confirm order',
      start_new_order: 'Start new order',
      continue_order: 'Continue order',
      confirm_order_pay: 'Confirm & take deposit',
    },
    lists: {
      no_lists_text: 'Seems you do not have any lists yet. Please go to the catalogue and create your first list from there.',
      show_products: 'Show products',
      hide_product: 'Hide product | Hide products',
      download_list: 'Download list',
      list_updated: 'List updated',
      list_removed: 'List removed',
      delete_list: 'Delete list',
      save_current_sequence: 'Save sequence',
      new_sequence_saved: 'Sequence saved'
    },
    catalogue: {
      catalogue_subheader: 'Full list of products available.',
      catalogue_flash_subheader: 'List of product with shorter production time. Each product has its own delivery time.',
      page_size: 'Products per page',
    },
    companies: {
      create_new: 'Create a new company',
      editing: 'Edit company',
      companies: 'Companies',
      subtitle: 'Manage companies and users',
      br_number: 'Br Number',
      zone: 'Zone',
      create_user: 'Create user',
      edit_user: 'Edit user',
      set_new_password: 'Set new password',
      notify_user: 'Notify user?',
      update_user: 'Update user',
      add_user: 'Add user',
      //new
      addresses: 'Addresses',
      billing_address: 'Billing address',
      delivery_address: 'Delivery Address',
      delivery_addresses: 'Delivery addresses',
      delivery_details: 'Delivery info',
      address_line_one: 'Address line one',
      address_line_one_placeholder: 'Apt, Floor, Street',
      address_line_two: 'Address line two',
      address_line_two_placeholder: 'Building name, details',
      city: 'City',
      postal_code: 'Postal code',
      please_add_billing_address: 'Please add a billing address',
      no_delivery_addresses: 'You do not have delivery addresses at the moment, we will use your billing info to ship your goods.',
      add_delivery_address: 'Add delivery address',
      address_alias: 'Alias',
      alias_placeholder: 'Friendly name for your address. ex: Home, Factory',
      use_as_default: 'Use as default',
      default_address: 'Default address'
      //end new
    }
  },
  payment: {
    create_payment: 'Create new payment request',
    payment_updated_successfully: 'Payment updated',
    payment_created_successfully: 'Payment created',
    deposit: 'Deposit',
    balance: 'Balance',
    pay_now: 'Pay now',
    full_name: 'Full name',
    secure_payments: 'Secure payments powered by',
    pay_by_credit_card: 'Pay by credit card',
    pay_by_transfer: 'Pay by transfer',
    payment_options: 'Payment options',
    payment_successful: 'Payment was successful',
    payment_type: 'Payment type',
    notify_client_payment_done: 'Notify client of payment received?',
    notify_client_payment_request: 'Notify client of this new request?',
    confirm_payment: 'Confirm payment',
    pending: 'Pending',
    paid: 'Paid',
    first_name: 'First name',
    last_name: 'Last name',
    save_payment_method: 'Save payment method?',
    create_invoice_confirmation: 'This action will create an invoice and can not be reverse. Are you sure to proceed?'
  },
  notifications: {
    notifications_center: 'Notifications center',
    orders_notifications: 'Order notifications',
    payments_notifications: 'Payment notifications',
    catalogue_notifications: 'Catalogue notifications',
    order_product_updated_title: 'Product <strong class="mColor-text">{product_name}</strong> has been updated.',
    done_by: 'By {user}.',
    clear_all: 'Clear all',
    order_payment_created: 'New payment request',
    order_status_changed: 'Order status has changed',
    order_documents_uploaded: 'A new <strong class="mColor-text">{document}</strong> has been uploaded.',
    order_created: 'New {type} created',
    from: 'From',
    to: 'to',
    order_payment_status_changed: 'Payment status has changed',
    order_product_created_title: 'Product <strong class="mColor-text">{product_name}</strong> has been added.',
    order_product_deleted_title: 'Product <strong class="mColor-text">{product_name}</strong> has been deleted.',
    product_created: '<strong class="mColor-text">{product_name}</strong> has been added to the system',
    product_price_updated: 'The PRICE of <strong class="mColor-text">{product_name}</strong> has been updated',
    product_updated: 'Product <strong class="mColor-text">{product_name}</strong> has been modified'
  },
  customDatasheet: {
    show_header: 'Show header',
    image_for_reference_only: 'This image is for reference only',
    created_by: 'Created by',
    phone: 'Phone',
    email: 'Email'
  },
  errors: {
    server_error: 'Something went wrong. Please try different settings and report this issue. Error code: {error_code}, Error message: {error_text}'
  }
}

export default messages