<template>
  <v-container fluid>
    <PageHeader 
      :title="!this.id ? $t('views.companies.create_new') : $t('views.companies.editing')"
    />
    <v-row>
      <v-col
        lg="6"
        md="6"
        sm="12"
      >
        <company-details-form :company="company"></company-details-form>
      </v-col>
      <v-col
        lg="6"
        md="6"
        sm="12"
      >
        <company-addresses v-if="id" :company="company" @update="loadCompany"></company-addresses>
        <company-users-list v-if="id" :id="id"></company-users-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '../../components/PageHeader.vue';
import CompanyDetailsForm from '../../components/companies/CompanyDetailsForm.vue';
import CompanyUsersList from '../../components/companies/CompanyUsersList.vue';
import companyAddresses from '../../components/companies/CompanyAddresses.vue';

export default {
  name: 'Companies',
  props: ['id'],
  components: {
    PageHeader,
    CompanyDetailsForm,
    CompanyUsersList,
    companyAddresses
  },
  data() {
    return {
      loading: true,
      company: null,
      currencies: ['USD'],
      zones: [],
      warehouses: []
    }
  },
  methods: {
    loadCompany() {
      //we try to load the company and fill up the form
      if(this.id) {
        this.$http.get(this.endpoint(`customer/get/${this.id}`))
        .then(resp => {
          this.company = resp.data.data;
          this.company.category_ids = resp.data.data.categories.map( c => c.id );
          this.loading = false;
        })
      } else {
        this.loading = false
      }
    }
  },
  beforeMount() {
    this.loadCompany();
  },
}
</script>
