import controller from './controller';
import { 
  COMPANY_ADDRESS_INSERT,
  COMPANY_ADDRESS_UPDATE,
  COMPANY_ADDRESS_DELETE
} from '../constants/endpoints.js';

export default class companyController extends controller {

  async updateAddress(company_id, params) {
    var endpoint = params.uuid === null ? COMPANY_ADDRESS_INSERT : COMPANY_ADDRESS_UPDATE;
    try {
      const result = await this.sendRequest('POST', this.buildEndpoint(endpoint, { customer_company_id: company_id }), params);
      if(result.data.result) {
        return result.data.data;
      }
      throw result.data;
    } catch(e) {
      console.warn('companyController?updateCompany', e.response);
    }
  }

  async deleteAddress(company_id, params) {
    try {
      const result = await this.sendRequest('POST', this.buildEndpoint(COMPANY_ADDRESS_DELETE, { customer_company_id: company_id }), params);
      if(result.data.result) {
        return result.data.data;
      }
      throw result.data;
    } catch(e) {
      console.warn('companyController?deleteAddress', e.response);
    }
  }

}