const endpoint = process.env.VUE_APP_API_ENDPOINT;
const endpoint_payment = process.env.VUE_APP_API_ENDPOINT_GLOBAL;

//catalogue
export const CATALOGUE_GET = endpoint+'catalogue/get_v2';
export const CATALOGUE_GET_PRODUCT = endpoint+'catalogue/get/:id';
export const CATALOGUE_UNSOLD_GET = endpoint+'catalogue/unsold/get';
export const NOVELTY_PRODUCTS_GET = endpoint+'catalogue/novelty_products/list';
export const CATEGORIES_GET = endpoint+'category/get';
export const GET_PRODUCT_PRICE_HISTORY = endpoint+'catalogue/price_history/:product_id';
export const GET_WAREHOUSE_PRODUCTS = endpoint+'warehouse/get_products';

//orders
export const ORDER_GET = endpoint+'order/get';
export const ORDER_LIGHT_GET = endpoint+'order/light/get';
export const CREATE_ORDER = endpoint+'order/create';
export const GET_ORDERS_FILTERS = endpoint+'order/get_orders_filters';
export const ADD_PRODUCT_TO_ORDER = endpoint+'order/:order_id/add_order_product';
export const PAYMENT_PAYPAL_CREATE = endpoint+'payments/create_paypal_order';
export const PAYMENT_PAYPAL_CHECK = endpoint+'payments/check_paypal_order';
export const PAYMENT_BRAINTREE_TOKEN = endpoint_payment+'payments/bt_get_guest_token';
export const PAYMENT_BRAINTREE_SUBMIT = endpoint_payment+'payments/bt_submit_guest_payment';
export const PAYMENT_GENERATE_DEPOSIT_INVOICE = endpoint+'invoice/create_for_deposit_payment/:payment_protect_id';
export const INVOICE_OPTIONS = endpoint+'invoice/co/:order_id/get';
export const INVOICE_MAKE = endpoint+'invoice/co/:order_id/preview';
export const INVOICE_LIST = endpoint+'invoice/list_v2';
export const INVOICE_GET = endpoint+'invoice/get/:invoice_id';
export const INVOICE_UPDATE = endpoint+'invoice/update/:invoice_id';
export const INVOICE_GET_ORDER_DATA = endpoint+'invoice/custom/order_data/:order_id';
export const INVOICE_MAKE_CUSTOM = endpoint+'invoice/custom/create';
export const INVOICE_MAKE_CREDIT = endpoint+'invoice/credit_invoice/create';
export const ORDER_GET_PROTECTED = endpoint+'order/get_protected/:protected_id';

export const GET_OFFICES = endpoint+'offices/get';

//shipping orders
export const SHIPPING_ORDERS_GET = endpoint+'shipping_orders/get';
export const SHIPPING_ORDERS_GET_DETAILS = endpoint+'shipping_orders/get/:shipping_order_id';
export const SHIPPING_ORDERS_MARK_AS_DISPATCHED = endpoint+'shipping_orders/:id/mark_as_dispatched';

//reports
export const SALES_BY_BROKER = endpoint+'reports/sales_by_broker';
export const GET_BROKERS = endpoint+'get_brokers';

//user
export const USER_UPDATE = endpoint+'update';

//Companies
export const COMPANY_ADDRESS_INSERT = endpoint+'customer/:customer_company_id/address/insert';
export const COMPANY_ADDRESS_UPDATE = endpoint+'customer/:customer_company_id/address/update';
export const COMPANY_ADDRESS_DELETE = endpoint+'customer/:customer_company_id/address/delete';