var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['wrapper', { hidden: !_vm.value }],attrs:{"elevation":"2"}},[_c('v-sheet',{staticClass:"rounded",attrs:{"elevation":"10","width":"100%"}},[_c('ul',[_c('li',{staticClass:"counter",style:({ backgroundColor: _vm.hexColor })},[_c('h3',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.selected.length))])]),_c('li',[_c('v-menu',{attrs:{"offset-y":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"activators",attrs:{"x-large":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-folder")]),_vm._v(" "+_vm._s(_vm.$t('components.products.new_catalogue'))+" ")],1)]}}]),model:{value:(_vm.newList),callback:function ($$v) {_vm.newList=$$v},expression:"newList"}},[_c('v-list',[_c('v-list-item',[_c('v-form',{ref:"newListForm"},[_c('v-text-field',{ref:"newListNameField",attrs:{"label":"New list name","required":"","autofocus":"","rules":_vm.requiredRules},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":_vm.vColor,"elevation":"2","dark":""},on:{"click":_vm.addNewList}},[_vm._v(_vm._s(_vm.$t('components.products.add')))])]},proxy:true}]),model:{value:(_vm.newListName),callback:function ($$v) {_vm.newListName=$$v},expression:"newListName"}})],1)],1)],1)],1)],1),_c('li',[_c('v-menu',{attrs:{"offset-y":"","top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"activators",attrs:{"x-large":""}},'v-btn',attrs,false),{
                'click': _vm.loadExistingLists
              }),[_c('v-icon',[_vm._v("fa-folder-plus")]),_vm._v(" "+_vm._s(_vm.$t('components.products.add_to_existing_catalogue'))+" ")],1)]}}]),model:{value:(_vm.existingList),callback:function ($$v) {_vm.existingList=$$v},expression:"existingList"}},[_c('v-list',[_c('v-list-item',[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"append-icon":"mdi-magnify"},on:{"keyup":_vm.filterSearch},model:{value:(_vm.optionsSearch),callback:function ($$v) {_vm.optionsSearch=$$v},expression:"optionsSearch"}})],1),_c('v-divider'),_vm._l((_vm.lists),function(list){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(list.inSearch),expression:"list.inSearch"}],key:list.id,on:{"click":function($event){return _vm.addToExistingList(list.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(list.name))])],1)})],2)],1)],1),_c('li',{on:{"click":function($event){return _vm.hide()}}},[_c('v-btn',{attrs:{"x-large":""},on:{"click":function($event){return _vm.hide()}}},[_c('v-icon',[_vm._v("fa-times")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }