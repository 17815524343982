<template>
  <div>
    <loader v-model="loading"></loader>
    <v-data-table
      :headers="tableHeaders"
      :items="invoice_options"
      :no-data-text="$t('orders.no_possible_invoices')"
      :page.sync="pagination.currentPage"
      :options.sync="options"
      :server-items-length="pagination.totalPages"
      hide-default-footer
    >
      <template
        v-if="order === null"
        v-slot:top
      >
        <v-row v-if="user.is_admin === 1">
          <v-col>
            <office-select
              v-model="office"
              @change="loadInvoices()"
            ></office-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="[
                { text: $t('invoices.pending_generate'), value: 'pending_generate' },
                { text: $t('invoices.generated'), value: 'generated' }
              ]"
              :label="$t('invoices.status')"
              v-model="invoiceStatus"
              @change="loadInvoices()"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="[
                { text: $t('invoices.all'), value: 'all' },
                { text: $t('invoices.paid'), value: 'paid' },
                { text: $t('invoices.unpaid'), value: 'unpaid' }
              ]"
              :label="$t('invoices.payment_status')"
              v-model="payment_status"
              @change="loadInvoices()"
              :disabled="invoiceStatus === 'pending_generate'"
            ></v-select>
          </v-col>
          <v-col>
            <orders-filters
              v-model="company"
              @change="loadInvoices()"
              hide-products
            ></orders-filters>
          </v-col>
          <v-col>
            <v-text-field
              :items="companies"
              :label="$t('search')"
              v-model="search"
              @change="loadInvoices()"
              :disabled="invoiceStatus === 'pending_generate'"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <div v-if="item.payment_record !== null && item.payment_record.reason === 'deposit'">{{ $t('payment.deposit') }}</div>
        <div v-if="item.shipping_order_id === null && item.invoice.type === 4">{{ $t('invoices.manual') }}</div>
        <div v-else>
          {{ $t('payment.balance') }} - {{ $tc('shipping_orders', 1) }}: {{ item.shipping_order_id }}
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <div v-if="item.invoice">{{ formatDate(item.invoice.created_at) }}</div>
      </template>
      <template v-slot:[`item.invoice_number`]="{ item }">
        <div v-if="item.invoice">
          <div>
            {{ item.invoice.invoice_no }}
            <v-tooltip
              v-if="item.invoice.internal_notes !== null" 
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="primary"
                >mdi-alert-circle</v-icon>
              </template>
              <span>{{ item.invoice.internal_notes }}</span>
            </v-tooltip>
          </div>
          <div 
            v-if="item.invoice.invoice_type === 'credit_invoice'"
            class="caption red--text font-weight-bold"
          >{{ item.invoice.sale_invoice_no }}</div>
        </div>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <div>{{ numberToNiceString(item.amount, item.currency, 2) }}</div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status === 'pending_generate' ? $t('payment.pending') : $t('orders.invoiced') }}
      </template>
      <template v-slot:[`item.paid_at`]="{ item }">
        <div v-if="item.payment_record">
          <span v-if="item.payment_record.paid_at !== null">{{ formatDate(item.payment_record.paid_at) }}</span>
          <div v-else>
            <span class="mColor-text">{{ $t('orders.pending') }}</span><br>
            <span v-if="item.payment_record.expired_at !== null" class="text-italic">{{ $t('due_date') }}: {{ formatDate(item.payment_record.expired_at) }}</span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <button-with-options>
          <template v-slot:main-action>
            <v-btn
              v-if="item.invoice === null"
              dark
              fab
              color="indigo"
              @click="previewInvoice(item, item.type)"
              x-small
            >
              <v-icon>mdi-file-eye-outline</v-icon>
            </v-btn>
            <v-btn
              v-else
              dark
              depressed
              :color="$store.getters.vColor"
              :href="item.invoice.download_url"
              target="_black"
              x-small
            >{{ $t('download') }}</v-btn>
          </template>
          <template v-if="item.invoice !== null && item.invoice.invoice_type !== 'credit_invoice'" v-slot:secondary-actions>
            <v-list dense>
              <v-list-item
                v-if="item.invoice && (item.payment_record === null || (item.payment_record && item.payment_record.reason !== 'deposit'))"
                depressed
                :href="item.invoice.delivery_note_url"
                target="_blank"
              >
                <v-list-item-title>{{ $t('orders.delivery_note') }} <v-icon small>mdi-download</v-icon></v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('editInvoice', item.invoice_id)">
                <v-list-item-title>{{ $t('edit') }} <v-icon small>mdi-pen</v-icon></v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('rectifyInvoice', item.invoice_id)">
                <v-list-item-title>{{ $t('invoices.rectify') }} <v-icon small>mdi-hammer-wrench</v-icon></v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </button-with-options>
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-3"
          v-model="pagination.currentPage"
          :length="pagination.totalPages"
        ></v-pagination>
      </template>
    </v-data-table>
    <v-dialog
      v-model="preview"
    >
      <invoice-preview
        v-if="preview"
        :order="orderData" 
        :invoice-data="invoice_data"
        @cancel="preview = false"
        @confirmed="loadInvoices"
      ></invoice-preview>
    </v-dialog>
  </div>
</template>
<script>
import loader from '../loadingBox.vue';
import invoicePreview from './invoicePreview.vue';
import { INVOICE_LIST, INVOICE_MAKE } from '@/constants/endpoints.js';
import { mapGetters } from 'vuex';
import officeSelect from '../officeSelect.vue';
import ordersFilters from '../orders/ordersFilters.vue';
import buttonWithOptions from '../buttonWithOptions.vue';

export default {
  name: 'OrderInvoices',
  props: {
    order: {
      default: null
    }
  },
  components: {
    loader,
    invoicePreview,
    officeSelect,
    ordersFilters,
    buttonWithOptions
  },
  data() {
    return {
      loading: true,
      invoice_options: [],
      invoice_data: null,
      preview: false,
      invoiceStatus: 'generated',
      search: '',
      payment_status: 'all',
      companies: [],
      orderData: {},
      office: null,
      pagination: {
        currentPage: 1,
        totalPages: 1
      },
      options: {},
      company: {
        company: null,
        product: null
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.loadInvoices()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['user']),
    tableHeaders() {
      if(this.order) {
        return [
          { text: this.$t('orders.invoice_number'), align: 'start', sortable: true, value: 'invoice_number' },
          { text: this.$t('orders.reason'), align: 'start', sortable: true, value: 'type' },
          { text: this.$t('created'), align: 'start', sortable: true, value: 'date' },
          { text: this.$t('amount'), align: 'start', sortable: true, value: 'amount' },
          { text: this.$t('paid_at'), align: 'start', sortable: false, value: 'paid_at' },
          { text: '', align: 'end', sortable: false, value: 'actions' },
        ];
      } else {
        return [
          { text: this.$t('orders.invoice_number'), align: 'start', sortable: false, value: 'invoice_number' },
          { text: this.$tc('order', 1), align: 'start', sortable: false, value: 'order_sku' },
          { text: this.$t('company'), align: 'start', sortable: false, value: 'company_name' },
          { text: this.$t('orders.reason'), align: 'start', sortable: false, value: 'type' }, 
          { text: this.$t('created'), align: 'start', sortable: false, value: 'date' },
          { text: this.$t('amount'), align: 'start', sortable: false, value: 'amount' },
          { text: this.$t('paid_at'), align: 'start', sortable: false, value: 'paid_at' },
          { text: '', align: 'end', sortable: false, value: 'actions' },
        ];
      }
    }
  },
  methods: {
    previewInvoice(inv, type) {
      //console.log(inv,type);
      this.loading = true;
      var deposit = type === 'deposit' ? true : false;
      var order_id = this.order === null ? inv.order_id : this.order.id;
      var data = {
        bank_id: 1,
        shipping_order_id: inv.shipping_order_id !== undefined ? inv.shipping_order_id : null,
        is_deposit: deposit,
        notes: null,
        internal_notes: null,
        is_create: false
      };
      //console.log(data);
      this.$http.post(this.buildEndpoint(INVOICE_MAKE, { order_id: order_id }), data)
      .then( resp => {
        console.log(resp);
        this.orderData = resp.data.data.order;
        this.loading = false;
        this.preview = true;
        this.invoice_data = resp.data.data;
      });
    },
    loadInvoices() {
      this.loading = true;
      this.preview = false;
      var order_id = this.order === null ? null : this.order.id
      this.$http.post(this.buildEndpoint(INVOICE_LIST), {
        type: this.invoiceStatus,
        paid: this.payment_status,
        order_id: order_id,
        search: this.search,
        company_id: this.company.company,
        office_id: this.office,
        page_no: this.pagination.currentPage
      }).then( resp => {
        this.loading = false;
        if(resp.data.data.records && resp.data.data.pagination) {
          this.invoice_options = resp.data.data.records;
          //console.log(this.invoice_options)
          this.pagination = resp.data.data.pagination;
        } else {
          this.invoice_options = resp.data.data;
        }
        console.log(this.invoice_options)
      });
    }
  },
  mounted() {
    this.office = this.user.office ? this.user.office.id : null;
    this.loadInvoices();
  }
}
</script>