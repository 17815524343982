<template>
	<v-card v-if="this.invoiceData != null">
		<v-card-title>
			{{ $t('orders.invoice_preview') }}
		</v-card-title>
    <v-divider></v-divider>
		<v-card-text v-if="invoiceData != null" class="preview_area">
			<table width="100%">
				<tr>
					<td width="50%">
						<img
							v-if="prefs.LOGO != undefined && prefs.LOGO != null"
							width="200"
							:src="prefs.LOGO"
							alt="Company Logo"
						/>
					</td>
					<td width="10%">
						&nbsp;
					</td>
					<td width="40%" class="text-right">
						<h6>{{ prefs.COMPANY_NAME }}</h6>
					</td>
				</tr>
			</table>

			<table width="100%">
				<tr>
					<td>
						<h4>{{ $t("orders.invoice") }} #{{ invoiceData.invoice_number_display }}</h4>
					</td>
					<td class="text-right">
						<b>{{ $t("date") }}:</b>
						{{ invoiceData.invoice_date_display }}
						<br />
						<template v-if="invoiceData.expire_date_display != null">
							<b>{{ $t("orders.expiring_at") }}:</b> {{ invoiceData.expire_date_display }}
						</template>
					</td>
				</tr>
			</table>

			<table width="100%">
				<tr>
					<td width="50%" style="vertical-align:top;">
						<p class="corporate_color">
							<strong>{{ $t('orders.to') }}:</strong>
						</p>
						<h2>{{ invoiceData.customer_name }}</h2>

						<p v-if="order.company.br_number != null">
							<strong>{{ $t('views.companies.br_number') }}: </strong
							>{{ order.company.br_number }}
						</p>
						<p>
							<strong>{{ $t('address') }}: </strong>
							{{ order.company.address }}
						</p>
					</td>
					<td width="50%" style="vertical-align:top;">
						<p class="corporate_color">
							<strong>{{ $t('orders.terms') }}:</strong>
						</p>
						<p style="font-size: 13px;">
							<strong>{{ $t('orders.incoterm') }}:</strong> {{ order.incoterm }}
						</p>
						<p style="font-size: 13px;">
							<strong>{{ $t('orders.shipment') }}:</strong>
							{{ order.transport_mode == "port" ? $t('orders.sea') : $t('orders.air') }}
						</p>
						<p style="font-size: 13px;">
							<strong>{{ $t("currency") }}: </strong>
							{{ order.currency }}
							<span
								v-if="order_rates != null && order.currency != order_rates.base"
							>
								({{ order.order_currency.rate + " to " + order_rates.base }})
							</span>
						</p>

						<p v-if="order.reference != null" style="font-size: 13px;">
							<strong>{{ $t("reference") }}:</strong> {{ order.reference }}
						</p>
					</td>
				</tr>
				<tr>
					<!--<td colspan="2">
                        <p class="corporate_color"><strong>{{ $t('Bank:') }}</strong></p>
                        <p style="font-size: 13px;">
                            <strong>{{ $t('Name') }}:</strong> {{ bank.name }}
                        </p>
                        <p style="font-size: 13px;">
                            <strong>{{ $t('Acct no') }}:</strong> {{ bank.acct_no }}
                        </p>
                        <p style="font-size: 13px;">
                            <strong>{{ $t('Swift') }}:</strong> {{ bank.swift }}
                        </p>
                        <p style="font-size: 13px;">
                            <strong>{{ $t('Bank code') }}:</strong> {{ bank.bank_code }}
                        </p>
                        <p style="font-size: 13px;">
                            <strong>{{ $t('Branch no') }}:</strong> {{ bank.branch_no }}
                        </p>
                        
                    </td>-->
				</tr>
				<tr v-if="invoiceData.notes != null">
					<td colspan="2">
						<p>
							<strong>{{ $t("Notes") }}: </strong>{{ invoiceData.notes }}
						</p>
					</td>
				</tr>
			</table>

			<!-- product list -->
			<h6 style="margin-top: 25px;" class="corporate_color">
				{{ $tc('order', 1) }}
			</h6>
			<v-data-table
				:headers="[
					{ text: $t('name'), align: 'start', sortable: false, value: 'name' },
					{ text: $t('quantity'), align: 'start', sortable: false, value: 'quantity' },
					{ text: $t('invoices.unit_price'), align: 'start', sortable: false, value: 'unit_price' },
					{ text: $t('subtotal'), align: 'start', sortable: false, value: 'base_price' },
					{ text: $t('tax'), align: 'start', sortable: false, value: 'tax_pct' },
					{ text: $t('total'), align: 'end', sortable: false, value: 'total_price_string' }
				]"
				:items="invoiceData.items"
				disable-pagination
				hide-default-footer
			>
				<template v-slot:[`item.name`]="{ item }">
					<div>{{ item.name.es }}</div>
				</template>
				<template v-slot:[`item.quantity`]="{ item }">
					<div>{{ item.quantity }}</div>
				</template>
				<template v-slot:[`item.tax_pct`]="{ item }">
					<div>{{ item.tax_pct }}%</div>
				</template>
				<template v-slot:[`item.unit_price`]="{ item }">
					<div>{{ numberToNiceString(item.unit_price, order.currency, 2) }}</div>
				</template>
				<template v-slot:[`item.base_price`]="{ item }">
					<div>{{ numberToNiceString(item.amount, order.currency, 2) }}</div>
				</template>
				<template v-slot:[`item.total_price_string`]="{ item }">
					<div>{{ numberToNiceString(item.amount_with_tax, order.currency, 2) }}</div>
				</template>
				<template v-slot:[`body.append`]>
					<tr>
						<td colspan="4"></td>
						<td>{{ $t('invoices.subtotal') }}</td>
						<td>
							<h4 class="text-right">{{ numberToNiceString(invoiceData.tax_base, order.currency, 2) }}</h4>
						</td>
					</tr>
					<tr>
						<td colspan="4"></td>
						<td>{{ $t('tax') }}</td>
						<td>
							<h4 class="text-right">{{ numberToNiceString(invoiceData.tax, order.currency, 2) }}</h4>
						</td>
					</tr>
					<tr>
						<td colspan="4"></td>
						<td>{{ $t('total') }}</td>
						<td>
							<h4 class="text-right">{{ numberToNiceString(invoiceData.total_with_tax, order.currency, 2) }}</h4>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        color="blue darken-2" 
        elevation="1"
        dark
        @click="$emit('cancel')"
        :loading="loading"
      >
        <v-icon>mdi-multiply</v-icon>
        {{ $t("cancel") }}
      </v-btn>
      <v-spacer></v-spacer>
			<v-switch
				v-model="notify"
				inset
				:label="$t('invoices.notify_client')"
				class="mr-3"
			></v-switch>
      <v-btn
        color="red darken-4"
        elevation="1"
        dark 
        @click="confirm"
        :loading="loading"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t("orders.generate_invoice") }}
      </v-btn>
    </v-card-actions>
	</v-card>
</template>

<script>
import { INVOICE_MAKE } from '@/constants/endpoints.js';
export default {
	name: "invoicePreview",
	props: {
    order: {
      default: () => {
				return {};
			}
    },
		invoiceData: {
			default: null
		},
		prefs: {
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
      loading: false,
			notify: true
		}
	},
	computed: {
		//for ease of use
		order_rates() {
			if (this.order != null && this.order.order_currency != null) {
				return JSON.parse(this.order.order_currency.rates);
			}
			return null;
		}
	},
	methods: {
    confirm() {
      this.loading = true;
      var deposit = this.invoiceData.type === 1 ? true : false;
      this.$http.post(this.buildEndpoint(INVOICE_MAKE, { order_id: this.order.id }), {
        bank_id: null,
        shipping_order_id: this.invoiceData.shipping_order_id !== undefined ? this.invoiceData.shipping_order_id : null,
        is_deposit: deposit,
        notes: null,
        internal_notes: null,
        is_create: true,
				notify: this.notify ? 1 : 0
      }).then( () => {
        this.loading = false;
        this.$emit('confirmed');
      });
    }
	},
	mounted() {
    console.log(this.invoiceData);
  }
};
</script>
<style scoped>
.preview_area p {
	margin: 0;
	padding: 0;
}
.preview_area td,
th {
	padding: 5px;
}

.corporate_color {
	color: #b51225;
}

table.main_list td {
	border-bottom: 1px solid #f2f2f2;
}
table.main_list thead {
	background-color: #f2f2f2;
}
</style>
