<template>
  <v-container fluid>
    <page-header :title="$t('profile.title')"></page-header>
    
    <v-card>
      <v-card-text>
        <v-form ref="profile_form" @submit.prevent="update">
          <v-text-field
            v-model="profile.first_name"
            :label="$t('name')"
            :rules="requiredRule"
          ></v-text-field>
          <v-text-field
            v-model="profile.last_name"
            :label="$t('profile.last_name')"
            :rules="requiredRule"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :label="$t('profile.password')"
            :rules="[passwordLength]"
          ></v-text-field>
          <v-text-field
            v-model="rePassword"
            :label="$t('profile.confirm_password')"
            :rules="[passwordConfirmationRule]"
          ></v-text-field>
          <v-select
            v-model="profile.lang"
            :items="languages"
            item-text="name"
            item-value="code"
            :label="$t('profile.language')"
            :loading="loadingLanguages"
          ></v-select>
          <v-select
            v-model="profile.working_categories"
            :items="categories"
            :label="$t('profile.working_categories')"
            :loading="loadingCategories"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip outlined v-if="index < 5">
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="grey--text text-caption"
              >
                (+{{ profile.working_categories.length - 2 }} others)
              </span>
            </template>
          </v-select>
          <v-btn
            type="submit"
            color="primary"
            dark
            elevation="1"
            x-large
          >{{ $t('save_changes') }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import { requiredRule } from '../constants/formRules';
import { mapGetters } from 'vuex';
import { getLanguages } from '../endpoints';
import { USER_UPDATE } from '../constants/endpoints';


export default {
  name: 'Account',
  components: {
    PageHeader
  },
  data() {
    return {
      profile: {},
      languages: [],
      loadingLanguages: true,
      categories: [],
      loadingCategories: true,
      password: '',
      rePassword: '',
      requiredRule,
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    passwordConfirmationRule() {
      return () => (this.password === this.rePassword) || 'Password must match';
    },
    passwordLength() {
      return () => (this.password < 8) || this.$t('password_length');
    }
  },
  methods: {
    loadCategories() {
      this.$http.get(this.endpoint(`category/get`), { 
        params: {
          incoterm: 'FOB'
        } 
      }).then( resp => {
        var vue = this;
        resp.data.data.map( c => {
          if(c.header) {
            vue.categories.push({
              text: c.header,
              value: c.id
            });
          }
        });
        this.loadingCategories = false;
      })
    },
    update() {
      if(this.$refs.profile_form.validate()) {
        this.$store.commit('isLoading', true);
        this.$http.post(this.buildEndpoint(USER_UPDATE), this.profile)
        .then(resp => {
          var user = resp.data.data;
          this.$store.commit('languageChange', user.lang);
          this.$i18n.locale = user.lang;
          this.$store.commit('updateUser', user);
          this.$store.commit('isLoading', false);
          this.$toasted.success(this.$t('profile.account_updated'));
        });
      }
    }
  },
  mounted() {
    this.$store.commit('isLoading', false);
    this.profile = Object.assign({}, this.user);
    getLanguages()
    .then( languages => {
      this.languages = languages;
      this.loadingLanguages = false;
    });
    this.loadCategories();
    //console.log(this.profile);
  }
}
</script>