<template>
  <v-autocomplete
    v-model="list"
    :label="$t('custom_lists')"
    :items="catalogues"
    :loading="loading"
    item-text="name"
    item-value="id"
    clearable
    @change="$emit('input', catalogue)"
    @focus="loadCatalogues()"
    open-on-clear
  >
  </v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'CustomCatalogueSelection',
  props: ['value'],
  data() {
    return {
      catalogues: [],
      catalogue: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters(['customList']),
    inOrders() {
      return (this.$router.currentRoute.name == 'OrdersNew') ? true : false
    },
    list: {
      get() {
        return this.customList;
      },
      set(val) {
        this.setCustomList(val);
      }
    }
  },
  methods: {
    ...mapActions(['setCustomList']),
    loadCatalogues() {
      return new Promise((resolve/*, reject*/) => {
        this.$http.get(this.endpoint(`my_catalogues`))
          .then( resp => {
            if(resp.data.result == true) {
              this.catalogues = resp.data.data.adminCatalogues
              resolve()
            }
          })
      })
    }
  },
  beforeMount() {
    var vue = this
    this.loadCatalogues()
    .then( () => {
      vue.loading = false
    })
  }
}
</script>