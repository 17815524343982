<template>
  <v-container fluid>
    <page-header :title="title" :subheader="subheader"></page-header>
    <v-card class="pa-5">
      <products-list></products-list>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import ProductsList from '@/components/products/ProductsList.vue';

export default {
  name: 'Catalogue',
  data: function() {
    return {
      title: this.$t('catalogue'),
      subheader: this.$t('views.catalogue.catalogue_subheader')
    }
  },
  components: {
    PageHeader,
    ProductsList
  }
}
</script>
