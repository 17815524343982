<template>
  <div class="newOrder">
    <v-container fluid>
      <PageHeader :title="$t('orders.create_order')" subheader="" />
      <div>
        <v-stepper
          v-model="step"
        >
          <v-stepper-header>
            <v-stepper-step
              step="1"
              :complete="step > 1"
              edit-icon="mdi-pencil"
              editable
              @click="navigateToStep(1)"
            >
              {{ $t('views.orders.order_settings') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              :editable="company.id !== undefined"
              :complete="step > 2"
              edit-icon="mdi-pencil"
              @click="navigateToStep(2)"
            >
              {{ $t('views.orders.add_items') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              :editable="count > 0"
              :complete="step > 3"
              @click="navigateToStep(3)"
            >
              {{ $t('views.orders.confirm_order') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <order-new-settings
                v-if="step == 1"
                @onConfirm="step = 2"
              ></order-new-settings>
            </v-stepper-content>

            <v-stepper-content step="2">
              <products-list
                v-if="step == 2"
                forOrders
                @addModal="productModal"
              ></products-list>
            </v-stepper-content>

            <v-stepper-content step="3">
              <cart-checkout v-if="step == 3"></cart-checkout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <product-modal
        :product="productInModal"
        v-if="showProductModal"
        @toggleDialogStatus="toggleDialog()"
        :can-order="true"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader';
import ProductsList from '../../components/products/ProductsList.vue';
import ProductModal from '../../components/orders/productModal';
import CartCheckout from '../../components/orders/CartCheckout';
import orderNewSettings from '../../components/orders/orderNewSettings';

export default {
  name: 'OrdersNew',
  components: {
    PageHeader,
    ProductsList,
    ProductModal,
    CartCheckout,
    orderNewSettings
  },
  data() {
    return {
      loading: true,
      step: this.$route.query.step === undefined ? 1 : this.$route.query.step,
      showCart: false,
      listHeader: [
        { text: '', col: 1 },
        { text: 'Name', col: 4 },
        { text: 'Packing', col: 2 },
        { text: 'Price', col: 4 }
      ],
      products: [],
      productInModal: {},
      showProductModal: false
    }
  },
  computed: {
    ...mapGetters('cart', [
      'company',
      'subtotal',
      'cbm',
      'origin_zone',
      'destination_zone',
      'count'
    ])
  },
  watch: {
    '$route.query'() {
      console.log(this.$route.query.step)
      if(this.$route.query.step !== undefined) {
        this.step = this.$route.query.step;
      }
    }
  },
  methods: {
    toggleCart() {
      this.showCart = !this.showCart
    },
    toggleDialog() {
      this.showProductModal = !this.showProductModal
    },
    productModal(product) {
      this.productInModal = product
      this.showProductModal = true
    },
    remove(id) {
      this.$store.dispatch('removeFromCart', id)
    },
    navigateToStep(val) {
      if(val !== this.$route.query.step) {
        this.$router.push({name: 'OrdersNew', query: { step: val}})
      }
    }
  }
}
</script>
