var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"search":_vm.table.search,"headers":_vm.table.headers,"items":_vm.products,"items-per-page":10,"hide-default-footer":_vm.isPreview},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name)+" - "),_c('strong',{staticClass:"mColor-text"},[_vm._v(_vm._s(item.option.key)+":")]),_vm._v(" "+_vm._s(item.option.value))]),(item.attributes.length > 0)?_c('div',{staticClass:"text-caption"},_vm._l((item.attributes),function(attr){return _c('span',{key:attr.key,staticClass:"pr-3"},[_c('strong',[_vm._v(_vm._s(attr.key)+":")]),_vm._v(" "+_vm._s(attr.value))])}),0):_vm._e()]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.designs.length)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(_vm.$tc('carton', item.quantity)))]),_c('p',{staticClass:"pa-0 mb-0"},[_vm._v(_vm._s(item.total_units)+" "+_vm._s(_vm.unit(item)))])]}},{key:"item.total_price_string",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.total_price_string))]),_c('p',{staticClass:"pa-0 mb-0"},[_vm._v(_vm._s(item.unit_price_string)+" "+_vm._s(_vm.unit(item, 1)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-btn',{attrs:{"color":_vm.$store.getters.vColor,"fab":"","dark":"","x-small":"","elevation":"1"},on:{"click":function($event){return _vm.editProduct(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}},(_vm.isPreview === false)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',[_vm._v(_vm._s(_vm.round(_vm.order.total_cbm)))]),_c('td',[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.order.total))])]),_c('td')])]},proxy:true}:null],null,true)}),_c('v-dialog',{attrs:{"max-width":"720"},model:{value:(_vm.productEditDialog),callback:function ($$v) {_vm.productEditDialog=$$v},expression:"productEditDialog"}},[(_vm.productEditDialog)?_c('order-product-edit',_vm._g({attrs:{"order":_vm.order,"product":_vm.editingProduct,"can-modify":_vm.canModify},on:{"productDeleted":function($event){_vm.productEditDialog = false}}},_vm.$listeners)):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }