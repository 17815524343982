<template>
    <v-container fluid>
      <PageHeader :title="title" :subheader="subheader" />
      <v-card class="pa-5">
        <products-list flash></products-list>
      </v-card>
    </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import ProductsList from '@/components/products/ProductsList.vue'

export default {
  name: 'Catalogue',
  data: function() {
    return {
      title: this.$t('catalogue_flash'),
      subheader: this.$t('views.catalogue.catalogue_flash_subheader')
    }
  },
  components: {
    PageHeader,
    ProductsList
  }
}
</script>
