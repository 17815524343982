<template>
  <v-data-table
    hide-default-footer
    dense
    :headers="headers"
    :items="wp"
    :items-per-page="25"
  >
    <template v-slot:[`item.timestamp`]="{ item }">
      {{ formatDate(item.timestamp) }}
    </template>
    <template v-slot:[`item.planned`]="{ item }">
      <div 
        v-if="item.planned === true"
        class="rounded_icon pending"
        
      >
        <v-icon dark x-small>mdi-clock</v-icon>
        
      </div>
      <div 
        v-else
        class="rounded_icon happened"
      >
        <v-icon dark x-small>mdi-check-bold</v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'trackingWaypoints',
  props: {
    waypoints: {
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('orders.location'), align: 'start', sortable: false, filterable: false, value: 'location' },
        { text: this.$t('date'), align: 'start', sortable: false, filterable: false, value: 'timestamp' },
        { text: this.$t('action'), align: 'start', sortable: false, filterable: false, value: 'description' },
        { text: '', align: 'start', sortable: false, filterable: false, width: '50px', value: 'planned' },
      ]
    }
  },
  computed: {
    wp() {
      if(this.waypoints !== null) {
        return this.waypoints;
      }
      return [];
    }
  }
}
</script>

<style scoped lang="scss">
.rounded_icon {
  border-radius: 100%;
  padding: 2px 6px;
  &.pending {
    background-color: #FF9800;
  }
  &.happened {
    background-color: #4CAF50;
  }
}
</style>
