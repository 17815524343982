<template>
  <v-card
    outlined
    tile
    hover
    :class="['tile-content', { is_flash: product.is_flash }]"
    @click="$store.dispatch('loadProductDetails', product.id)"
  >
    <product-flags :product="product"></product-flags>
    <img
      class="tile-product-image"
      contain
      :src="product.media ? product.media.small : placeholder"
    >
    <div
      class="pa-4"
    >
      <ul>
        <li><span class="font-weight-bold">{{ $t('components.products.ref_number') }}:</span> {{ product.product_id }}</li>
      </ul>
      <h3>
        <span :style="`color: ${$store.getters.hexColor}`">{{ product.name }}</span> 
        <v-btn
          color="indigo"
          v-if="product.certifications && product.certifications.length > 0"
          @click.stop="showCertificationsModal = true"
        ><v-icon>mdi-certificate</v-icon></v-btn>
      </h3>
    </div>
    <v-dialog
      v-model="showCertificationsModal"
      transition="dialog-bottom-transition"
      max-width="720"
    >
      <product-certifications
        :product="product"
        :certifications="product.certifications"
      ></product-certifications> 
    </v-dialog>
  </v-card>
</template>

<script>
import placeholder from '../../assets/images/not-found.png'; 
import productCertifications from './ProductCertifications';
import productFlags from './productFlags.vue';

export default {
  name: 'ProductAsTile',
  components: {
    productCertifications, productFlags
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {
          img: '',
          ref: '',
          name: '',
          description: '',
          packing: '',
          carton: '',
          price: ''
        }
      }
    },
    addCart: {
      default: false
    },
    isNovelties: {
      default: false
    },
    flash: {
      default: 0
    }
  },
  data() {
    return {
      showCertificationsModal: false,
      placeholder
    }
  },
  computed: {
    addToCart() {
      return this.addCart ? true : false;
    },
    isFlash() {
      if(this.flash === 1) {
        return true;
      }
      return false;
    },
    flashData() {
      return this.isFlash ? this.product.unsold_ref_info : {};
    }
  },
  methods:{
    fullDetailsToggle(val) {
      this.showFullDetails = val
    },
  },
  mounted() {
    //console.log(this.product);
  }
}
</script>
<style lang="scss" scoped>
.tile-content {
  width: 100%;
  display: table;
  height: 100%;
  //border: 1px solid #E2E2E2;
  //flex-direction: column;
}
.asTile {
  background-color: #F2F2F2;
  //border-bottom-left-radius: 5px;
  //border-bottom-right-radius: 5px;
  padding: 0px !important;
  vertical-align: bottom;
  display: table-row-group;
}
.tile-product-image {
  width: 100%;
  height: auto;
}
</style>