<template>
  <v-dialog
    v-if="productDetailsDialog"
    v-model="productDetailsDialog"
    fullscreen
    transition="dialog-bottom-transition"
    hide-overlay
    @keydown.esc="toggleProductDialog(false)"
  >
    <v-card tile>
      <v-toolbar
        flat
        dark
        color="primary"
      >
        <v-toolbar-title>{{ product.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="toggleProductDialog(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <loader v-model="productDetailsLoading"></loader>
      <v-card-text
        v-if="!productDetailsLoading" 
        class="pt-8"
      >
        <v-alert
          v-if="disableAddButton"
          color="warning"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-plus"
        >
          <div v-html="$t('orders.add_button_disabled', { incoterm: $store.getters['cart/incoterm']})"></div>
        </v-alert>
        <v-row>
          <v-col
            lg="2"
            md="2"
          >
            <image-slider :images="images"></image-slider>
          </v-col>
          <v-col
            lg="10"
            md="10"
          >
            <h1 class="mColor-text pt-3">{{ product.name }}</h1>
            <p class="pa-2 pt-4" style="font-size: 14px;">{{ product.description }}</p>
            <v-btn
              small
              color="indigo"
              text
              @click="exportDialog = !exportDialog"
            >{{ $t('components.products.download_datasheet') }}<v-icon>mdi-download</v-icon></v-btn>
            <product-price-history
              v-if="false"
              :product-id="product.id"
            ></product-price-history>
          </v-col>
        </v-row>
        <v-row>
          <v-col >
            <express-order-options :product="product"></express-order-options>
          </v-col>
        </v-row>
        <div class="pt-5">
          <v-data-table
            :search="optionSearch"
            :headers="headers"
            :items="options"
            item-key="master_sku"
          >
            <template v-slot:top>
              <div class="pt-2">
                <v-text-field
                  v-model="optionSearch"
                  dense
                  outlined
                  :label="$t('components.products.option_search')"
                ></v-text-field>
              </div>
            </template>
            <template v-slot:[`item.ref`]="{ item }">
              <div v-if="orderMethod !== 'catalogue'">{{ item.master_sku }}</div>
              <div v-else>{{ item.ref }}</div>
            </template>
            <template v-slot:[`item.option_details`]="{ item }">
              <v-chip
                v-if="orderMethod !== 'catalogue' && orderMethod !== 'warehouse'"
                color="light-green"
                class="mt-2"
                small
                label
                dark
              >
                <v-icon small class="mr-2">mdi-truck</v-icon> {{ item.eta_string }}
              </v-chip>
              <div class="font-weight-bold mt-1"><span :style="`color: ${$store.getters.hexColor}`">{{ item.group_name }}:</span> {{ item.group_value }}</div>
              <p v-if="item.description !== ''">{{ item.description }}</p>
              <p v-if="item.packing.specifications && item.packing.specifications !== ''"><span class="font-weight-bold">{{ $t('components.products.packing_specs') }}: </span>{{ item.packing.specifications }}</p>
            </template>
            <template v-slot:[`item.attributes`]="{ item }">
              <v-form v-if="orderMethod === 'catalogue'" :ref="`opt_${item.unique_id}`">
                <div class="d-flex align-center">
                  <div>
                    <v-select
                      v-for="(attrs, index) in item.attributes_grouped"
                      :key="index"
                      :items="attrs"
                      item-text="value"
                      item-value="attribute_id"
                      :rules="requiredRules"
                      :label="attrs[0].group_name"
                      v-model="attrs.selected"
                      @change="checkOfferAttrs(item)"
                      full-width
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        <div>{{ item.value }}</div>
                      </template>
                    </v-select>
                    <div
                      v-if="inCart(item) && !addingProductToExistingOrder"
                      class="i-exist" 
                    >{{ $t('orders.products_in_cart') }}: {{ inCartQuantity(item) }} {{ $tc('components.products.carton', 2) }}</div>
                  </div>
                </div>
              </v-form>
              <div v-else>
                <div
                  v-for="(a, ind) in item.attributes_grouped"
                  :key="ind"
                  class="font-weight-bold"
                >
                  <span :style="`color: ${$store.getters.hexColor}`">{{ ind }}:</span> {{ a[0] }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <div class="pa-1">
                <ul
                  v-if="offerShouldApply(item.offer) === false || item.offer === null"
                  v-html="mxPriceTiers(item)"
                ></ul>
                <product-price-offer
                  v-else
                  :offer="item.offer"
                  :option="item"
                ></product-price-offer>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div :class="['d-flex', { 'align-center': orderMethod !== 'catalogue' }]">
                <div>
                  <span v-if="orderMethod !== 'catalogue'" style="font-size: 11px" >{{ $t('products_list.available_for_order') }}: {{ item.available_quantity }}</span>
                  <vue-number-input v-model="item.quantity" :min="0" center controls></vue-number-input>
                  <span style="font-size: 9px">{{ optionQuantitiesToString(item) }}</span>
                </div>
                <v-btn
                  class="ma-2"
                  x-small
                  fab
                  elevation="2"
                  :color="$store.getters.vColor"
                  @click="addOption(item.unique_id)"
                  :loading="adding_loading"
                  :disabled="disableAddButton"
                >
                  <span class="white--text"><v-icon>mdi-plus</v-icon></span>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
    <confirm-settings v-if="!addingProductToExistingOrder" ref="cart"></confirm-settings>
    <v-dialog
      v-model="exportDialog"
      v-if="exportDialog"
      max-width="720"
    >
      <v-card>
        <v-card-title>{{ $t('export_options') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <product-datasheet-options :datasheet_url="product.datasheet_url" :product_id="product.id"></product-datasheet-options>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>

import imageSlider from '../imageSlider';
import ProductPriceOffer from '../products/ProductPriceOffer.vue';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import productDatasheetOptions from '../products/productDatasheetOptions';
import { ADD_PRODUCT_TO_ORDER, CATALOGUE_GET_PRODUCT } from '@/constants/endpoints.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import productPriceHistory from '../products/productPriceHistory.vue';
import expressOrderOptions from '../products/expressOrderOptions.vue';

export default {
  name: 'productModal',
  props: ['can-order', 'add-to-order', 'order-id', 'value', 'flash'],
  components: {
    imageSlider,
    VueNumberInput,
    ProductPriceOffer,
    confirmSettings: () => import("./confirmSettings"),
    productDatasheetOptions,
    loader: () => import("../loadingBox.vue"),
    productPriceHistory,
    expressOrderOptions
  },
  data() {
    return {
      headers: [
        { text: this.$t('ref'), align: 'start', width: '100px', value: 'ref' },
        { text: this.$t('components.products.option_details'), align: 'start', value: 'option_details' },
        { text: this.$t('components.products.attributes'), align: 'start', width: '200px', value: 'attributes' },
        { text: this.$t('price'), align: 'start', width: '220px', value: 'price' },
        { text: '', align: 'start', width: '250px', value: 'actions' },
      ],
      requiredRules: [
        v => !!v || 'This field required',
      ],
      exportDialog: false,
      adding_loading: false,
      optionSearch: ''
    }
  },
  computed: {
    ...mapGetters('cart', [
      'products',
      'company',
      'incoterm',
      'transport'
    ]),
    ...mapGetters([
      'productDetailsDialog',
      'productInDialog',
      'getIncoterm',
      'getCurrency',
      'productDetailsLoading',
      'orderMethod'
    ]),
    product() {
      console.log(this.productInDialog);
      return this.productInDialog
    },
    options() {
      if(this.orderMethod === 'warehouse' && this.product.option_in_warehouse) {
        return this.product.option_in_warehouse;
      }
      if(this.orderMethod === 'cargo' && this.product.option_in_cargo) {
        return this.product.option_in_cargo;
      }
      if(this.orderMethod === 'factory' && this.product.option_in_factory) {
        return this.product.option_in_factory;
      }
      return this.product.options;
    },
    image() {
      if(!this.product.images) {
        return '';
      }
      return this.product.images[0] ? this.product.images[0].thumb : ''
    },
    images() {
      if(!this.product.images) {
        return [];
      }
      var images = this.product.images.map( i => {
        return i.large
      })
      return images
    },
    addingProductToExistingOrder() {
      if(this.addToOrder === undefined) {
        return false;
      }
      return true;
    },
    disableAddButton() {
      if(this.$route.name !== 'OrdersNew' && this.incoterm !== '') {
        if(this.getIncoterm !== this.incoterm) {
          return true;
        }
      }
      return false;
    },
    modalIncoterm() {
      if(this.$route.name === 'OrdersNew') {
        return this.incoterm;
      }
      return this.getIncoterm;
    },
    isFlash() {
      if(this.flash === 1) {
        return true;
      }
      return false;
    }
  },
  watch: {
    options() {
      console.log(this.options);
    }
  },
  methods: {
    ...mapMutations(['toggleProductDialog']),
    ...mapActions('cart', [
      'addToCart'
    ]),
    loadProduct() {
      this.$http.post(this.buildEndpoint(CATALOGUE_GET_PRODUCT, { id: this.product.id }), {
        incoterm: this.modalIncoterm,
        currency: this.getCurrency
      }).then( resp => {
        this.options = resp.data.data.options;
        console.log(this.options);
        this.loading = false;
      });
    },
    async addOption(unique_id) {

      this.adding_loading = true;

      if(this.$refs[`opt_${unique_id}`] && !this.$refs[`opt_${unique_id}`].validate()) {
        this.adding_loading = false;
        return;
      }

      var option = this.options.find(o => o.unique_id === unique_id);

      //console.log(option);

      var cartProduct = this.buildCartProduct(this.product, option);

      if(this.addToOrder) {
        this.addDirectlyToOrder(cartProduct);
        return;
      }

      //check if the order is setup (no need to happen when adding directly)
      if(this.company.id === undefined || this.incoterm === '') {
        this.newOrder = true;
        var confirmation = await this.$refs.cart.open();
        if(!confirmation) {
          this.adding_loading = false;
          return;
        }
      }
      //console.log(cartProduct)
      this.addToCart(cartProduct);
      this.adding_loading = false;

    },
    addDirectlyToOrder(product) {
      this.$http.post(this.buildEndpoint(ADD_PRODUCT_TO_ORDER, { order_id: this.orderId }), {
        quantity: product.quantity,
        product_id: product.product_id,
        option_id: product.option_id,
        attributes: product.attributes
      }).then( () => {
        this.$toasted.success(this.$t('orders.product_added'));
        this.$emit('productAdded');
        this.adding_loading = false;
      })
    },
    inCart(option) {
      var attrs = this.getSelectedAttributes(option);
      var idd_flash = this.isFlash ? '-F' : '';
      var idd =  `${option.id}-${attrs.join('-')}${idd_flash}`;
      return this.products.some( p => p.id === idd );
    },
    inCartQuantity(option) {
      var attrs = this.getSelectedAttributes(option);
      var idd_flash = this.isFlash ? '-F' : '';
      var idd =  `${option.id}-${attrs.join('-')}${idd_flash}`;
      var p = this.products.find( p => p.id === idd );
      return p.quantity;
    },
    checkOfferAttrs(option) {
      if(this.checkOfferAttributes(option)) {
        option.isOffer = true;
      } else {
        option.isOffer = false;
      }
    },
    flashAllowedLessCartQty(allowed) {
      //console.log(allowed, option);
      return allowed;
    }
  }
}
</script>


