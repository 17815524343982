var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"title":_vm.title,"subheader":_vm.subheader}}),_c('v-card',[_c('v-data-table',{attrs:{"headers":[
        { text: this.$t('ref'), align: 'start', sortable: false, filterable: false, value: 'master_sku' },
        { text: this.$t('product_name'), align: 'start', sortable: false, filterable: false, value: 'product_name' },
        { text: this.$t('packing'), align: 'start', sortable: false, filterable: false, value: 'packing' },
        { text: this.$t('on_stock'), align: 'start', sortable: false, filterable: false, value: 'quantity_remaining' } ],"items":_vm.wproducts},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.product_name))]),_c('div',{staticClass:"font-weight-bold mt-1"},[_c('span',{style:(("color: " + (_vm.$store.getters.hexColor)))},[_vm._v(_vm._s(item.product_option_group_name)+":")]),_vm._v(" "+_vm._s(item.product_option_name))]),(item.description !== '')?_c('p',[_vm._v(_vm._s(item.description))]):_vm._e(),(item.packing.specifications && item.packing.specifications !== '')?_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('components.products.packing_specs'))+": ")]),_vm._v(_vm._s(item.packing.specifications))]):_vm._e()]}},{key:"item.packing",fn:function(ref){
      var item = ref.item;
return [_c('ul',{domProps:{"innerHTML":_vm._s(_vm.mxPacking(item))}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }