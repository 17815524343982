<template>
  <div :style="{ position: 'absolute', 'z-index': 2}" :class="[{ 'x-small': isSmall }]">
    <div v-if="position === 'top-left'" :class="['c-corner-label', 'top-left', `color-${color}`]">
      <div class="c-corner-label__text"><slot></slot></div>
    </div>
    <div v-if="position === 'top-right'" :class="['c-corner-label', 'top-right', `color-${color}`]">
      <div class="c-corner-label__text"><slot></slot></div>
    </div>
    <div v-if="position === 'bottom-left'" :class="['c-corner-label',' bottom-left', `color-${color}`]">
      <div class="c-corner-label__text"><slot></slot></div>
    </div>
    <div v-if="position === 'bottom-right'" :class="['c-corner-label', 'bottom-right', `color-${color}`]">
      <div class="c-corner-label__text"><slot></slot></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position: {
      default: 'top-left'
    },
    xSmall: {
      default: false
    },
    color: {
      default: 'indigo'
    }
  },
  computed: {
    isSmall() {
      return this.xSmall === false ? false : true;
    }
  }
}
</script>
<style scoped lang="scss">
.x-small {
  transform: scale(0.7);
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;
  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }
  @return $x1;
}

$cornerwidth: 70px;

.c-corner-label {
	position: absolute;
	border-style: solid;
  &.color-indigo {
    border-color: transparent #3F51B5 transparent transparent;
    border-color: rgba(255, 255, 255, 0) #3F51B5 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
  &.color-primary {
    border-color: transparent #B71C1C transparent transparent;
    border-color: rgba(255, 255, 255, 0) #B71C1C rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
  &.color-green {
    border-color: transparent #4CAF50 transparent transparent;
    border-color: rgba(255, 255, 255, 0) #4CAF50 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  }
	height: 0;
	width: 0;
	border-width: 0 $cornerwidth $cornerwidth 0;
}
.c-corner-label__text {
    color: #eee;
    font-size: $cornerwidth/6;
    font-weight: bold;
    position: relative;
    left: -$cornerwidth*0.065;
    top: $cornerwidth*0.16;
    white-space: nowrap;
    transform: rotate(45deg);
    width: $cornerwidth*sqrt(2);
    text-align: center;
    line-height: 2em;
    text-transform: uppercase;
}

.top-left {
  top: 0;
	left: 0;
  transform: rotate(-90deg);
}
.top-right {
  top: 0;
	right: 0;
}
.bottom-left {
  bottom: 0;
	left: 0;
}
.bottom-right {
  bottom: 0;
	right: 0;
}
</style>
