<template>
  <v-container fluid>
    <PageHeader :title="title" :subheader="subheader" />
    <v-card>
      <v-data-table
        :headers="[
          { text: this.$t('ref'), align: 'start', sortable: false, filterable: false, value: 'master_sku' },
          { text: this.$t('product_name'), align: 'start', sortable: false, filterable: false, value: 'product_name' },
          { text: this.$t('packing'), align: 'start', sortable: false, filterable: false, value: 'packing' },
          { text: this.$t('on_stock'), align: 'start', sortable: false, filterable: false, value: 'quantity_remaining' },
        ]"
        :items="wproducts"
      >
       <template v-slot:[`item.product_name`]="{ item }">
         <div>{{ item.product_name }}</div>
         <div class="font-weight-bold mt-1"><span :style="`color: ${$store.getters.hexColor}`">{{ item.product_option_group_name }}:</span> {{ item.product_option_name }}</div>
          <p v-if="item.description !== ''">{{ item.description }}</p>
          <p v-if="item.packing.specifications && item.packing.specifications !== ''"><span class="font-weight-bold">{{ $t('components.products.packing_specs') }}: </span>{{ item.packing.specifications }}</p>
       </template>
       <template v-slot:[`item.packing`]="{ item }">
         <ul v-html="mxPacking(item)"></ul>
       </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from '../../components/PageHeader.vue';
import CatalogueController from '../../controllers/catalogueController';

const cc = new CatalogueController();

export default {
  name: 'Warehouses',
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$tc('warehouse', 2),
      subheader: '',
      wproducts: []
    }
  },
  methods: {
    async getProducts() {
      var req = await cc.getWarehouseProducts();
      this.wproducts = req.data?.items;
      console.log(this.wproducts)
    }
  },
  mounted() {
    this.getProducts();
  }
}
</script>