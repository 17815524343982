<template>
  <div class="home">
    <h1>{{ $t('dashboard_title') }}</h1>
    <v-btn
      v-if="$store.getters.development"
      x-large
      color="primary"
      @click="run"
    >RUN</v-btn>
    <v-alert
      v-model="underconstructionAlert"
      dismissible
      color="primary"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-account-hard-hat"
    >
      <div v-html="$t('underconstruction_alert')"></div>
    </v-alert>
    <v-container fluid>
      <v-row>
        <v-col
          lg="12"
          md="12"
          sm="12"
        >
          <novelties></novelties>
        </v-col>
        <v-col
          lg="12"
          md="12"
          sm="12"
        >
          <flash-slides></flash-slides>
        </v-col>
        <v-col
          lg="12"
          md="12"
          sm="12"
        >
          <offer-slides></offer-slides>
        </v-col>
      </v-row>
    </v-container>
    <product-details-modal
      v-if="$store.getters.productDialog"
      v-model="$store.getters.productDialog"
    ></product-details-modal>
  </div>
</template>

<script>
import novelties from '../components/dashboard/novelties.vue';
import flashSlides from '../components/dashboard/flashSlides.vue';
import offerSlides from '../components/dashboard/offerSlides.vue';
import ProductDetailsModal from '../components/orders/productModal.vue';

export default {
  name: 'Dashboard',
  components: {
    novelties,
    flashSlides,
    offerSlides,
    ProductDetailsModal
  },
  data() {
    return {
      products: [],
      underconstructionAlert: false
    }
  },
  methods: {
    run() {
      this.$http.get(this.endpoint('reports/sales_by_broker'), {
        params: {
          from: new Date(new Date().getFullYear(), 0, 2).toISOString().substr(0, 10),
          to: new Date().toISOString().substr(0, 10),
        }
      })
      .then( resp => {
        //console.log(resp.data)
        this.products = resp.data.data.items;
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
