import axios from 'axios';

export default class controller {

  buildEndpoint(url, data = null) {
    if(data !== null) {
      var regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g');
      return url.replace(regex, (m, $1) => data[$1] || m)
    }
    return url
  }

  sendRequest(type = 'POST', url, params) {
    var request = {
      method: type,
      url: url
    };
    if(type === 'POST') {
      request.data = params;
    } else {
      request.params = params;
    }
    return new Promise((resolve, reject) => {
      axios(request)
      .then(resp => {
        resolve(resp);
      }).catch( err => {
        reject(err);
      });
    });
  }

}