import Vue from 'vue';
import mixins from '../mixins';
import router from '../router';
import { i18n } from '../main.js';
import catalogueController from '../controllers/catalogueController';

const cc = new catalogueController();

//session module
const defaultState = () =>  {
  return {
    selected: [],
    productInDialog: {},
    productDetailsDialog: false,
    productDetailsLoading: false,
    estimatedDeliveryDate: null,
    flashProducts: 0,
    categories: [],
    selectedCategories: [],
    customList: null,
    products: [], 
    pagination: {
      pageSize: 25,
      pageNumber: 1,
      totalPages: 1
    },
    catalogueSearch: '',
    catalogueLoading: false,
    catalogueType: [],
    orderMethod: 'catalogue',
  }
};
const catalogue = {
  state: defaultState(),
  mutations: {
    addToSelection(state, id) {
      const exist = state.selected.find(t => t === id);
      if(exist) {
        state.selected = state.selected.filter(t => t !== id);
      } else {
        state.selected.push(id);
      }
    },
    clearSelected(state) {
      state.selected = [];
    },
    resetCatalogue(state) {
      Object.assign(state, defaultState());
    },
    toggleProductDialog(state, bool) {
      mixins.methods.removeParamsToLocation(router.history.current.path);
      state.productDetailsLoading = false;
      state.productInDialog = {};
      state.productDetailsDialog = bool;
      //state.productInDialog = (state.productDialog) ? product : {};
    },
    estimatedDeliveryDate(state, date) {
      state.estimatedDeliveryDate = date;
    },
    toggleFlashProducts(state, val) {
      state.flashProducts = val;
    },
    catalogueLoading(state, bool) {
      state.catalogueLoading = bool;
    },
    setCatalogueSearch(state, search) {
      state.catalogueSearch = search;
    },
    setCatalogueType(state, array) {
      state.catalogueType = array;
    },
    setOrderMethod(state, val) {
      state.orderMethod = val;
    }
  },
  actions: {
    selectCategories({ state }, cats) {
      state.pagination.pageNumber = 1;
      state.selectedCategories = cats;
      this.dispatch('getProducts', true);
    },
    async getCategories({state, rootGetters}) {
      var cats = await cc.getCategories({
        incoterm: rootGetters.getIncoterm
      });
      state.categories = cats;
      this.dispatch('getProducts', true);
    },
    setCustomList({state}, val) {
      state.pagination.pageNumber = 1;
      state.customList = val;
      this.dispatch('getProducts', true);
    },
    async getProducts({state, getters }, changePageSize = false) {
      this.commit('catalogueLoading', true);
      const result = await cc.getProducts(getters.catalogueParams);
      if(result.result) {
        const data = result.data;
        state.products = data.items;
        state.pagination = {
          pageSize: data.pagination.pageSize,
          pageNumber: changePageSize ? 1 : data.pagination.currentPage*1,
          totalPages: data.pagination.totalPages
        };
      } else {
        state.products = [];
        Vue.toasted.error(i18n.t('errors.server_error', { error_code: result.status, error_text: result.statusText }));
      }
      this.commit('catalogueLoading', false);
    },
    async loadProductDetails({ state, rootGetters }, product_id) {
      mixins.methods.addParamsToLocation({ product: product_id }, router.history.current.path);
      state.productDetailsDialog = true;
      state.productDetailsLoading = true;
      const product = await cc.getProduct(product_id, { 
        currency: rootGetters.getCurrency,
        incoterm: rootGetters.getIncoterm
      });
      console.log(product);
      state.productInDialog = product;
      state.productDetailsLoading = false;
    },
  },
  getters : {
    selected: state => state.selected,
    estimatedDeliveryDate: state => state.estimatedDeliveryDate,
    productInDialog: state => state.productInDialog,
    flashProducts: state => state.flashProducts,
    catalogueType: state => state.catalogueType,
    catalogueParams: (state, getters) => {
      return {
        currency: getters.getCurrency,
        pageSize: state.pagination.pageSize,
        pageNumber: state.pagination.pageNumber,
        incoterm: getters.getIncoterm,
        only_offers: 0,
        flash: getters.flashProducts,
        filter_by_location: getters.catalogueType,
        filter: [
          {
            custom_catalogue: getters.customList
          },
          {
            categories: getters.selectedCategories
          },
          {
            keywords: getters.catalogueSearch
          },
          {
            ref: ''
          },
        ]
      };
    },
    products: state => state.products,
    pagination: state => state.pagination,
    catalogueLoading: state => state.catalogueLoading,
    categories: state => state.categories,
    selectedCategories: state => state.selectedCategories,
    customList: state => state.customList,
    productDetailsDialog: state => state.productDetailsDialog,
    productDetailsLoading: state => state.productDetailsLoading,
    catalogueSearch: state => state.catalogueSearch,
    orderMethod: state => state.orderMethod
  }
}

export default catalogue