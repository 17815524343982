<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-center">
        <div style="width: 120px;">
          <v-select
            v-model="pagination.pageSize"
            :items="[5,10,15,25,50,100]"
            outlined
            dense
            rounded
            @change="getProducts(true)"
          ></v-select>
        </div>
        <v-pagination
          v-model="pagination.pageNumber"
          :length="pagination.totalPages"
          :total-visible="pagination.totalPages >= 6 ? 7 : pagination.totalPages" 
          @input="getProducts(false)"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'productsPagination',
  computed: {
    ...mapGetters([
      'pagination'
    ])
  },
  methods: {
    ...mapActions([
      'getProducts'
    ])
  }
}
</script>
