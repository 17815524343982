var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('charts.sales_by_broker'))),_c('v-btn',{on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"lg":"8","md":"12"}},[_c('date-range-picker',{on:{"change":_vm.rangeChange}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"position":"relative","min-height":"100px"}},[_c('loading-box',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('v-data-table',{attrs:{"headers":[
        { text: '', align: 'center', sortable: false, value: 'avatar' },
        { text: this.$t('name'), align: 'start', sortable: false, value: 'name' },
        { text: this.$t('charts.companies_created'), align: 'start', sortable: true, value: 'companies_count' },
        { text: this.$t('charts.assigned_total_sales'), align: 'start', sortable: true, value: 'assigned_sales' },
        { text: this.$t('charts.fellow_total_sales'), align: 'start', sortable: true, value: 'final_broker_sales' },
        { text: this.$t('charts.cbm_sold'), align: 'start', sortable: true, value: 'cbm_sold' },
        { text: this.$t('charts.created_total_sales'), align: 'start', sortable: true, value: 'created_sales' } ],"items":_vm.brokers},on:{"pagination":_vm.updPage},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
      var index = ref.index;
return [(index === 0 && _vm.page === 1)?_c('v-icon',{attrs:{"color":"green","large":""}},[_vm._v("mdi-trophy")]):_vm._e(),(index === 1 && _vm.page === 1)?_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-medal")]):_vm._e(),(index > 1 && _vm.page === 1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]):_vm._e()]}},{key:"item.name",fn:function(ref){
      var _obj, _obj$1, _obj$2, _obj$3;

      var item = ref.item;
      var index = ref.index;return [_c('div',{class:[
          ( _obj = {}, _obj['font-weight-black'] = index === 0 && _vm.page === 1, _obj ),
          ( _obj$1 = {}, _obj$1['font-weight-bold'] = index === 1 && _vm.page === 1, _obj$1 ),
          ( _obj$2 = {}, _obj$2['text-h5'] = index === 0 && _vm.page === 1, _obj$2 ),
          ( _obj$3 = {}, _obj$3['text-h6'] = index === 1 && _vm.page === 1, _obj$3 )
        ]},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]}},{key:"item.fellow_total_sales",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToNiceString(item.fellow_total_sales, _vm.$store.getters.getCurrency, 2))+" ")]}},{key:"item.assigned_total_sales",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToNiceString(item.assigned_total_sales, _vm.$store.getters.getCurrency, 2))+" ")]}},{key:"item.cbm_sold",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.round(item.order_cbm, 2))+" ("+_vm._s(_vm.round(item.order_weight, 2))+"kg)")])]}},{key:"item.created_total_sales",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.numberToNiceString(item.created_total_sales, _vm.$store.getters.getCurrency, 2)))])]}},{key:"item.orders_numbers",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(_vm._s(item.orders_numbers.join(', ')))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }