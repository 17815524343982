<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('products_list.offer') }}
        <v-btn @click="reload"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="position: relative; min-height: 100px;">
        <loading-box v-model="loading"></loading-box>
        <v-row>
          <v-col>
            <carousel
              :autoplay="true"
              :autoplayHoverPause="true"
              :perPage="6"
              :scrollPerPage="false"
              :navigationEnabled="true"
              :autoplayTimeout="4000"
              :paginationEnabled="false"
            >
              <slide
                v-for="(product, index) in products"
                :key="index"
              >
                <div style="padding: 5px;">
                  <product-as-tile 
                    :product="product"
                    :addCart="false"
                    is-novelties
                    @productAdd="$emit('addModal', product)"
                  ></product-as-tile>
                </div>
              </slide>
            </carousel>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { CATALOGUE_GET } from '../../constants/endpoints'
import loadingBox from '../loadingBox';
import ProductAsTile from '../products/ProductAsTile.vue';
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';

export default {
  name: 'Novelties',
  components: {
    loadingBox,
    ProductAsTile,
    Carousel,
    Slide
  },
  data() {
    return {
      loading: true,
      products: []
    }
  },
  computed: {
    ...mapGetters([
      'flashProducts'
    ]),
  },
  methods: {
    getData() {
      this.loading = true
      this.$http.post(this.buildEndpoint(CATALOGUE_GET), {
        currency: this.$store.getters.getCurrency,
        pageSize: 10,
        pageNumber: 1,
        incoterm: this.$store.getters.getIncoterm,
        only_offers: 1,
      })
      .then( resp => {
        //console.log(resp.data);
        this.products = resp.data.data.items;
      })
      .finally(() => {
        this.loading = false;
      })
    },
    reload() {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>