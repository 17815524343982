<template>
  <v-row>
    <v-col>
      <v-autocomplete
        :label="$t('views.companies.companies')"
        v-model="filters.company"
        :items="companies"
        @change="change()"
        clearable
        :loading="loading"
      ></v-autocomplete>
    </v-col>
    <v-col v-if="showProducts">
      <v-autocomplete
        :label="$tc('product', 2)"
        v-model="filters.product"
        :items="products"
        @change="change()"
        clearable
        :loading="loading"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { GET_ORDERS_FILTERS } from '../../constants/endpoints'

export default {
  props: ['value', 'hide-products'],
  data() {
    return {
      loading: true,
      companies: [],
      products: [],
      filters: {
        company: null,
        product: null,
      }
    }
  },
  computed: {
    showProducts() {
      return this.hideProducts === undefined ? true : false;
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$http.get(this.buildEndpoint(GET_ORDERS_FILTERS))
      .then( resp => {
        //console.log(resp.data.data)
        this.companies = resp.data.data.companies.map( c => {
          return {
            text: c.name,
            value: c.id
          };
        });
        this.products = resp.data.data.products.map( c => {
          return {
            text: c.product_name,
            value: c.product_id
          };
        });
        this.loading = false;
      })
    },
    change() {
      this.$emit('input', this.filters);
      this.$emit('change')
    }
  },
  mounted() {
    this.load();
  }
}
</script>