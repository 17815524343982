<template>
  <div>
    <v-container fluid v-if="$store.getters.development">
      <v-row>
        <v-col>
          <v-btn @click="debugList">Debug list</v-btn>
          <v-btn @click="loadProducts">Reload</v-btn>
          <v-btn @click="debugCart">Debug Cart</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <catalogue-filters :for-orders="isForOrders"></catalogue-filters>
    <v-divider></v-divider>
    <v-container
      fluid
      style="min-height: 100px;"
    >
      <loading-box v-model="catalogueLoading"></loading-box>
      <!--LIST MODE-->
      <div 
        v-if="$store.getters.catView === 'list'"
      >
        <v-row
          class="list-header"
          v-if="products.length > 0"
        >
          <v-col
            class="productsHeader"
            :lg="h.col"
            :md="h.col"
            v-for="(h, index) in listHeader"
            :key="index"
          >
            <p v-if="filterHeader(h)"><strong>{{ h.text }}</strong></p>
            <v-checkbox
              v-if="h.text == 'input' && !isForOrders"
              v-model="activateSelection">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <product-as-list
          v-for="(product,index) in products"
          :key="index" 
          :productData="product"
          :addCart="isForOrders"
          :canSelect="activateSelection"
          @productAdd="$emit('addModal', product)"
        />
      </div>
      <!--TILES MODE-->
      <div v-show="$store.getters.catView === 'tiles'">
        <v-row>
          <v-col sm="12" md="3">
            <div v-scroll="fixCategories" ref="categoriesTree">
              <categories-selection
                v-model="selectedCategories"
                :loading="loadingCategories"
                :categories="categoriesAsTree"
                type="tree"
              ></categories-selection>
            </div>
          </v-col>
          <v-col>
            <v-row :class="['product-tile-row', $vuetify.breakpoint.name]" ref="productRef">
              <div
                class="tile-item"
                v-for="(product, index) in products"
                :key="index" 
                lg="3"
                md="4"
              >
                <product-as-tile
                  :flash="flashProducts" 
                  :product="product"
                  :addCart="isForOrders"
                  @productAdd="$emit('addModal', product)"
                ></product-as-tile>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <!--Pagination-->
      <products-list-pagination></products-list-pagination>
      <!--END Pagination-->
    </v-container>
    <div
      class="no-results"
      v-if="products.length === 0 && catalogueLoading === false"
    >
      <v-alert
        border="left"
        colored-border
        type="warning"
        elevation="2"
        v-if="error == ''"
      >
        {{ $t('no_products') }} {{ error }}
      </v-alert>
      <v-alert
        border="left"
        colored-border
        type="error"
        elevation="2"
        v-if="error != ''"
      >
        {{ error }}
      </v-alert>
    </div>
    <selection-options v-model="activateSelection"></selection-options>
    <product-details-modal
      v-if="productDetailsDialog"
      v-model="productDetailsDialog"
    ></product-details-modal>
  </div>
</template>

<script>
import catalogueFilters from './catalogueFilters';
import ProductAsList from './ProductAsList.vue';
import ProductAsTile from './ProductAsTile.vue';
import CategoriesSelection from '../CategoriesSelection.vue';
import SelectionOptions from './selectionOptions';
import loadingBox from '../loadingBox';
import productsListPagination from './productsListPagination';
import ProductDetailsModal from '../orders/productModal';
import { mapActions, mapGetters } from 'vuex';
import { CATALOGUE_GET } from '../../constants/endpoints';
import _ from 'lodash';

export default {
  name: 'ProductsList',
  props: ['forOrders'],
  components: {
    ProductAsList,
    ProductAsTile,
    CategoriesSelection,
    SelectionOptions,
    loadingBox,
    productsListPagination,
    ProductDetailsModal,
    catalogueFilters
  },
  data: function() {
    return {
      search: '',
      categories: [],
      categoriesAsTree: [],
      selectedCategories: [],
      loadingCategories: false,
      express_products: [],
      activateSelection: false,
      listHeader: [
        { text: 'input', inOrders: true, col: 1 },
        { text: this.$t('product_name'), inOrders: true, col: (this.isForOrders ? 3 : 4) },
        { text: this.$t('categories'), inOrders: false, col: 1 },
        { text: this.$t('packing'), inOrders: true, col: 2 },
        { text: this.$t('moq'), inOrders: true, col: 1 },
        { text: this.$t('price') + ' ' + this.$store.getters.getIncoterm, inOrders: true, col: 3 }
      ],
      catalogue: '',
      error: '',
      debugging: false,
      flash_products: 0,
    }
  },
  watch: {
    getIncoterm: function(val, old) {
      if(val != old) {
        this.loadProducts()
      }
    },
    currency: function(val, old) {
      if(val != old) {
        this.loadProducts();
      }
    },
    search() {
      this.page = 1
      this.loadProducts();
    },
    only_offers() {
      this.page = 1;
      this.loadProducts();
    },
    selectedCategories() {
      if(!this.loadingCategories) {
        this.page = 1;
        this.loadProducts();
      }
    }
  },
  computed: {
    ...mapGetters('cart', [
      'incoterm'
    ]),
    ...mapGetters([
      'getLanguage',
      'getIncoterm',
      'catView',
      'flashProducts',
      'products',
      'catalogueLoading',
      'productDetailsDialog'
    ]),
    currency() {
      return this.$store.getters.getCurrency;
    },
    isForOrders() {
      return this.forOrders == '' ? true : false;
    },
    listIncoterm() {
      if(this.isForOrders) return this.incoterm;
      return this.getIncoterm;
    },
    apiEndpoint() {
      return this.buildEndpoint(CATALOGUE_GET);
    }
  },
  methods: {
    ...mapActions([
      'getProducts',
      'getCategories',
      'loadProductDetails'
    ]),
    filterSearch: _.debounce(function() {
      this.loadProducts()
    }, 600),
    filterHeader(header) {
      //h.text != 'input' && isForOrders === h.inOrders
      if(header.text == 'input') {
        return false;
      }
      if(this.isForOrders) {
        if(header.inOrders) {
          return true;
        }
        return false;
      }
      return true;
    },
    async loadProducts() {
      //must rebuild header because deeper nodes do not react
      this.listHeader = [
        { text: 'input', inOrders: true, col: 1 },
        { text: this.$t('product_name'), inOrders: true, col: (this.isForOrders ? 3 : 4) },
        { text: this.$t('categories'), inOrders: false, col: 1 },
        { text: this.$t('packing'), inOrders: true, col: 2 },
        { text: this.$t('moq'), inOrders: true, col: 1 },
        { text: this.$t('price') + ' ' + this.listIncoterm, inOrders: true, col: 3 }
      ];

      await this.getProducts();
      this.$emit('loaded');

    },
    fixCategories() {
      var top = this.$refs.productRef.getBoundingClientRect().top;
      if(top <= 80) {
        //this.$refs.categoriesTree.classList.add('stick');
      } else {
        this.$refs.categoriesTree.classList.remove('stick');
      }
    },
    debugCart() {
      this.loadProducts();
    },
    debugList() {
      console.log(this.productDetailsDialog);
    }
  },
  created() {
    if(this.$route.query.product) {
      this.loadProductDetails(this.$route.query.product);
    }
  },
  mounted() {
    if(this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  }
}
</script>
<style scoped lang="scss">
.activated {
  background-color: #CCC;
}
.product-tile-row {
  position: relative;
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  &.lg {
    grid-template-columns: repeat(4, 1fr);
  }
  &.md {
    grid-template-columns: repeat(3, 1fr);
  }
  &.sm {
    grid-template-columns: repeat(2, 1fr);
  }
  &.xs {
    grid-template-columns: repeat(1, 1fr);
  }
  .tile-item {
    width: 100%;
    display: flex;
  }
}
.productsHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.pagination_container {
  margin-bottom: -25px;
}
.stick {
    position: fixed;
    top: 80px;
}
</style>