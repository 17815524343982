<template>
  <v-card tile>
    <v-card-text>
      <v-btn @click="test" v-if="$development">Test</v-btn>
      <v-row v-if="allowSelectionOfOrders">
        <v-col>
          <v-autocomplete
            v-model="order_id"
            @click="loadOrders"
            :loading="isLoadingOrders"
            :label="$t('invoices.select_order')"
            :items="orders"
            item-text="title"
            item-value="id"
            hide-no-data
            @change="loadOrderData"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <loader v-model="isLoadingOrder"></loader>
      <v-row v-if="order.products">
        <v-col lg="5">
          <div class="content-column mb-2">
            <h3>{{ this.order.orderNumber }} - {{ $t('invoices.order_payments') }}</h3>
            <v-data-table
              :headers="[
                { text: $t('reason'), align: 'start', sortable: false, value: 'reason' },
                { text: $t('amount'), align: 'start', sortable: false, value: 'amount' },
                { text: $t('invoice'), align: 'start', sortable: false, value: 'invoice' },
                { text: $t('status'), align: 'start', sortable: false, value: 'status' }
              ]"
              :items="order.payments"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.reason`]="{ item }">
                {{ $t(item.reason) }}
              </template>
              <template v-slot:[`item.invoice`]="{ item }">
                <div v-if="item.invoice !== null">{{ item.invoice.invoice_no }}</div>
              </template>
            </v-data-table>
          </div>
          <div class="content-column">
            <h3>{{ this.order.orderNumber }} - {{ $t('invoices.order_products') }}</h3>
            <v-data-table
              :headers="[
                { text: $t('name'), align: 'start', sortable: false, value: 'name_es' },
                { text: $t('quantity'), align: 'start', sortable: false, value: 'quantity' },
                { text: $t('orders.invoiced'), align: 'start', sortable: false, value: 'quantity_already_invoiced' },
                { text: $t('total'), align: 'start', sortable: false, value: 'total_price_string' },
                { text: '', align: 'start', sortable: false, value: 'action' },
              ]"
              :items="order.products"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  @click="addToInvoice(item)"
                  color="primary"
                  small
                >{{ $t('components.products.add') }}</v-btn>
              </template>
            </v-data-table>
          </div>
          <div class="d-flex mt-3">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3"
              small
              elevation="1"
              color="secondary"
              @click="manualTaxDialog = true"
            >{{ $t('invoices.add_custom_tax') }}</v-btn>
            <v-btn
              small
              elevation="1"
              color="primary"
              @click="manualDialog = true"
            >{{ $t('invoices.add_custom_item') }}</v-btn>
          </div>
        </v-col>
        <v-col lg="7">
          <v-form ref="invoice_form">
            <div class="content-column">
              <div class="d-flex justify-space-between">
                <h3>{{ $t('invoices.invoice_preview') }} {{ isEdit ? invoiceData.invoice.invoice_no : '' }}</h3>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="isEdit"
                  dark
                  depressed
                  color="primary"
                  :href="invoiceData.invoice.download_url"
                  target="_black"
                  small
                >{{ $t('download') }}</v-btn>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex justify-space-between">
                <div>
                  <h2 class="mt-3 mColor-text">{{ order.company }}</h2>
                  <p><strong>{{ $tc('order', 1) }}:</strong> {{ this.order.orderNumber }}</p>
                </div>
                <div style="max-width: 200px;">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :return-value.sync="date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :offset-overflow="true"
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('date')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                    >
                    <v-btn
                      text
                      @click="menu = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <v-data-table
                :headers="[
                  { text: $t('name'), align: 'start', sortable: false, value: 'name_es' },
                  { text: $t('quantity'), align: 'start', sortable: false, value: 'quantity', width: '60px' },
                  { text: $t('tax'), align: 'start', sortable: false, value: 'tax_pct', width: '80px' },
                  { text: $t('invoices.unit_price'), align: 'start', sortable: false, value: 'unit_price', width: '90px' },
                  { text: $t('total'), align: 'end', sortable: false, value: 'total_price_string', width: '150px' },
                  { text: '', align: 'end', sortable: false, value: 'action', width: '50px' }
                ]"
                :items="invoice_products"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.quantity`]="{ item }">
                  <v-text-field 
                    type="number"
                    v-model="item.quantity" 
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:[`item.tax_pct`]="{ item }">
                  <v-text-field
                    type="number"
                    v-model="item.tax_pct" 
                    dense
                    suffix="%"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.unit_price`]="{ item }">
                  <v-text-field
                    v-model="item.unit_price" 
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:[`item.total_price_string`]="{ item }">
                  <div>{{ numberToNiceString(calculatePercent(item.quantity*item.quantity_per_carton*item.unit_price, item.tax_pct, 'SUM'), order.currency, 2) }}</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    @click="removeFromInvoice(item)"
                    color="primary"
                    small
                  ><v-icon small>mdi-close</v-icon></v-btn>
                </template>
                <template v-slot:[`body.append`]>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('invoices.subtotal') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.base, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr v-for="ct in custom_taxes" :key="ct.built_sku">
                    <td colspan="3"></td>
                    <td>{{ ct.name }} ({{ ct.percent }}%)</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(calculatePercent(invoiceTotals.base, ct.percent), order.currency, 2) }}</h4>
                    </td>
                    <td>
                      <v-btn
                        @click="removeTaxFromInvoice(ct)"
                        color="primary"
                        small
                      ><v-icon small>mdi-close</v-icon></v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('tax') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.tax, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('total') }}</td>
                    <td>
                      <h4 class="text-right">{{ numberToNiceString(invoiceTotals.total, order.currency, 2) }}</h4>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('invoices.already_paid') }}</td>
                    <td>
                      <v-text-field
                        dense
                        :prefix="order.currency === 'EUR' ? '€' : '$'"
                        v-model="paid"
                      ></v-text-field>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>{{ $t('invoices.to_be_paid') }}</td>
                    <td>
                      <v-text-field
                        dense
                        :prefix="order.currency === 'EUR' ? '€' : '$'"
                        v-model="to_be_paid"
                      ></v-text-field>
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
              <div class="mt-3">
                <v-textarea
                  outlined
                  v-model="notes"
                  :label="$t('invoices.notes')"
                  :hint="$t('invoices.notes_hint')"
                  height="65"
                ></v-textarea>
              </div>
              <div>
                <v-textarea
                  outlined
                  v-model="internal_notes"
                  :label="$t('invoices.internal_notes')"
                  :hint="$t('invoices.internal_notes_hint')"
                  height="65"
                ></v-textarea>
              </div>
              <div class="d-flex mt-5">
                <v-switch
                  v-model="notify"
                  inset
                  :label="$t('invoices.notify_client')"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  elevation="1"
                  dark
                  @click="submitInvoice"
                >{{ isEdit ? $t('invoices.update_invoice') : $t('invoices.create_invoice') }}</v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="manualDialog">
      <v-card>
        <v-card-title>{{ $t('invoices.add_custom_item') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('name')"
                v-model="custom.name_es"
                @blur="custom.name_en = custom.name_es"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('quantity')"
                v-model="custom.quantity"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('invoices.quantity_carton')"
                v-model="custom.quantity_per_carton"
                :hint="$t('invoices.for_calculation_purpose')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('invoices.unit_price')"
                v-model="custom.unit_price"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('tax')"
                v-model="custom.tax_pct"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="1"
            color="primary"
            @click="addManual()"
          >{{ $t('orders.add_product') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="manualTaxDialog">
      <v-card>
        <v-card-title>{{ $t('invoices.add_custom_tax') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('name')"
                v-model="customTax.name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('invoices.percent')"
                v-model="customTax.percent"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="1"
            color="primary"
            @click="addManualTax()"
          >{{ $t('invoices.add_custom_tax') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ORDER_LIGHT_GET, INVOICE_GET_ORDER_DATA, INVOICE_MAKE_CUSTOM, INVOICE_UPDATE } from '../../constants/endpoints';
import loader from '../loadingBox.vue';

var manual = {
  name_en: '',
  name_es: '',
  quantity: 1,
  unit_price: 0,
  quantity_per_carton: 1,
  tax_pct: 21,
  id: 0,
  order_product_id: null
};

var manualTax = {
  name: 'Recargo de equivalencia',
  percent: 5.2,
  apply_to: 'default'
};

export default {
  name: 'invoiceCreate',
  props: ['is-edit', 'invoice-data', 'order-id'],
  components: {
    loader
  },
  data() {
    return {
      order_id: null,
      order: {},
      orders: [],
      isLoadingOrders: false,
      invoice_products: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      isLoadingOrder: false,
      paid: 0,
      to_be_paid: 0,
      notes: '',
      internal_notes: '',
      manualDialog: false,
      manualTaxDialog: false,
      custom: manual,
      customTax: manualTax,
      custom_taxes: [],
      notify: false,
      deleted_ids: [],
    }
  },
  computed: {
    allowSelectionOfOrders() {
      if(!this.orderId && !this.isEdit) {
        return true;
      }
      return false;
    },
    invoiceTotals() {
      var totals = {
        base: 0,
        tax: 0,
        total: 0
      };
      for(var i in this.invoice_products) {
        var base = this.invoice_products[i].quantity*this.invoice_products[i].quantity_per_carton*this.invoice_products[i].unit_price;
        var tax = this.calculatePercent(base, this.invoice_products[i].tax_pct)
        totals.base += base;
        totals.tax += tax;
        totals.total += base+tax;
      }
      if(this.custom_taxes.length > 0) {
        for(var s in this.custom_taxes) {
          var add = this.calculatePercent(totals.base, this.custom_taxes[s].percent);
          totals.total += add;
        }
      }
      return totals;
    }
  },
  watch: {
    invoice_products: {
      deep: true,
      handler() {
        this.invoice_products.map(ip => {
          ip.subtotal = ip.quantity*ip.quantity_per_carton*ip.unit_price;
          ip.total = this.calculatePercent(ip.quantity*ip.quantity_per_carton*ip.unit_price, ip.tax_pct, 'SUM');
          ip.order_product_id = this.isEdit ? ip.order_product_id : ip.id;
        })
      }
    }
  },
  methods: {
    test() {
      console.log(this.invoice_products);
    },
    addManual() {
      this.custom.built_sku = Date.now();
      this.invoice_products.push(Object.assign({}, this.custom));
      this.custom = manual;
      this.manualDialog = false;
    },
    addManualTax() {
      this.customTax.built_sku = Date.now();
      this.custom_taxes.push(Object.assign({}, this.customTax));
      this.customTax = manualTax;
      this.manualTaxDialog = false;
    },
    loadOrders() {
      this.isLoadingOrders = true;
      this.$http.get(ORDER_LIGHT_GET)
      .then(resp => {
        this.isLoadingOrders = false;
        this.orders = resp.data.data.map( o => {
          o.title = o.sku+' - '+o.company.name;
          return o;
        });
      });
    },
    loadOrderData(autopush = false) {
      var vue = this;
      this.isLoadingOrder = true;
      this.$http.get(this.buildEndpoint(INVOICE_GET_ORDER_DATA, { order_id: this.order_id }))
      .then( resp => {
        this.order = resp.data.data;
        if(autopush) {
          vue.order.products.forEach(p => {
            vue.addToInvoice(p, false);
          });
        }
        this.isLoadingOrder = false;
      })
    },
    addToInvoice(product, manual = true) {
      product.tax_pct = product.tax_pct*1;//convert to number
      var exist = this.invoice_products.find(ip => ip.built_sku === product.built_sku);
      if(exist !== undefined) {
        this.$toasted.error(this.$t('invoices.item_added_already'));
        return;
      }
      var cloned = Object.assign({}, product);
      //cloned.quantity = cloned.quantity - cloned.quantity_already_invoiced;
      if(cloned.quantity === 0) {
        if(manual) {
          this.$toasted.error(this.$t('invoices.product_fully_invoiced'));
        }
        return;
      }
      this.invoice_products.push(cloned);
    },
    removeFromInvoice(product) {
      this.deleted_ids.push(product.id);
      this.invoice_products = this.invoice_products.filter(ip => ip.built_sku !== product.built_sku);
    },
    removeTaxFromInvoice(tax) {
      this.custom_taxes = this.custom_taxes.filter(t => t.built_sku !== tax.built_sku);
    },
    submitInvoice() {
      if(!this.$refs.invoice_form.validate()) {
        return false;
      }
      const invoice = {
        order_id: this.order.id,
        invoice_date: this.date,
        notes: this.notes,
        internal_notes: this.internal_notes,
        tax_base: this.invoiceTotals.base,
        tax: this.invoiceTotals.tax,
        already_invoiced: 0,
        subtotal: this.invoiceTotals.total,
        total: this.invoiceTotals.total,
        paid: this.paid,
        total_to_be_paid: this.to_be_paid,
        items: this.invoice_products,
        custom_taxes: this.custom_taxes,
        notify: this.notify ? 1 : 0,
        item_delete_ids: this.deleted_ids
      };
      this.isLoadingOrder = true;
      var action = this.isEdit ? this.buildEndpoint(INVOICE_UPDATE, { invoice_id: this.invoiceData.invoice.id }) : this.buildEndpoint(INVOICE_MAKE_CUSTOM);
      this.$http.post(action, invoice)
      .then(resp => {
        this.isLoadingOrder = false;
        if(resp.data.result === true) {
          var message = this.isEdit ? this.$t('invoices.invoice_updated') : this.$t('invoices.invoice_created');
          this.$toasted.success(message);
          if(!this.isEdit) {
            this.$emit('success');
          }
        } else {
          this.$toasted.error(resp.data.response_message);
        }
      })
    }
  },
  mounted() {
    if(this.isEdit) {
      this.order = this.invoiceData.order;
      var invoiceDate = new Date(this.invoiceData.invoice.invoice_date);
      this.date = invoiceDate.getFullYear()+'-'+('0' + (invoiceDate.getMonth()+1)).slice(-2)+'-'+('0' + invoiceDate.getDate()).slice(-2);
      this.custom_taxes = this.invoiceData.invoice.custom_taxes;
      this.internal_notes = this.invoiceData.invoice.internal_notes;
      this.notes = this.invoiceData.invoice.notes;
      this.invoiceData.invoice.invoice_items.forEach((p,i) => {
        this.addToInvoice({
          id: p.id,
          name: p.name,
          name_es: p.name,
          quantity: p.quantity,
          tax_pct: p.tax_pct,
          built_sku: p.order_product !== null ? p.order_product.built_sku : 'custom-'+i,
          unit_price: p.unit_price*1,
          quantity_per_carton: p.order_product !== null ? p.order_product.quantity_per_carton : 1,
          order_product_id: p.order_product !== null ? p.order_product.id : null
        });
      });
    }
    if(this.orderId) {
      this.order_id = this.orderId;
      this.loadOrderData(true);
    }
  }
}
</script>

<style scoped>
.content-column {
  border: 1px solid #E2E2E2;
  padding: 10px;
  border-radius: 3px;
}
</style>